import React, { Component } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { FiEdit, FiTrash2, FiPlus } from "react-icons/fi";
import PageTitle from "../components/common/PageTitle";

class DarkTable extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Row>
        <Col>
          <Card small className="mb-4 overflow-hidden ">
            <CardHeader className="bg-dark">
              <h5
                className="m-0 text-white step-add-course"
                onClick={() => {
                  this.props.add();
                }}
              >
                {" "}
                Add <FiPlus></FiPlus>
              </h5>
            </CardHeader>
            <CardBody className="bg-dark p-0 pb-3">
              {this.props.head.length > 0 ? (
                <table className="table table-dark mb-0">
                  <thead className="thead-dark">
                    <tr>
                      {this.props.head.map((head, index) => (
                        <th scope="col" className="border-0">
                          {head !== "pic" ? head : ""}
                        </th>
                      ))}
                      {this.props.action !== null ? (
                        <th scope="col" className="border-0">
                          {`Actions`}
                        </th>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.rows.map((row, index) => (
                      <tr>
                        {Object.values(row).map(col => (
                          <td>
                            {!col.includes("upload/uploads") &&
                            !col.includes(".jpg") &&
                            !col.includes(".jpeg") &&
                            !col.includes(".png")
                              ? col
                              : ""}
                          </td>
                        ))}
                        {this.props.action !== null ? (
                          <td>
                            {" "}
                            {this.props.action.map((action, index) => (
                              <a
                                onClick={() => {
                                  this.props.manageClick(action.name, row.id);
                                }}
                              >
                                {" "}
                                {action.name == "Edit" ? (
                                  <FiEdit></FiEdit>
                                ) : (
                                  <FiTrash2></FiTrash2>
                                )}{" "}
                              </a>
                            ))}
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <center>No Data to Display</center>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default DarkTable;
