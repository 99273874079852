import React, { Component } from "react";
import { Container, Row, Col } from "shards-react";
import PageTitle from "./../components/common/PageTitle";
import Gallery from "react-grid-gallery";
import PdfView from "./PdfView";
import { getLibrary, chunkUpload, uploadBook, getPlan } from "../services/api";
import { FiX } from "react-icons/fi";
import Modal from "react-modal";
import { Menu, Item, contextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  FormInput,
  Button
} from "shards-react";
import Load from "../Load";
import IconDisplay from "../components/common/IconDisplay";
import { FiBookOpen } from "react-icons/fi";
import { Store } from "../flux";
import { Dispatcher, Constants } from "../flux";

class Library extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      url: null,
      plan: Store.getCurrentPlan(),
      showModal: false,
      loading: true,
      currentUser: Store.getCurrentUser(),
      editModal: { open: false, elements: [] },
      buttonTheme: "primary",
      buttonText: "Publish",
      uploadUrl: "",
      resource: {
        src: "",
        thumbnail: "https://www.lesson21.com/app/upload/uploads/216968-pdf.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 320,
        tags: [
          { value: "Ocean", title: "Ocean" },
          { value: "People", title: "People" }
        ],
        caption: ""
      },
      books: [],
      uploadUrl: "",
      fileName: ""
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.showMenu = this.showMenu.bind(this);
  }

  config = {
    onUploadProgress: progressEvent => {
      this.progressSum+=progressEvent.loaded;
      let progress = (this.progressSum / this.state.fileSize) * 100;
      if(parseInt(progress) >=100){
        this.progressSum = 0;
        progress = 100;
      }
      this.setState({...this.state,buttonText:`Uploading ${parseInt(progress)}%`})
    }
  }
  progressSum = 0;
  showMenu(e) {
    if (this.state.currentUser.Type == "PRIMARY" && this.state.currentUser.Email == 'hashif360@gmail.com') {
    contextMenu.show({
      id: `library`,
      event: e
    });
  }
  }
  bookClick(index) {
    this.setOpen(true, index);
  }
  setOpen(val, id) {
    this.setState({
      isOpen: val,
      url: id != null ? this.state.books[id].src : null
    });
  
  }

  handleItemClick = ({ event, props }) => {
    this.setState({
      ...this.state,
      editModal: { open: true, elements: [props] }
    });
  };
  handleClose = () => {
    this.setState({ ...this.state, editModal: { open: false, elements: [] } });
  };
  handleOpenModal() {
    this.setState({ ...this.state, showModal: true });
  }

  handleCloseModal() {
    this.setState({ ...this.state, showModal: false });
  }
  componentDidMount() {
    getPlan(this.state.currentUser.Institution,this.state.currentUser).then((data)=>{
      this.setState({...this.state,plan: data});
      Dispatcher.dispatch({
        actionType: Constants.PLAN,
        payload: data
      });
     
    }).catch(()=>{});

    getLibrary(this.state.currentUser)
      .then(data => {
        this.setState({ ...this.state, books: data, loading:false });
      })
      .catch(err => {this.setState({...this.state,loading:false})});
  }
  render() {
    return this.state.loading ? (
      <Container fluid className="main-content-container px-4">
        <Load />
      </Container>
    ) : (
      <Container
        onContextMenu={this.showMenu}
        fluid
        className={`main-content-container px-4 ${this.state.plan.BookLibrary == '1' ? '' : 'grey' }`}
      >
        {/* <div>
        <button onClick={this.handleOpenModal}>Trigger Modal</button> */}
        <Menu id={`library`}>
          <Item onClick={this.handleOpenModal}>Upload</Item>
        </Menu>
        <Modal
          isOpen={this.state.showModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick={true}
          className="library_modal"
          overlayClassName="library_overlay"
          ariaHideApp={false}
        >
          <Card small>
            {/* Files & Dropdowns */}
            <CardHeader className="border-bottom">
              <h6 className="m-0">Upload an Ebook</h6>
            </CardHeader>

            <ListGroup flush>
              <ListGroupItem className="px-3">
                <strong className="text-muted d-block mb-2">Name</strong>
                <div className="custom-file mb-3">
                  <FormInput
                    onChange={e => {
                      this.setState({
                        ...this.state,
                        fileName: e.target.value
                      });
                    }}
                  />
                </div>
                <strong className="text-muted d-block mb-2">Upload File</strong>
                <div className="custom-file mb-3">
                  <input
                    type="file"
                    onChange={e => {
                      this.setState({
                        ...this.state,
                        buttonTheme: "warning",
                        buttonText: "Uploading"
                      });
                      chunkUpload(e,this.config,this.state.currentUser)
                        .then(data => {
                          this.setState({
                            ...this.state,
                            uploadUrl: data,
                            buttonTheme: "success",
                            buttonText: "Publish"
                          });
                        })
                        .catch((err) => {
                          if(err=="You have exceeded your storage quota. Please Upgrade."){
                            alert(err);
                            this.setState({ ...this.state, showModal: false });
                          }
                        });
                    }}
                    className="custom-file-input"
                    id="customFile2"
                  />
                  <label className="custom-file-label" htmlFor="customFile2">
                    Choose file...
                  </label>
                </div>
                <div className="custom-file mb-3">
                  <Button
                    disabled={this.state.buttonText !== "Publish"}
                    theme={this.state.buttonTheme}
                    onClick={() => {
                      this.setState({...this.state,loading:true});
                      uploadBook(this.state.uploadUrl, this.state.fileName,this.state.currentUser)
                        .then(data => {
                          getLibrary(this.state.currentUser)
                            .then(data => {
                              this.setState({
                                ...this.state,
                                showModal: false,
                                books: data,
                                loading:false
                              });
                            })
                            .catch(err => {this.setState({...this.state,loading:false});});
                        })
                        .catch(err => {this.setState({...this.state,loading:false});});
                    }}
                  >
                    {this.state.buttonText}
                  </Button>
                </div>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Modal>
        {/* </div> */}
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Library"
            subtitle="Lesson 21"
            className="text-sm-left mb-3"
          />
        </Row>

        {/* Small Stats Blocks */}
        {/* <Row>
          <Col className="col-lg mb-4">
            {this.state.isOpen ? (
              <div>
                <PdfView url={this.state.url} />
                <div class="fixed">
                  <FiX
                    onClick={() => {
                      this.setOpen(false, null);
                    }}
                    size={30}
                  />
                </div>
              </div>
            ) : (
              <Row>
                {" "}
                <Col className="col-lg mb-4">
                  <Gallery
                    onClickThumbnail={index => {
                      this.setOpen(true, index);
                    }}
                    images={this.state.books}
                  />{" "}
                </Col>
              </Row>
            )}
          </Col>
        </Row> */}
      
        <Row>
          <Col className="col-lg mb-4">
            {this.state.isOpen ? (
              <div>
                <PdfView url={this.state.url} />
                <div class="fixed">
                  <FiX
                    onClick={() => {
                      this.setOpen(false, null);
                    }}
                    size={30}
                  />
                </div>
              </div>
            ) : (
              this.state.books.map((book, index) => (
                <IconDisplay
                  icon={<FiBookOpen></FiBookOpen>}
                  context={e => {
                    this.showMenu(e, book);
                  }}
                  key={index}
                  bookClick={() => {
                    this.bookClick(index);
                  }}
                  iconSize="4em"
                  title={book.caption}
                />
              ))
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Library;
