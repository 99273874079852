export default {
  CHANGE: "CHANGE",
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
  LOGIN: "LOGIN",
  BRAND: "BRAND",
  PLAN: "PLAN",
  CLEARPLAN:"CLEARPLAN",
  TOUR: "TOUR",
  LOGOUT: "LOGOUT"
};
