/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";

class Load extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{height: window.innerHeight}} class="wrapper">
  <div class="loader-outer">
    <div class="loader-inner">
      <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
    </div>
  </div>
  <h1><span>LOADING</span></h1>
</div>
      // <div
      //   id="wrapper"
      //   style={{
      //     width: "100%",
      //     zIndex: 10001,
      //     height: "100%",
      //     backgroundColor: "#FFFFFF",
      //     position: "absolute",
      //     top: 0,
      //     left: 0
      //   }}
      // >
      //   <div id="loader" style={{backgroundImage: this.state.brand != null? this.state.brand.brandingpic : "../images/content-management/lesson21_big.jpg"}}></div>
      // </div>
    );
  }
}

export default Load;
