/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import Iframe from 'react-iframe';
import { Store } from "flux";


class CustomMeeting extends React.Component {
  constructor(props) {
    super(props);
    
  }
  componentDidMount() {
    console.log(this.props);
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  };
  render() {
    return (
      <Iframe url={this.props.location.state.link}
      width="100%"
      height={window.innerHeight}
      id="myId"
      className="calls"
      display="initial"
      position="relative"/>
    );
  }
}

export default CustomMeeting;
