import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import { Menu, Item, contextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { Store } from "../flux";
import { MdAssignment } from "react-icons/md";
import DashNavigate from "./DashNavigate";
import Account from "./Account";
import Joyride from 'react-joyride';
import { Dispatcher, Constants } from "../flux";

const steps =  [
  {
    content: (
      <div>
        You can interact with your own components through the spotlight.
        <br />
        Click the menu above!
      </div>
    ),
    disableBeacon: true,
    scrollToSteps : true,
    // disableOverlayClose: true,
    // hideCloseButton: true,
    // hideFooter: true,
    placement: 'right',
    disableScrolling : true,
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.step-3',
    title: 'Menu',
  },
  {
    content: (
      <div>
        You can interact with your own components through the spotlight.
        <br />
        Click the menu above!
      </div>
    ),
    disableBeacon: true,
    disableScrolling : true,
    scrollToSteps : true,
    // disableOverlayClose: true,
    // hideCloseButton: true,
    // hideFooter: true,
    placement: 'left',
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.step-4',
    title: 'Menu',
  },
  {
    content: (
      <div>
        You can interact with your own components through the spotlight.
        <br />
        Click the menu above!
      </div>
    ),
    disableBeacon: true,
    disableScrolling : true,
    // disableOverlayClose: true,
    // hideCloseButton: true,
    // hideFooter: true,
    placement: 'left',
    scrollToSteps : true,
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.step-5',
    title: 'Menu',
  }
]

const initialTabs = {
  tab1: "dark",
  tab2: "dark",
  tab3: "dark",
  tab4: "dark",
  tab5: "dark",
  tab6: "dark",
  tab7: "dark"
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      run: true,
      stepIndex: 0,
      hover: false,
      currentUser: Store.getCurrentUser(),
      hoverId: 0,
      posts: [],
      tabs: {
        tab1: "danger",
        tab2: "dark",
        tab3: "dark",
        tab4: "dark",
        tab5: "dark",
        tab6: "dark",
        tab7: "dark"
      }
    };

  }
  componentDidMount() {
   
  }
  render() {
    var stage = (this.state.tour== null ? 'initial' : this.state.tour.stage);
    var isStage = stage == 'dashboard';
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <DashNavigate history={this.props.history} match={this.props.match} />
        {/* <Joyride
          continuous={true}
          run={isStage}
          steps={steps}
          stepIndex={this.state.stepIndex}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          callback={(x)=>{
            if(x.lifecycle== 'tooltip'){
              window.scrollTo(0,0);
            }
            if(x.action == 'next' && x.lifecycle == 'complete'){
              this.setState({...this.state, stepIndex: x.index+1})
              if(x.index == 2){
                Dispatcher.dispatch({
                  actionType: Constants.TOUR,
                  payload: {run: true, stage: 'account'}
                });
                this.setState({...this.state, tour : {run: true, stage: 'account'}});
                this.props.history.push('/account');
              }
            }
            console.log(x);
          //  this.setState({...this.state, stepIndex: this.state.stepIndex+1})
          }}
        /> */}
        <Account></Account>
      </Container>
    );
  }
}

export default Dashboard;
