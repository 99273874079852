import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import { Menu, Item, contextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { Store } from "../flux";
import { MdAssignment } from "react-icons/md";
import DashNavigate from "./DashNavigate";
import axios from "axios";
import { FloatingButton, Item as FItem } from "react-floating-button";
import Home from "./Home";
import ManageStudents from "./ManageStudents";
import ManageTeachers from "./ManageTeachers";
import ManageCourses from "./ManageCourses";

const initialTabs = {
  tab1: "dark",
  tab2: "dark",
  tab3: "dark",
  tab4: "dark",
  tab5: "dark",
  tab6: "dark",
  tab7: "dark"
};

class Manage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      currentUser: Store.getCurrentUser(),
      hoverId: 0,
      posts: [],
      tabs: {
        tab1: "danger",
        tab2: "dark",
        tab3: "dark",
        tab4: "dark",
        tab5: "dark",
        tab6: "dark",
        tab7: "dark"
      }
    };
  }

  render() {
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <DashNavigate history={this.props.history} match={this.props.match} />

        <ManageStudents></ManageStudents>
        <ManageTeachers></ManageTeachers>
        <ManageCourses></ManageCourses>
      </Container>
    );
  }
}

export default Manage;
