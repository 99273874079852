import React from "react";
import { Container, Row, Col } from "shards-react";
import { FiBookOpen } from "react-icons/fi";
import { MdOndemandVideo } from "react-icons/md";
import { BsFileEarmarkText } from "react-icons/bs";
import { AiFillQuestionCircle } from "react-icons/ai";
import { MdContacts } from "react-icons/md";
import { IoIosRadio } from "react-icons/io";
import ReactHoverObserver from 'react-hover-observer';

class DashNavigate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hoverID: null, hover: false };
    this.toggleHover = this.toggleHover.bind(this);
  }
  toggleHover(id) {
    this.setState({ ...this.state, hoverID: id, hover: !this.state.hover });
  }
  render() {
    var initalStyles = [
      { color: "#ff527a", backgroundColor: "#ffdee5" },
      { color: "#5600d4", backgroundColor: "#d8d8f7" },
      { color: "#feb628", backgroundColor: "#fef3d6" },
      { color: "#f68445", backgroundColor: "#fde7da" },
      { color: "#8e00b2", backgroundColor: "#e8d1f0" },
      { color: "#3793f8", backgroundColor: "#cbecff" }
    ];
    var buttonStyles = [
      { color: "#FFFFFF", backgroundColor: "#ff527a" },
      { color: "#FFFFFF", backgroundColor: "#5600d4" },
      { color: "#FFFFFF", backgroundColor: "#feb628" },
      { color: "#FFFFFF", backgroundColor: "#f68445" },
      { color: "#FFFFFF", backgroundColor: "#8e00b2" },
      { color: "#FFFFFF", backgroundColor: "#3793f8" }
    ];
    var bStyles = initalStyles;
    if (this.state.hoverID !== null && this.state.hover) {
      bStyles[this.state.hoverID] = buttonStyles[this.state.hoverID];
    }

    return (
      <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Col style={{ maxWidth: "max-content", marginRight: "30px" }}>
          <div
            style={{
              marginTop: "20px",
              width: "max-content",
              marginBottom: "20px",
              paddingLeft: "30px",
              fontSize: "1.2em",
              paddingRight: "30px",
              borderRadius: "0.5rem",
              backgroundColor: "#f5f6f8",
              paddingTop: "16.5px",
              minHeight: "60px"
            }}
          >
            <div>
              <span style={{ paddingRight: "15px", fontWeight: "bold" }}>
                Dashboard
              </span>{" "}
              | <span style={{ paddingLeft: "15px" }}>Admin</span>
            </div>
          </div>
        </Col>

        {/* <ReactHoverObserver>
        {({ isHovering }) => (
        <div
         className='step-3'
          onClick={e => {
            this.props.history.push(`/dashboard`);
          }}
          style={!isHovering?{
            marginTop: "20px",
            float: "right",
            maxWidth: "max-content",
            marginLeft: "100px",
            marginBottom: "20px",
            paddingLeft: "18.897637795px",
            fontSize: "1.2em",
            paddingRight: "18.897637795px",
            borderRadius: "0.5rem",
            paddingTop: "16.5px",
            minHeight: "60px",
            ...bStyles[3]
          }:{
            marginTop: "20px",
            float: "right",
            maxWidth: "max-content",
            marginLeft: "100px",
            marginBottom: "20px",
            paddingLeft: "18.897637795px",
            fontSize: "1.2em",
            paddingRight: "18.897637795px",
            borderRadius: "0.5rem",
            paddingTop: "16.5px",
            minHeight: "60px",
            ...buttonStyles[3]
          }}
        >
          <div>
            <span style={{ marginRight: "5px" }}>
              <BsFileEarmarkText />
            </span>
            <span>Analytics</span>
          </div>
        </div>
        )}
</ReactHoverObserver>  */}
{/* <ReactHoverObserver>
{({ isHovering }) => (
        <div
        className='step-4'
          onClick={e => {
            this.props.history.push(`/manage`);
          }}
          style={!isHovering?{
            marginTop: "20px",
            float: "right",
            maxWidth: "max-content",
            marginLeft: "100px",
            marginBottom: "20px",
            paddingLeft: "18.897637795px",
            fontSize: "1.2em",
            paddingRight: "18.897637795px",
            borderRadius: "0.5rem",
            paddingTop: "16.5px",
            minHeight: "60px",
            ...bStyles[4]
          }:{
            marginTop: "20px",
            float: "right",
            maxWidth: "max-content",
            marginLeft: "100px",
            marginBottom: "20px",
            paddingLeft: "18.897637795px",
            fontSize: "1.2em",
            paddingRight: "18.897637795px",
            borderRadius: "0.5rem",
            paddingTop: "16.5px",
            minHeight: "60px",
            ...buttonStyles[4]
          }}
        >
          <div>
            <span style={{ marginRight: "5px" }}>
              <AiFillQuestionCircle />
            </span>
            <span>Manage</span>
          </div>
        </div>
)}
        </ReactHoverObserver> */}
        <ReactHoverObserver>
        {({ isHovering }) => (
        <div
        className='step-5'
          onClick={e => {
            this.props.history.push(`/account`);
          }}
          style={!isHovering?{
            marginTop: "20px",
            float: "right",
            maxWidth: "max-content",
            marginLeft: "100px",
            marginBottom: "20px",
            paddingLeft: "18.897637795px",
            fontSize: "1.2em",
            paddingRight: "18.897637795px",
            borderRadius: "0.5rem",
            paddingTop: "16.5px",
            minHeight: "60px",
            ...bStyles[5]
          }:{
            marginTop: "20px",
            float: "right",
            maxWidth: "max-content",
            marginLeft: "100px",
            marginBottom: "20px",
            paddingLeft: "18.897637795px",
            fontSize: "1.2em",
            paddingRight: "18.897637795px",
            borderRadius: "0.5rem",
            paddingTop: "16.5px",
            minHeight: "60px",
            ...buttonStyles[5]
          }}
        >
          <div>
            <span style={{ marginRight: "5px" }}>
              <MdContacts />
            </span>
            <span>Account</span>
          </div>
        </div>
        )}
        </ReactHoverObserver>
      </Row>
    );
  }
}

export default DashNavigate;
