import React from "react";
import ReactCrop from 'react-image-crop';
import { Container, Row, Col, Card, CardBody } from "shards-react";
import 'react-image-crop/dist/ReactCrop.css';
import { Store } from "../flux";
import Load from "../Load";
import { SketchPicker } from 'react-color';
import {
    upload,
    getBranding,
    updateBranding,
    updateLogin,
    saveWebsite
  } from "../services/api";
import { Dispatcher, Constants } from "../flux";

class Branding extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      brand : Store.getBrand(),
      currentUser: Store.getCurrentUser(),
      plan:Store.getCurrentPlan(),
      brandsrc: null,
      brandplaceholder:null,
      loginplaceholder: null,
      loginsrc: null,
      branduploadurl:null,
      loginuploadurl: null,
      brandcrop: {
        unit: 'px',
        width: 180,
        aspect: 1024 / 301,
      },
      logincrop: {
          unit: 'px',
          width: 307,
          aspect: 800 / 533,
        },
      website:{
        enabled: false,
        subdomain: '',
        mainbgcolor:'#FFFFFF',
        initialtextcolor:'#000000',
        specialareabackground:'#FFFFFF',
        whatsapp: ''
      }
    }
    this.onDrag = this.onDrag.bind(this);
 
  }
  
  componentDidMount(){
      let id = this.state.currentUser.Institution;
      getBranding(id).then((data)=>{
    this.setState({...this.state, loading: false,brandplaceholder:data.brandingpic, loginplaceholder: data.loginpic});
      })
  }
  brandonSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ brandsrc: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  loginonSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ loginsrc: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  loginonImageLoaded = image => {
    this.loginimageRef = image;
  };

  loginonCropComplete = crop => {
    this.loginmakeClientCrop(crop);
  };
  brandonImageLoaded = image => {
    this.brandimageRef = image;
  };

  brandonCropComplete = crop => {
    this.brandmakeClientCrop(crop);
  };
  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], {type: mimeString});


}

  blobToFile(theBlob, fileName){
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
}

  brandonCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ brandcrop: crop });
  };

  loginonCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ logincrop: crop });
  };

  async loginmakeClientCrop(crop) {
    if (this.loginimageRef && crop.width && crop.height) {
      const result = await this.getCroppedImg(
        this.loginimageRef,
        crop,
        'loginpic.png'
      );
    //   const croppedImageUrl = await this.getCroppedImg(
    //     this.imageRef,
    //     crop,
    //     'newFile.jpeg'
    //   );
    const logincroppedImageUrl = result.url;
     
   upload(result.blob).then((data)=>{
    this.setState({...this.state,loginuploadurl: data.url})
    })
      this.setState({ ...this.state, logincroppedImageUrl });
    }
  }
  async brandmakeClientCrop(crop) {
    if (this.brandimageRef && crop.width && crop.height) {
      const result = await this.getCroppedImg(
        this.brandimageRef,
        crop,
        'brandpic.png'
      );
    //   const croppedImageUrl = await this.getCroppedImg(
    //     this.imageRef,
    //     crop,
    //     'newFile.jpeg'
    //   );
    const brandcroppedImageUrl = result.url;
      console.log(brandcroppedImageUrl);
      upload(result.blob).then((data)=>{
     this.setState({...this.state,branduploadurl: data.url})
     })
      this.setState({ ...this.state,brandcroppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve({url: this.fileUrl, blob : blob});
      }, 'image/jpeg');
    });
  }
  handleCheckClick (){

    this.setState({ ...this.state, website:{...this.state.website, enabled: !this.state.website.enabled }});
  }
  onDrag(color, selector) {
    this.setState({
      ...this.state,
      website:{...this.state.website, [selector]: color}
    });
  }
 
  render() {
    const { brandcrop,logincrop, brandcroppedImageUrl,logincroppedImageUrl, brandsrc,loginsrc } = this.state;

    return this.state.loading ? (
      <Container fluid className="main-content-container px-4">
        <Load />
      </Container>
    ) : (
      <div className="App" style={{height: '100%'}}>
        <div> Choose Navbar Logo: &nbsp;
          <input type="file" accept="image/*" onChange={this.brandonSelectFile} />
          {this.state.branduploadurl ?   <div
        className="bg-danger text-white text-center rounded p-3"
        style={{ maxWidth: 'max-content',float: 'left',boxShadow: "inset 0 0 5px rgba(0,0,0,.2)" }}
        onClick = {()=>{
          this.setState({...this.state,loading: true});
            if(brandcroppedImageUrl){
                let id = this.state.currentUser.Institution;
                Dispatcher.dispatch({
                  actionType: Constants.BRAND,
                  payload: {...this.state.brand, brandingpic: this.state.branduploadurl}
                });
                updateBranding(id, {brandingpic: this.state.branduploadurl})
                .then(()=>{
                  window.location.reload();
                }).catch(()=>{
                  this.setState({...this.state,loading: false});
                })
                }
        }}
      >
        Update
      </div>  : '' }
        </div> 
        {brandsrc && (
          <ReactCrop
            src={brandsrc}
            crop={brandcrop}
            ruleOfThirds
            onImageLoaded={this.brandonImageLoaded}
            onComplete={this.brandonCropComplete}
            onChange={this.brandonCropChange}
          />
        )}
        {!brandcroppedImageUrl && (
    
    <img alt="Crop" style={{ marginLeft:'25px' ,maxWidth: '100%',display: 'block' }} src={this.state.brandplaceholder} />
         
       )}
        {brandcroppedImageUrl && (
    
     <img alt="Crop" style={{ marginLeft:'25px' ,maxWidth: '100%',display: 'block' }} src={brandcroppedImageUrl} />
          
        )}
        <div> Choose Login Logo: &nbsp; 
          <input type="file" accept="image/*" onChange={this.loginonSelectFile} />

          {this.state.loginuploadurl ? <div
        className="bg-danger text-white text-center rounded p-3"
        style={{ maxWidth: 'max-content',float: 'left',boxShadow: "inset 0 0 5px rgba(0,0,0,.2)" }}
        onClick = {()=>{
          this.setState({...this.state,loading: true});
            if(logincroppedImageUrl){
                let id = this.state.currentUser.Institution;
                Dispatcher.dispatch({
                  actionType: Constants.BRAND,
                  payload: {...this.state.brand, loginpic: this.state.loginuploadurl}
                });
                updateLogin(id, {loginpic: this.state.loginuploadurl})
                .then(()=>{
                  window.location.reload();
                }).catch(()=>{
                  this.setState({...this.state,loading: false});
                })
                }
        }}
      >
        Update
      </div>  : '' }
        </div>
        {loginsrc && (
          <ReactCrop
            src={loginsrc}
            crop={logincrop}
            ruleOfThirds
            onImageLoaded={this.loginonImageLoaded}
            onComplete={this.loginonCropComplete}
            onChange={this.loginonCropChange}
          />
        )}
         {!logincroppedImageUrl && (
    
    <img alt="Crop" style={{ marginLeft:'25px' ,maxWidth: '100%',display: 'block' }} src={this.state.loginplaceholder} />
      
    )}
        {logincroppedImageUrl && (
    
        <img alt="Crop" style={{ marginLeft:'25px' ,maxWidth: '100%',display: 'block' }} src={logincroppedImageUrl} />
          
        )}

        <div>
          Enable Website: &nbsp;
          <input type="checkbox" checked={this.state.website.enabled} onChange={()=>{this.handleCheckClick()}} className="filled-in" id="filled-in-box"/>
          <br/>
          Main background color: &nbsp;
          <SketchPicker color={this.state.website.mainbgcolor} onChangeComplete={(x)=>{this.onDrag(x.hex,'mainbgcolor')}} />
      <div style={{
        background: this.state.website.mainbgcolor,
        width: 100,
        height: 50,
        color: 'white'
      }}>
        {this.state.website.mainbgcolor}
      </div>
      <br/>
      <div>
       Subdomain: <input type="text" onChange={(e)=>{this.setState({...this.state,website:{...this.state.website,subdomain: e.target.value}})}}/>.lesson21.com
      </div>
      <br/>
      <div>
       Whatsapp: <input type="text" onChange={(e)=>{this.setState({...this.state,website:{...this.state.website,whatsapp: e.target.value}})}}/> include country code like +91XXXXXXXXXX, leave empty to disable.
      </div>
      <br/>
      Initial Text color: &nbsp;
          <SketchPicker color={this.state.website.initialtextcolor} onChangeComplete={(x)=>{this.onDrag(x.hex,'initialtextcolor')}} />
      <div style={{
        background: this.state.website.initialtextcolor,
        width: 100,
        height: 50,
        color: 'white'
      }}>
        {this.state.website.initialtextcolor}
      </div>
      <br/>
      Special Area background: &nbsp;
          <SketchPicker color={this.state.website.specialareabackground} onChangeComplete={(x)=>{this.onDrag(x.hex,'specialareabackground')}} />
      <div style={{
        background: this.state.website.specialareabackground,
        width: 100,
        height: 50,
        color: 'white'
      }}>
        {this.state.website.specialareabackground}
      </div>
        </div>
        <br/>
        <button onClick={()=>{
          let id = this.state.currentUser.Institution;
          saveWebsite(id,this.state.website);
          console.log(this.state.website)}}>Save</button>
      <br/>
      <br/>
      <button onClick={()=>{
         window.open(`https://lesson21.com/ploojk9656453944/${this.state.website.subdomain}/dbo.php?action=edit`);
         
         }}>Edit Website</button>
     
      </div>
      
     
    );
  }
}

export default Branding;

