import React, { Component } from "react";
import axios from "axios";
import { chunkUpload, addQuiz } from "../services/api";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Form,
  Button,
  FormInput,
  FormSelect,
  FormGroup
} from "shards-react";
import { Store } from "../flux";
import PageTitle from "../components/common/PageTitle";

class CreateQuiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: Store.getCurrentUser(),
      quizTitle: "",
      quizSynopsis: "",
      questions: [],
      initalised: false,
      obj: {
        question: "",
        questionType: "text",
        answers: [],
        correctAnswer: "",
        messageForCorrectAnswer: "Correct answer. Good job.",
        messageForIncorrectAnswer: "Incorrect answer. Please try again.",
        explanation: "",
        point: 0
      }
    };
  }
  config = {
    onUploadProgress: progressEvent => {
      this.progressSum+=progressEvent.loaded;
      let progress = (this.progressSum / this.state.fileSize) * 100;
      if(parseInt(progress) >=100){
        this.progressSum = 0;
        progress = 100;
      }
      this.setState({...this.state,buttonText:`Uploading ${parseInt(progress)}%`})
    }
  }
  progressSum = 0;
  addQuiz(addData) {
    let body = {
      Name: addData.quizTitle,
      ShortCode: this.props.match.params.id,
      ModifiedTime: "2021-01-01",
      Status: 1,
      Content: JSON.stringify(addData)
    };
    addQuiz(body, this.state.currentUser).then(res => {
      this.props.history.push(`/quizgallery/${this.props.match.params.id}`);
    });
  }
  render() {
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Quiz"
            subtitle="Lesson 21"
            className="text-sm-left mb-3"
          />
        </Row>

        {/* Small Stats Blocks */}
        <Row>
          <Col className="col-lg mb-4">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Create Quiz</h6>
              </CardHeader>

              <ListGroup flush>
                <ListGroupItem className="px-3">
                  {!this.state.initalised ? (
                    <Form>
                      <FormGroup>
                        <FormInput
                          onChange={e => {
                            this.setState({
                              ...this.state,
                              quizTitle: e.target.value
                            });
                          }}
                          placeholder="Title"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormInput
                          onChange={e => {
                            this.setState({
                              ...this.state,
                              quizSynopsis: e.target.value
                            });
                          }}
                          placeholder="Synopsis"
                        />
                      </FormGroup>
                    
                      <Button
                        onClick={() => {
                          if (
                            this.state.quizTitle.length > 0 &&
                            this.state.quizSynopsis.length > 0
                          ) {
                            this.setState({ ...this.state, initalised: true });
                          } else {
                            alert("Fields Required");
                          }
                        }}
                      >
                        Create Quiz
                      </Button>
                    </Form>
                  ) : (
                    <Form>
                      <FormGroup>
                        <FormInput
                          value={this.state.obj.question}
                          onChange={e => {
                            this.setState({
                              ...this.state,
                              obj: {
                                ...this.state.obj,
                                question: e.target.value
                              }
                            });
                          }}
                          placeholder="Question"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormSelect
                          value={this.state.obj.questionType}
                          onChange={e => {
                            this.setState({
                              ...this.state,
                              obj: {
                                ...this.state.obj,
                                questionType: e.target.value
                              }
                            });
                          }}
                          placeholder="Answer"
                        >
                          <option value="text">Text</option>
                          <option value="photo">Image</option>
                        </FormSelect>
                      </FormGroup>
                      {this.state.obj.answers.map((input, index) =>
                        this.state.obj.questionType == "photo" ? (
                          <FormGroup>
                            <FormInput
                              type="file"
                              name={index}
                              onChange={e => {
                                let ansArray = this.state.obj.answers;
                                let name = e.target.name;
                                chunkUpload(e,this.config,this.state.currentUser)
                                  .then(data => {
                                    ansArray[name] = data;
                                    
                                    this.setState({
                                      ...this.state,
                                      obj: {
                                        ...this.state.obj,
                                        answers: ansArray
                                      }
                                    });
                                  })
                                  .catch((err) => {
                                    if(err=="You have exceeded your storage quota. Please Upgrade."){
                                      alert(err);
                                      //this.setState({ ...this.state, showModal: false });
                                    }
                                  });
                              }}
                              key={index}
                            />
                          </FormGroup>
                        ) : (
                          <FormGroup>
                            <FormInput
                              name={index}
                              onChange={e => {
                                let ansArray = this.state.obj.answers;
                                ansArray[e.target.name] = e.target.value;
                                this.setState({
                                  ...this.state,
                                  obj: { ...this.state.obj, answers: ansArray }
                                });
                              }}
                              key={index}
                            />
                          </FormGroup>
                        )
                      )}
                      <FormGroup>
                        <Button
                          onClick={() => {
                            let ansArray = this.state.obj.answers;
                            ansArray.push("");
                            
                            this.setState({
                              ...this.state,
                              obj: { ...this.state.obj, answers: ansArray }
                            });
                          }}
                        >
                          Add Answer
                        </Button>
                      </FormGroup>
                      <FormGroup>
                        <FormSelect
                          onChange={e => {
                            this.setState({
                              ...this.state,
                              obj: {
                                ...this.state.obj,
                                correctAnswer: e.target.value
                              }
                            });
                          }}
                        >
                          <option>Choose Correct Answer ...</option>
                          {this.state.obj.answers.map((input, index) => (
                            <option value={index + 1}>
                              {this.state.obj.answers[index].replace(
                                "https://www.lesson21.com/app/upload/uploads/",
                                ""
                              )}
                            </option>
                          ))}
                        </FormSelect>
                      </FormGroup>
                      <FormGroup>
                        <FormInput
                          value={this.state.obj.messageForCorrectAnswer}
                          onChange={e => {
                            this.setState({
                              ...this.state,
                              obj: {
                                ...this.state.obj,
                                messageForCorrectAnswer: e.target.value
                              }
                            });
                          }}
                          placeholder="Message for Correct Answer"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormInput
                          value={this.state.obj.messageForIncorrectAnswer}
                          onChange={e => {
                            this.setState({
                              ...this.state,
                              obj: {
                                ...this.state.obj,
                                messageForIncorrectAnswer: e.target.value
                              }
                            });
                          }}
                          placeholder="Message for Incorrect Answer"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormInput
                          value={this.state.obj.explanation}
                          onChange={e => {
                            this.setState({
                              ...this.state,
                              obj: {
                                ...this.state.obj,
                                explanation: e.target.value
                              }
                            });
                          }}
                          placeholder="Explanation"
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormInput
                          value={this.state.obj.point}
                          onChange={e => {
                            this.setState({
                              ...this.state,
                              obj: { ...this.state.obj, point: e.target.value }
                            });
                          }}
                          placeholder="Point"
                        />
                      </FormGroup>
                      <Row form>
                        <Col md="5">
                          <Button
                            onClick={() => {
                              let obj = this.state.obj;
                              let questions = this.state.questions;
                              questions.push(obj);
                              this.setState({
                                ...this.state,
                                questions: questions,
                                obj: {
                                  question: "",
                                  questionType: "text",
                                  answers: [],
                                  correctAnswer: "",
                                  messageForCorrectAnswer:
                                    "Correct answer. Good job.",
                                  messageForIncorrectAnswer:
                                    "Incorrect answer. Please try again.",
                                  explanation: "",
                                  point: 0
                                }
                              });
                            }}
                          >
                            Next
                          </Button>
                        </Col>
                        <Col md="7" className="form-group">
                          <Button
                            onClick={() => {
                              let obj = this.state.obj;
                              let questions = this.state.questions;
                              questions.push(obj);
                              this.setState({
                                ...this.state,
                                questions: questions,
                                obj: {
                                  question: "",
                                  questionType: "text",
                                  answers: [],
                                  correctAnswer: "",
                                  messageForCorrectAnswer:
                                    "Correct answer. Good job.",
                                  messageForIncorrectAnswer:
                                    "Incorrect answer. Please try again.",
                                  explanation: "",
                                  point: 0
                                }
                              });
                              this.addQuiz({
                                quizTitle: this.state.quizTitle,
                                quizSynopsis: this.state.quizSynopsis,
                                questions: this.state.questions
                              });
                            }}
                          >
                            Create Quiz
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default CreateQuiz;
