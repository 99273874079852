import React, { Component } from "react";
import { Container, Row, Col } from "shards-react";
import Quiz from "react-quiz-component";
import AttendExam from "../components/AttendExam";


class MCQ extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, videoId: null };
  }
  setOpen(val, id) {
    this.setState({
      isOpen: val,
      videoId: id != null ? this.props.smallStats[id].videoId : id
    });
    
  }
  render() {
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}

        {/* Small Stats Blocks */}
        <Row>
          <Col className="col-lg mb-4 lesson21Quiz">
            <AttendExam quiz={this.props.src} shuffle={true} />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default MCQ;
