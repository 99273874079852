import React, { Component } from "react";
import { Container } from "shards-react";
import "react-contexify/dist/ReactContexify.css";
import { Store } from "../flux";
import DashNavigate from "./DashNavigate";
import ManageStudents from "./ManageStudents";
import ManageTeachers from "./ManageTeachers";
import ManageCourses from "./ManageCourses";
import Joyride from 'react-joyride';

const steps =  [
  {
    content: (
      <div>
        You can interact with your own components through the spotlight.
        <br />
        Click the menu above!
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
    placement: 'bottom',
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.dropdown',
    title: 'Menu',
  },
  {
    content: (
      <div>
        You can interact with your own components through the spotlight.
        <br />
        Click the menu above!
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
    placement: 'right',
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.step-2',
    title: 'Menu',
  }
]

const initialTabs = {
  tab1: "dark",
  tab2: "dark",
  tab3: "dark",
  tab4: "dark",
  tab5: "dark",
  tab6: "dark",
  tab7: "dark"
};

class AdminManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      run: true,
      stepIndex: 0,
      hover: false,
      currentUser: Store.getCurrentUser(),
      hoverId: 0,
      posts: [],
      tabs: {
        tab1: "danger",
        tab2: "dark",
        tab3: "dark",
        tab4: "dark",
        tab5: "dark",
        tab6: "dark",
        tab7: "dark"
      }
    };
  }

  render() {
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <DashNavigate history={this.props.history} match={this.props.match} />
        {
          {
            STUDENT: <ManageStudents></ManageStudents>,
            TEACHER: <ManageTeachers></ManageTeachers>,
            COURSE: <ManageCourses></ManageCourses>
          }[this.props.match.params.id]
        }
        {/* <Joyride
          continuous={true}
          run={this.state.run && window.location.href.includes('home')}
          steps={steps}
          stepIndex={this.state.stepIndex}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          callback={(x)=>{
            if(x.action == 'next' && x.lifecycle == 'complete'){
              this.setState({...this.state, stepIndex: x.index+1})
            }
            console.log(x);
          //  this.setState({...this.state, stepIndex: this.state.stepIndex+1})
          }}
        /> */}
      </Container>
    );
  }
}

export default AdminManage;
