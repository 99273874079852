import { EventEmitter } from "events";

import Dispatcher from "./dispatcher";
import Constants from "./constants";
import getSidebarNavItems from "../data/sidebar-nav-items";
import { useReducer } from "react";

const cipher = salt => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

  return text => text.split('')
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join('');
}

const decipher = salt => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
  return encoded => encoded.match(/.{1,2}/g)
      .map(hex => parseInt(hex, 16))
      .map(applySaltToChar)
      .map(charCode => String.fromCharCode(charCode))
      .join('');
}
function isJson(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}
function encrypt(secret){
  let myCipher = cipher('eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ikxlc3NvbjIxIGlzIGF3ZXNvbWUiLCJpYXQiOjE1MTYyMzkwMjJ9')
  return myCipher(secret)
}
function decrypt(secret){
  if(!isJson(secret)){
  let myDecipher = decipher('eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ikxlc3NvbjIxIGlzIGF3ZXNvbWUiLCJpYXQiOjE1MTYyMzkwMjJ9')
  return myDecipher(secret);
  } else {
    return secret;
  }
}
let _store = {
  menuVisible: false,
  navItems: getSidebarNavItems(),
  Plan: localStorage.getItem("Plan") !== null
  ? JSON.parse(decrypt(localStorage.getItem("Plan")))
  : null,
  Brand: localStorage.getItem("Brand") !== null
  ? JSON.parse(decrypt(localStorage.getItem("Brand")))
  : null,
  User:
    localStorage.getItem("User1") !== null
      ? JSON.parse(decrypt(localStorage.getItem("User1")))
      : null,
  Tour:
    localStorage.getItem("Tour") !== null
        ? JSON.parse(decrypt(localStorage.getItem("Tour")))
        : null
};

class Store extends EventEmitter {
  constructor() {
    super();

    this.registerToActions = this.registerToActions.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);

    Dispatcher.register(this.registerToActions.bind(this));
  }

  registerToActions({ actionType, payload }) {
    switch (actionType) {
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar();
        break;
      case Constants.LOGIN:
        this.login(payload);
        break;
      case Constants.LOGOUT:
          this.logout();
        break;
      case Constants.BRAND:
          this.brand(payload);
          break;
     case Constants.PLAN:
        this.setPlan(payload);
        break;
    case Constants.CLEARPLAN:
          this.clearPlan();
          break;
    case Constants.TOUR:
        this.setTour(payload);
        break;
      default:
    }
  }

  login(data) {
    _store.User = data;
    localStorage.setItem("User1", encrypt(JSON.stringify(data)));
    this.emit(Constants.CHANGE);
  }

  logout() {
    localStorage.setItem("User1", null);
    this.emit(Constants.CHANGE);
  }
  brand(data) {
    _store.Brand = data;
    localStorage.setItem("Brand", encrypt(JSON.stringify(data)));
    this.emit(Constants.CHANGE);
  }
  setPlan(data) {
    _store.Plan = data;
    localStorage.setItem("Plan", encrypt(JSON.stringify(data)));
    this.emit(Constants.CHANGE);
  }
  setTour(data) {
    _store.Plan = data;
    localStorage.setItem("Tour", encrypt(JSON.stringify(data)));
    this.emit(Constants.CHANGE);
  }
  clearPlan() {
    localStorage.removeItem("Plan")
    this.emit(Constants.CHANGE);
  }
  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.emit(Constants.CHANGE);
  }

  getMenuState() {
    return _store.menuVisible;
  }
  getCurrentUser() {
    return _store.User;
  }
  getBrand() {
    return _store.Brand;
  }
  getCurrentPlan() {
    return _store.Plan;
  }
  getTour(){
    return _store.Tour;
  }
  getSidebarItems() {
    return _store.navItems;
  }

  addChangeListener(callback) {
    this.on(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }
}

export default new Store();
