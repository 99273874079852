import React from "react";
import { Container, Row, Col } from "shards-react";
import { FiBookOpen } from "react-icons/fi";
import { MdOndemandVideo } from "react-icons/md";
import { BsFileEarmarkText } from "react-icons/bs";
import { AiFillQuestionCircle } from "react-icons/ai";
import { MdContacts } from "react-icons/md";
import { IoIosRadio } from "react-icons/io";
import { Store } from "../flux";
import ReactHoverObserver from 'react-hover-observer';
import {isMobile} from 'react-device-detect';
import {
  getLiveLink,
  getMeetingLink
} from "../services/api";
import Load from "../Load";
import CustomMeeting from "../CustomMeeting";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Navigate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { link: null,hoverID: null, hover: false, currentUser: Store.getCurrentUser(),call:false,loading:false };
    this.toggleHover = this.toggleHover.bind(this);
    
  }
  toggleHover(id) {
    this.setState({ ...this.state, hoverID: id, hover: !this.state.hover });
  }
  render() {
    var initalStyles = [
      { color: "#ff527a", backgroundColor: "#ffdee5" },
      { color: "#5600d4", backgroundColor: "#d8d8f7" },
      { color: "#feb628", backgroundColor: "#fef3d6" },
      { color: "#f68445", backgroundColor: "#fde7da" },
      { color: "#8e00b2", backgroundColor: "#e8d1f0" },
      { color: "#3793f8", backgroundColor: "#cbecff" }
    ];
    var buttonStyles = [
      { color: "#FFFFFF", backgroundColor: "#ff527a" },
      { color: "#FFFFFF", backgroundColor: "#5600d4" },
      { color: "#FFFFFF", backgroundColor: "#feb628" },
      { color: "#FFFFFF", backgroundColor: "#f68445" },
      { color: "#FFFFFF", backgroundColor: "#8e00b2" },
      { color: "#FFFFFF", backgroundColor: "#3793f8" }
    ];
    var bStyles = initalStyles;
    

    return ( this.state.loading ? (
      <Container fluid className="main-content-container px-4">
        <Load />
      </Container>
    ) :
      <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
         <ToastContainer />
        {!isMobile ?
        <Col style={{ maxWidth: "max-content", marginRight: "30px" }}>
          <div
            style={{
              marginTop: "20px",
              width: "max-content",
              marginBottom: "20px",
              paddingLeft: "30px",
              fontSize: "1.2em",
              paddingRight: "30px",
              borderRadius: "0.5rem",
              backgroundColor: "#f5f6f8",
              paddingTop: !isMobile? "16.5px" : "11.5px",
              minHeight: "60px"
            }}
          >
              <div>
              <span style={{ paddingRight: "15px", fontWeight: "bold" }}>
                Dashboard
              </span>{" "}
              | <span style={{ paddingLeft: "15px" }}>Courses</span>
            </div>
          </div>
        </Col> : ''}
        <ReactHoverObserver>
        {({ isHovering }) => (
        <div
         
          onClick={e => {
            this.setState({...this.state,loading:true});
            let name = `${this.state.currentUser.FirstName}+${this.state.currentUser.LastName}`
            let type = this.state.currentUser.Type == 'STUDENT' ? 'false' :  'true';
            let course = this.props.match.params.id;
            getMeetingLink(course,this.state.currentUser).then((x)=>{
              this.setState({...this.state,loading:false});
              if(x == 'NOT STARTED'){
                toast.error("The meeting havent started.");
              } else {
                window.location.href = x;
              //window.open(x);
              }
              // if(x.includes("phase2/lesson21")){
              //   x= x.replace("[firstname]",this.state.currentUser.FirstName);
              //   x=x.replace("[lastname]",this.state.currentUser.LastName);
              //   x=x.replace("[course]",course);
              //   x=x.replace("[moderator]",this.state.currentUser.Type == 'STUDENT'? 'false' : 'true');
              // } else if(x.includes("lesstry")){
              //   this.props.history.push({
              //     pathname: "/mymeeting",
              //     link: x
              //   });
              // }
              // if(!x.includes("lesstry")){
              // window.open(x);
              // }
            }).catch(()=>{
              this.setState({...this.state,loading:false});
            });
            //window.open(url);
          }}
          style={isHovering ?{
            marginTop: "20px",
            float: "right",
            maxWidth: "max-content",
            marginLeft: !isMobile ? "36px" : "0%", transform: !isMobile ? 'scale(1)': 'scale(0.8)', 
            marginBottom: "20px",
            paddingLeft: !isMobile ? "18.897637795px" :  "13.897637795px",
            fontSize: "1.2em",
            paddingRight: !isMobile ? "18.897637795px" : "13.897637795px",
            borderRadius: "0.5rem",
            paddingTop: !isMobile? "16.5px" : "11.5px",
            minHeight: !isMobile ? "60px" : "50px",
            ...buttonStyles[0]
          }:{ marginTop: "20px",
          float: "right",
          maxWidth: "max-content",
          marginLeft: !isMobile ? "36px" : "0%", transform: !isMobile ? 'scale(1)': 'scale(0.8)', 
          marginBottom: "20px",
          paddingLeft: !isMobile ? "18.897637795px" :  "13.897637795px",
          fontSize: "1.2em",
          paddingRight: !isMobile ? "18.897637795px" : "13.897637795px",
          borderRadius: "0.5rem",
          paddingTop: !isMobile? "16.5px" : "11.5px",
          minHeight: !isMobile ? "60px" : "50px",
          ...bStyles[0]
          }}
        >
          <div>
            <span style={{ marginRight: "5px" }}>
              <IoIosRadio />
            </span>
            {!isMobile ? <span>Live</span>:''}
          </div>
        </div>
        )}
        </ReactHoverObserver>
        <ReactHoverObserver>
        {({ isHovering }) => (
          
        
        <div
       
          onClick={e => {
            this.props.history.push(`/course/${this.props.match.params.id}`);
          }}
          style={isHovering ?{
            marginTop: "20px",
            float: "right",
            maxWidth: "max-content",
            marginLeft: !isMobile ? "36px" : "0%", transform: !isMobile ? 'scale(1)': 'scale(0.8)', 
            marginBottom: "20px",
            paddingLeft: !isMobile ? "18.897637795px" :  "13.897637795px",
            fontSize: "1.2em",
            paddingRight: !isMobile ? "18.897637795px" : "13.897637795px",
            borderRadius: "0.5rem",
            paddingTop: !isMobile? "16.5px" : "11.5px",
            minHeight: !isMobile ? "60px" : "50px",
            ...buttonStyles[1]
          }:{ marginTop: "20px",
          float: "right",
          maxWidth: "max-content",
          marginLeft: !isMobile ? "36px" : "0%", transform: !isMobile ? 'scale(1)': 'scale(0.8)', 
          marginBottom: "20px",
          paddingLeft: !isMobile ? "18.897637795px" :  "13.897637795px",
          fontSize: "1.2em",
          paddingRight: !isMobile ? "18.897637795px" : "13.897637795px",
          borderRadius: "0.5rem",
          paddingTop: !isMobile? "16.5px" : "11.5px",
          minHeight: !isMobile ? "60px" : "50px",
          ...bStyles[1]
          }}
        >
          <div>
            <span style={{ marginRight: "5px" }}>
              <FiBookOpen />
            </span>
            {!isMobile ? <span>Lessons</span>:''}
          </div>
        </div>
        )}
        </ReactHoverObserver>
        <ReactHoverObserver>
        {({ isHovering }) => (
        <div
          
          onClick={e => {
            this.props.history.push(`/videos/${this.props.match.params.id}`);
          }}
          style={isHovering ?{
            marginTop: "20px",
            float: "right",
            maxWidth: "max-content",
            marginLeft: !isMobile ? "36px" : "0%", transform: !isMobile ? 'scale(1)': 'scale(0.8)', 
            marginBottom: "20px",
            paddingLeft: !isMobile ? "18.897637795px" :  "13.897637795px",
            fontSize: "1.2em",
            paddingRight: !isMobile ? "18.897637795px" : "13.897637795px",
            borderRadius: "0.5rem",
            paddingTop: !isMobile? "16.5px" : "11.5px",
            minHeight: !isMobile ? "60px" : "50px",
            ...buttonStyles[2]
          }:{ marginTop: "20px",
          float: "right",
          maxWidth: "max-content",
          marginLeft: !isMobile ? "36px" : "0%", transform: !isMobile ? 'scale(1)': 'scale(0.8)', 
          marginBottom: "20px",
          paddingLeft: !isMobile ? "18.897637795px" :  "13.897637795px",
          fontSize: "1.2em",
          paddingRight: !isMobile ? "18.897637795px" : "13.897637795px",
          borderRadius: "0.5rem",
          paddingTop: !isMobile? "16.5px" : "11.5px",
          minHeight: !isMobile ? "60px" : "50px",
          ...bStyles[2]
          }}
        >
          <div>
            <span style={{ marginRight: "5px" }}>
              <MdOndemandVideo />
            </span>
            {!isMobile ? <span>Videos</span> :''}
          </div>
        </div>
        )}
        </ReactHoverObserver>
        <ReactHoverObserver>
        {({ isHovering }) => (
        <div
          
          onClick={e => {
            this.props.history.push(
              `/assignments/${this.props.match.params.id}`
            );
          }}
          style={isHovering ?{
            marginTop: "20px",
            float: "right",
            maxWidth: "max-content",
            marginLeft: !isMobile ? "36px" : "0%", transform: !isMobile ? 'scale(1)': 'scale(0.8)', 
            marginBottom: "20px",
            paddingLeft: !isMobile ? "18.897637795px" :  "13.897637795px",
            fontSize: "1.2em",
            paddingRight: !isMobile ? "18.897637795px" : "13.897637795px",
            borderRadius: "0.5rem",
            paddingTop: !isMobile? "16.5px" : "11.5px",
            minHeight: !isMobile ? "60px" : "50px",
            ...buttonStyles[3]
          }:{ marginTop: "20px",
          float: "right",
          maxWidth: "max-content",
          marginLeft: !isMobile ? "36px" : "0%", transform: !isMobile ? 'scale(1)': 'scale(0.8)', 
          marginBottom: "20px",
          paddingLeft: !isMobile ? "18.897637795px" :  "13.897637795px",
          fontSize: "1.2em",
          paddingRight: !isMobile ? "18.897637795px" : "13.897637795px",
          borderRadius: "0.5rem",
          paddingTop: !isMobile? "16.5px" : "11.5px",
          minHeight: !isMobile ? "60px" : "50px",
          ...bStyles[3]
          }}
        >
          <div>
            <span style={{ marginRight: "5px" }}>
              <BsFileEarmarkText />
            </span>
            {!isMobile ? <span>Assignments</span> :''}
          </div>
        </div>
        )}
        </ReactHoverObserver>
        <ReactHoverObserver>
        {({ isHovering }) => (
        <div
         
          onClick={e => {
            this.props.history.push(
              `/quizgallery/${this.props.match.params.id}`
            );
          }}
          style={isHovering ?{
            marginTop: "20px",
            float: "right",
            maxWidth: "max-content",
            marginLeft: !isMobile ? "36px" : "0%", transform: !isMobile ? 'scale(1)': 'scale(0.8)', 
            marginBottom: "20px",
            paddingLeft: !isMobile ? "18.897637795px" :  "13.897637795px",
            fontSize: "1.2em",
            paddingRight: !isMobile ? "18.897637795px" : "13.897637795px",
            borderRadius: "0.5rem",
            paddingTop: !isMobile? "16.5px" : "11.5px",
            minHeight: !isMobile ? "60px" : "50px",
            ...buttonStyles[4]
          }:{ marginTop: "20px",
          float: "right",
          maxWidth: "max-content",
          marginLeft: !isMobile ? "36px" : "0%", transform: !isMobile ? 'scale(1)': 'scale(0.8)', 
          marginBottom: "20px",
          paddingLeft: !isMobile ? "18.897637795px" :  "13.897637795px",
          fontSize: "1.2em",
          paddingRight: !isMobile ? "18.897637795px" : "13.897637795px",
          borderRadius: "0.5rem",
          paddingTop: !isMobile? "16.5px" : "11.5px",
          minHeight: !isMobile ? "60px" : "50px",
          ...bStyles[4]
          }}
        >
          <div>
            <span style={{ marginRight: "5px" }}>
              <AiFillQuestionCircle />
            </span>
            {!isMobile ? <span>Quiz</span>:''}
          </div>
        </div>
        )}
        </ReactHoverObserver>
        <ReactHoverObserver>
        {({ isHovering }) => (
        <div
         
          onClick={e => {
            this.props.history.push(
              `/course_library/${this.props.match.params.id}`
            );
          }}
          style={isHovering ?{
            marginTop: "20px",
            float: "right",
            maxWidth: "max-content",
            marginLeft: !isMobile ? "36px" : "0%", transform: !isMobile ? 'scale(1)': 'scale(0.8)', 
            marginBottom: "20px",
            paddingLeft: !isMobile ? "18.897637795px" :  "13.897637795px",
            fontSize: "1.2em",
            paddingRight: !isMobile ? "18.897637795px" : "13.897637795px",
            borderRadius: "0.5rem",
            paddingTop: !isMobile? "16.5px" : "11.5px",
            minHeight: !isMobile ? "60px" : "50px",
            ...buttonStyles[5]
          }:{ marginTop: "20px",
          float: "right",
          maxWidth: "max-content",
          marginLeft: !isMobile ? "36px" : "0%", transform: !isMobile ? 'scale(1)': 'scale(0.8)', 
          marginBottom: "20px",
          paddingLeft: !isMobile ? "18.897637795px" :  "13.897637795px",
          fontSize: "1.2em",
          paddingRight: !isMobile ? "18.897637795px" : "13.897637795px",
          borderRadius: "0.5rem",
          paddingTop: !isMobile? "16.5px" : "11.5px",
          minHeight: !isMobile ? "60px" : "50px",
          ...bStyles[5]
          }}
        >
          <div>
            <span style={{ marginRight: "5px" }}>
              <MdContacts />
            </span>
            {!isMobile ? <span>Resourses</span>: ''}
          </div>
        </div>
        )}
        </ReactHoverObserver>
      </Row>
    );
  }
}

export default Navigate;
