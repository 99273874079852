import React, { Component } from "react";
import { Container, Row } from "shards-react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import PageTitle from "../components/common/PageTitle";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { getPlan } from "../services/api";
import moment from "moment";
import Modal from "react-modal";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  FormInput,
  Button
} from "shards-react";
import { Store } from "../flux";
import { getEvents, addEvent } from "../services/api";
import { Dispatcher, Constants } from "../flux";

const localizer = momentLocalizer(moment);

class Planner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: Store.getCurrentPlan(),
      currentUser: Store.getCurrentUser(),
      modalIsOpen: false,
      events: [],
      newEvent: { start: moment().toDate(), end: moment().toDate(), title: "" }
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  handleOpenModal(e) {
    
   
    this.setState({
      ...this.state,
      modalIsOpen: true,
      newEvent: { start: e.start, end: e.end, title: "" }
    });
  }
  handleCloseModal() {
    this.setState({ ...this.state, modalIsOpen: false });
  }
  componentDidMount(){
    getPlan(this.state.currentUser.Institution,this.state.currentUser).then((data)=>{
      this.setState({...this.state,plan: data});
      Dispatcher.dispatch({
        actionType: Constants.PLAN,
        payload: data
      });
      
    }).catch(()=>{});

getEvents(this.state.currentUser.id,this.state.currentUser).then((data)=>{
this.setState({...this.state,events:JSON.parse(data.events)})
}).catch(()=>{

});
  }
  render() {
    return (
      <Container fluid className={`main-content-container px-4 ${this.state.plan.Planner == '1' ? '' : 'grey'}`}>
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Calendar"
            subtitle="Personal"
            className="text-sm-left mb-3"
          />
        </Row>

      

        <Modal
          isOpen={this.state.modalIsOpen}
          contentLabel="onRequestClose Example"
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick={true}
          className="library_modal"
          overlayClassName="library_overlay"
          ariaHideApp={false}
        >
          <Card small>
           
            <CardHeader className="border-bottom">
              <h6 className="m-0">Add New Event</h6>
            </CardHeader>

            <ListGroup flush>
              <ListGroupItem className="px-3">
                <strong className="text-muted d-block mb-2">Name</strong>
                <div className="custom-file mb-3">
                  <FormInput
                    onChange={e => {
                      this.setState({
                        ...this.state,
                        newEvent: {
                          ...this.state.newEvent,
                          title: e.target.value
                        }
                      });
                    }}
                  />
                </div>

                <div className="custom-file mb-3">
                  <Button
                    onClick={() => {
                      let objs = this.state.events;
                      objs.push(this.state.newEvent);

                      addEvent(this.state.currentUser.id,objs,this.state.currentUser)
                      .then(()=>{})
                      .catch(()=>{});

                      this.setState({
                        ...this.state,
                        modalIsOpen: false,
                        events: objs
                      });
                    }}
                  >
                    Add Event
                  </Button>
                </div>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Modal>
        <Calendar
          selectable={true}
          localizer={localizer}
          views = {['month']}
          defaultDate={new Date()}
          defaultView="month"
          events={this.state.events}
          onSelectEvent={event => {
            const r = window.confirm("Would you like to remove this event?")
   if(r === true){
    let eventSet = this.state.events;
    let id = eventSet.indexOf(event);
    eventSet.splice(id,1);
    addEvent(this.state.currentUser.id,eventSet,this.state.currentUser)
                      .then(()=>{})
                      .catch(()=>{});
    this.setState({...this.state,events:eventSet});
    
   }
          }}
          onSelectSlot={e => {
            this.handleOpenModal(e);
          }}
          style={{ height: "100vh" }}
        />
      </Container>
    );
  }
}

export default Planner;
