import React, { Component } from "react";
import { Container, Row, Col } from "shards-react";
import { addLesson } from "../services/api";
import { Store } from "../flux";
import PageTitle from "../components/common/PageTitle";
import Editor from "../components/add-new-post/Editor";
import SidebarActions from "../components/add-new-post/SidebarActions";
import SidebarCategories from "../components/add-new-post/SidebarCategories";

class AddNewPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      editorHtml: "",
      currentUser: Store.getCurrentUser()
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleTitle = this.handleTitle.bind(this);
    this.publish = this.publish.bind(this);
  }
  handleChange(value) {
    this.setState({ ...this.state, editorHtml: value });
  }
  handleTitle(value) {
    this.setState({ ...this.state, title: value });
  }
  publish() {
    let data = {
      Name: this.state.title,
      ShortCode: "CHEM",
      ModifiedTime: "2021-03-02",
      Status: 1,
      Content: this.state.editorHtml
    };
    addLesson(data, this.state.currentUser)
      .then(data => {
        
      })
      .catch(err => console.log(err));
  }
  render() {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Add New Post"
            subtitle="Blog Posts"
            className="text-sm-left"
          />
        </Row>

        <Row>
          {/* Editor */}
          <Col lg="9" md="12">
            <Editor
              handleChange={this.handleChange}
              handleTitle={this.handleTitle}
            />
          </Col>

          {/* Sidebar Widgets */}
          <Col lg="3" md="12">
            <SidebarActions publish={this.publish} />
            {/* <SidebarCategories /> */}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default AddNewPost;
