import React from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  FormSelect,
  Card,
  CardHeader,
  CardBody,
  CardFooter
} from "shards-react";
import { getStorageSize } from "../services/api";
import { Store } from "../flux";

import Chart from "../utils/chart";

class Storage extends React.Component {
  constructor(props) {
    super(props);
     this.state = {
       storage: 0,
       plan:Store.getCurrentPlan(),
       chartConfig: null,
       currentUser: Store.getCurrentUser()
     }
    this.canvasRef = React.createRef();
  } 

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

  componentDidMount() {
    getStorageSize(this.state.currentUser).then((x)=>{
      this.setState({...this.state,storage:this.formatBytes(parseInt(x))});
      let chartConfig = {
        type: "pie",
        data: {
          datasets: [
            {
              hoverBorderColor: "#ffffff",
              data: [(parseInt(x)/1024/1024/1024).toFixed(2),parseInt(this.state.plan.UploadLimit)],
              backgroundColor: [
                "rgba(0,123,255,0.9)",
                "rgba(0,123,255,0.5)",
                "rgba(0,123,255,0.3)"
              ]
            }
          ],
          labels: [`${this.formatBytes(parseInt(x))} Used` ,`Free ${this.formatBytes(parseInt(this.state.plan.UploadLimit)*1024*1024*1024)}` ]
        },
        options: {
          ...{
            legend: {
              position: "bottom",
              labels: {
                padding: 25,
                boxWidth: 20
              }
            },
            cutoutPercentage: 0,
            tooltips: {
              custom: false,
              mode: "index",
              position: "nearest"
            }
          },
          ...this.props.chartOptions
        }
      };
  
      new Chart(this.canvasRef.current, chartConfig);
       }).catch(()=>{
     
       });
       let chartConfig = {
        type: "pie",
        data: {
          datasets: [
            {
              hoverBorderColor: "#ffffff",
              data: [0,5],
              backgroundColor: [
                "rgba(0,123,255,0.9)",
                "rgba(0,123,255,0.5)",
                "rgba(0,123,255,0.3)"
              ]
            }
          ],
          labels: ["Used" ,"Free" ]
        },
        options: {
          ...{
            legend: {
              position: "bottom",
              labels: {
                padding: 25,
                boxWidth: 20
              }
            },
            cutoutPercentage: 0,
            tooltips: {
              custom: false,
              mode: "index",
              position: "nearest"
            }
          },
          ...this.props.chartOptions
        }
      };
  
      new Chart(this.canvasRef.current, chartConfig);
    
  }

  render() {
    const { title } = this.props;
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <CardBody className="d-flex py-0">
          <canvas
            height="220"
            ref={this.canvasRef}
            className="blog-users-by-device m-auto"
          />
        </CardBody>
        <CardFooter className="border-top">
          <Row>
           <Col><center>Upgrade For More Storage</center></Col>
          </Row>
        </CardFooter>
      </Card>
    );
  }
}

Storage.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart config object.
   */
  chartConfig: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object,
  /**
   * The chart data.
   */
  chartData: PropTypes.object
};

Storage.defaultProps = {
  title: "Storage",

};

export default Storage;
