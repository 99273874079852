export default function() {
  return [
    {
      title: "Home",
      to: "/home",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      htmlAfter: ""
    },
    {
      title: "Tasks",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/tasks"
    },
    {
      title: "Notice Board",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/noticeboard"
    },
    {
      title: "Video Library",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/video-library"
    },
    {
      title: "Ebook Library",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/library"
    },
    {
      title: "Planner",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/planner"
    }
    // {
    //   title: "Errors",
    //   htmlBefore: '<i class="material-icons">vertical_split</i>',
    //   to: "/errors",
    // },
    // {
    //   title: "Tasks",
    //   htmlBefore: '<i class="material-icons">vertical_split</i>',
    //   to: "/tasks",
    // }
  ];
}
