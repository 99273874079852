import React, { Component } from "react";
import { Row, Col, TimePicker, Select,InputNumber, Input } from "antd";
import Button  from "../../common/Button";
import TextArea from "../../common/TextArea";
import moment from 'moment';
import { MiddleBlockSection, Content, ContentWrapper } from "./styles";
import {
    chunkUpload
  } from "../../services/api";
  import Countdown from 'react-countdown';

  // Random component
  const Completionist = () => <span>Time Up!</span>;
  
  // Renderer callback with condition

const { Option } = Select;



class AttendExam extends Component {
constructor(props){
    super(props)
   
    this.state = {stage: 'init',quiz: this.props.quiz ,index: 0, time: 'left', Answers:[], desc: '', bg:[],runningTime: null}
    this.renderer = this.renderer.bind(this);
}
componentDidMount(){
  console.log(this.props.quiz);
    this.setState({...this.state,quiz: this.props.quiz, runningTime: this.props.quiz.Time});
}
complete(){
    console.log(this.state.quiz)
}
renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return `${hours}${minutes}${seconds}` == `${0}${0}${0}`?'':<Completionist />;
  } else {
    // Render a countdown
    return <span>{hours}:{minutes}:{seconds}</span>;
  }
};
render(){
    return (
      this.state.stage!= 'complete' ?  
      this.state.time != 'up' ?  
      this.state.stage == 'init'?    <MiddleBlockSection>
          
          <div style={{width: '100%'}} justify="center" align="middle">
          <Col lg={24} md={24} sm={24} xs={24}>{this.state.quiz.Name}</Col>
          <Col lg={24} md={24} sm={24} xs={24}>{this.state.quiz.Synopsis}</Col>
          <Col lg={24} md={24} sm={24} xs={24}>{this.state.quiz.Time}</Col>
          <Col lg={24} md={24} sm={24} xs={24}><Button onClick={()=>{this.setState({...this.state, stage:'started'})}}>Start</Button></Col>

          </div>
      
      </MiddleBlockSection>:  <MiddleBlockSection>
      <div style={{width: '100%'}} justify="center" align="middle">
      <Countdown
    date={
      Date.now() +
      moment(this.state.runningTime, "HH:mm:ss").valueOf() -
      moment().startOf("day").valueOf()
    }
    renderer={this.renderer}
    onComplete={(()=>{this.setState({...this.state, time: 'up'})})}
  />
        <Col lg={24} md={24} sm={24} xs={24}>{this.state.quiz.Questions[this.state.index].Question}</Col>
       {this.state.quiz.Questions[this.state.index].Type != 'description' ? 
           this.state.quiz.Questions[this.state.index].Type == 'text' ? <div>
               {this.state.quiz.Questions[this.state.index].Options.map((x)=>(
                   
                   <div style={{display: 'flex', flexDirection: 'row',color: this.state.bg.includes(x)? 'white':'#8F00FF',backgroundColor: this.state.bg.includes(x)? '#8F00FF':'white', flex: 1}} onClick={()=>{
                    let question = this.state.quiz.Questions[this.state.index];
                    let answers = question.answers? question.answers : [];
                   
                    if(!answers.includes(x)){
                    answers.push(x);
                    } else {
                      answers = answers.filter(function(item) {
                        return item !== x
                    })
                    }
                    
                    
                    question.answers = answers;
                    
                    let quiz = this.state.quiz;
                    quiz.Questions[this.state.index] = question;

                    let bg = this.state.bg;
                   
                    if(!bg.includes(x)){
                      bg.push(x);
                      } else {
                        bg = bg.filter(function(item) {
                          return item !== x
                      })
                      }
                     
                   
                    this.setState({...this.state, quiz: quiz, bg: bg});
                   
                   }}>{x}</div>
               ))}
           </div>:<div>
               {this.state.quiz.Questions[this.state.index].Options.map((x)=>(
                    <Col lg={24} md={24} sm={24} xs={24}><div style={{ padding: 10, backgroundColor: this.state.bg.includes(x)? '#8F00FF':'white'}}><img onClick={()=>{
                        
                        let question = this.state.quiz.Questions[this.state.index];
                        question.Answer = x;
                        let answers = question.answers? question.answers : [];
                       
                        if(!answers.includes(x)){
                          answers.push(x);
                          } else {
                            answers = answers.filter(function(item) {
                              return item !== x
                          })
                          }
                       
                       question.answers = answers;
                    
                       let bg = this.state.bg;
                     
                       if(!bg.includes(x)){
                        bg.push(x);
                        } else {
                          bg = bg.filter(function(item) {
                            return item !== x
                        })
                        }
                     

                       let quiz = this.state.quiz;
                       quiz.Questions[this.state.index] = question;
                       this.setState({...this.state, quiz: quiz, bg:bg});
                      
                       }} src={x}/></div></Col>
               ))}
           </div>
        : <Col lg={24} md={24} sm={24} xs={24}><TextArea onChange={(e)=>{this.setState({...this.state, desc: e.target.value})
    
    }} rows={4} /></Col>
       }
<Col lg={24} md={24} sm={24} xs={24}><Button onClick={()=>{
     let question = this.state.quiz.Questions[this.state.index];
    
     let answers = this.state.Answers;
    if(this.state.quiz.Questions[this.state.index].Type == 'description'){
         
        let question = this.state.quiz.Questions[this.state.index];
        let answers = question.answers? question.answers : [];
       answers.push(this.state.desc);
       question.answers = answers;
    
       let quiz = this.state.quiz;
       quiz.Questions[this.state.index] = question;
       this.setState({...this.state, quiz: quiz, desc:''});

                     }
                        if(this.state.quiz.Questions.length <= this.state.index+1){
                        this.setState({...this.state, stage: 'complete'});
                        this.complete();
                        } else {
                        this.setState({...this.state, index: this.state.index+1});
                        }
                       }}>Next</Button></Col>
      </div>
      </MiddleBlockSection> : 'Time Up' : this.state.quiz.Questions.map((x)=>(
        <div>
<div>{x.Question}</div>
{x.answers.map((y)=>(<div>
  {x.Type == "image" ? <img src={y}></img> : <div>{y}</div>}
  </div>))}
</div>

      ))
    );
}
}

export default AttendExam;