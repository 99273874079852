import React, { Component } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { getCourseDetails, deleteCourse } from "../services/api";
import { Store } from "../flux";
import PageTitle from "../components/common/PageTitle";
import DarkTable from "./DarkTable";
import EditUser from "./EditUser";
import EditCourse from "./EditCourse";
import AddCourse from "./AddCourse";
import Load from "../Load";
import Joyride from 'react-joyride';

const steps =  [
  {
    content: (
      <div>
        You can interact with your own components through the spotlight.
        <br />
        Click the menu above!
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: false,
    hideCloseButton: true,
    // hideFooter: true,
    placement: 'bottom',
    spotlightClicks: false,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.step-logo',
    title: 'Menu',
  },
  {
    content: (
      <div>
        You can interact with your own components through the spotlight.
        <br />
        Click the menu above!
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: false,
    hideCloseButton: true,
    // hideFooter: true,
    placement: 'right',
    spotlightClicks: false,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.step-home',
    title: 'Menu',
  },
  {
    content: (
      <div>
        You can interact with your own components through the spotlight.
        <br />
        Click the menu above!
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: false,
    hideCloseButton: true,
    // hideFooter: true,
    placement: 'right',
    spotlightClicks: false,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.step-tasks',
    title: 'Menu',
  },
  {
    content: (
      <div>
        You can interact with your own components through the spotlight.
        <br />
        Click the menu above!
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: false,
    hideCloseButton: true,
    // hideFooter: true,
    placement: 'right',
    spotlightClicks: false,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.step-video-library',
    title: 'Menu',
  },
  {
    content: (
      <div>
        You can interact with your own components through the spotlight.
        <br />
        Click the menu above!
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: false,
    hideCloseButton: true,
    // hideFooter: true,
    placement: 'right',
    spotlightClicks: false,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.step-library',
    title: 'Menu',
  },
  {
    content: (
      <div>
        You can interact with your own components through the spotlight.
        <br />
        Click the menu above!
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: false,
    hideCloseButton: true,
    // hideFooter: true,
    placement: 'right',
    spotlightClicks: false,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.step-planner',
    title: 'Menu',
  }
];

class ManageCourses extends Component {
  tableData = [];
  constructor(props) {
    super(props);
    this.state = {
      user: Store.getCurrentUser(),
      loading:true,
      selectedUser: null,
      add: false,
      edit: false,
      heads: [],
      rows: [],
      originalCopy: []
    };

    this.manageClick = this.manageClick.bind(this);
    this.goBack = this.goBack.bind(this);
    this.addCourse = this.addCourse.bind(this);
  }
  componentDidMount() {
    getCourseDetails(this.state.user.Institution, this.state.user)
      .then(data => {
        let copy = [];
        data.forEach(x => {
          var element = { id: "", Name: "", ShortCode: "", Status: "" };
          element.id = x.id;
          element.Name = x.Name;
          element.ShortCode = x.ShortCode;
          element.Status = x.Status;
          copy.push(element);
        });

        this.setState({
          ...this.state,
          heads: Object.keys(copy[0]),
          rows: copy,
          loading:false,
          originalCopy: data
        });
      })
      .catch(err =>{
        this.setState({...this.state,loading:false});
      });
  }
  goBack() {
    this.setState({...this.state,loading:true});
    getCourseDetails(this.state.user.Institution, this.state.user)
      .then(data => {
        let copy = [];
        data.forEach(x => {
          var element = { id: "", Name: "", ShortCode: "", Status: "" };
          element.id = x.id;
          element.Name = x.Name;
          element.ShortCode = x.ShortCode;
          element.Status = x.Status;
          copy.push(element);
        });

        this.setState({
          ...this.state,
          heads: Object.keys(copy[0]),
          rows: copy,
          originalCopy: data,
          loading:false,
          add: false,
          edit: false
        });
      })
      .catch(err => { this.setState({...this.state,loading:false,heads:[],rows: [], originalCopy: []});});
  }
  manageClick(name, id) {
    var result = this.state.originalCopy.filter(obj => {
      return obj.id === id;
    });
    if (name == "Edit") {
      this.setState({ ...this.state, edit: true, selectedUser: result[0] });
    } else {
      let data = {
        ShortCode: result[0].ShortCode,
        Institution: result[0].School
      };
      deleteCourse(data, this.state.user).then(data => {
        this.goBack();
      }).catch(()=>{});
    }
  }
  addCourse() {
    this.setState({ ...this.state, add: true });
  }
  render() {
    return this.state.loading ? (
      <Container fluid className="main-content-container px-4">
        <Load />
      </Container>
    ) : (this.state.add ? (
      <AddCourse
        type={`STUDENT`}
        nav={this.goBack}
        user={this.state.selectedUser}
      ></AddCourse>
    ) : this.state.edit ? (
      <EditCourse
        type={`Student`}
        nav={this.goBack}
        course={this.state.selectedUser}
      ></EditCourse>
    ) : (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Manage Courses"
            subtitle=""
            className="text-sm-left"
          />
        </Row>
        <DarkTable
          add={this.addCourse}
          manageClick={this.manageClick}
          action={[{ name: "Edit" }, { name: "Delete" }]}
          title={`Courses`}
          head={this.state.heads}
          rows={this.state.rows}
        ></DarkTable>
      </Container>
    ));
  }
}

export default ManageCourses;
