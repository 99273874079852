import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Store } from "../flux";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  let isAuthenticated = Store.getCurrentUser();

  return (
    // <Route
    //   {...restOfProps}
    //   render={(props) =>
    //     isAuthenticated!=null ? <Component {...props} /> : <Redirect to="/login" />
    //   }
    // />
     <Route
      {...restOfProps}
      render={(props) => <Component {...props} />
      }
    />
  );
}

export default ProtectedRoute;