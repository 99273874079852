import React, { Component } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { getStudentDetails, deleteUser } from "../services/api";
import { Store } from "../flux";
import PageTitle from "../components/common/PageTitle";
import DarkTable from "./DarkTable";
import EditUser from "./EditUser";
import AddUser from "./AddUser";
import Load from "../Load";

class ManageStudents extends Component {
  tableData = [];
  constructor(props) {
    super(props);
    this.state = {
      user: Store.getCurrentUser(),
      selectedUser: null,
      loading:true,
      add: false,
      edit: false,
      heads: [],
      rows: [],
      originalCopy: []
    };

    this.manageClick = this.manageClick.bind(this);
    this.goBack = this.goBack.bind(this);
    this.addStudent = this.addStudent.bind(this);
  }
  componentDidMount() {
    getStudentDetails(this.state.user.Institution, this.state.user)
      .then(data => {
        let copy = [];
        data.forEach(x => {
          var element = { id: "", FirstName: "", LastName: "", Email: "" };
          element.id = x.id;
          element.FirstName = x.FirstName;
          element.LastName = x.LastName;
          element.Email = x.Email;
          copy.push(element);
        });

        this.setState({
          ...this.state,
          heads: Object.keys(copy[0]),
          rows: copy,
          originalCopy: data,
          loading: false
        });
      })
      .catch(err => {this.setState({...this.state,loading:false})});
  }
  goBack() {
    this.setState({...this.state,loading:true});
    getStudentDetails(this.state.user.Institution, this.state.user)
      .then(data => {
        let copy = [];
        try {
          data.forEach(x => {
            var element = { id: "", FirstName: "", LastName: "", Email: "" };
            element.id = x.id;
            element.FirstName = x.FirstName;
            element.LastName = x.LastName;
            element.Email = x.Email;
            copy.push(element);
          });

          this.setState({
            ...this.state,
            heads: Object.keys(copy[0]),
            rows: copy,
            originalCopy: data,
            loading: false,
            add: false,
            edit: false
          });
        } catch {
          this.setState({
            ...this.state,
            selectedUser: null,
            loading:false,
            add: false,
            edit: false,
            heads: [],
            rows: [],
            originalCopy: []
          });
        }
      })
      .catch(err => {this.setState({...this.state,loading:false})});
  }
  addStudent() {
    this.setState({ ...this.state, add: true });
  }
  manageClick(name, id) {
    if (name == "Edit") {
      var result = this.state.originalCopy.filter(obj => {
        return obj.id === id;
      });
      
      this.setState({ ...this.state, edit: true, selectedUser: result[0] });
    } else {
      
      deleteUser(id, this.state.user).then(data => {
        this.goBack();
      }).catch(()=>{this.setState({...this.state,loading:false});});
    }
  }
  render() {
    return this.state.loading ? (
      <Container fluid className="main-content-container px-4">
        <Load />
      </Container>
    ) : (this.state.add ? (
      <AddUser
        type={`STUDENT`}
        nav={this.goBack}
        user={this.state.selectedUser}
      ></AddUser>
    ) : this.state.edit ? (
      <EditUser
        type={`Student`}
        nav={this.goBack}
        user={this.state.selectedUser}
      ></EditUser>
    ) : (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Manage Students"
            subtitle=""
            className="text-sm-left"
          />
        </Row>
        <DarkTable
          add={this.addStudent}
          manageClick={this.manageClick}
          action={[{ name: "Edit" }, { name: "Delete" }]}
          title={`Students`}
          head={this.state.heads}
          rows={this.state.rows}
        ></DarkTable>
      </Container>
    ));
  }
}

export default ManageStudents;
