import React, { Component } from "react";
import { lazy } from "react";
import IntroContent from "../content/IntroContent.json";
import MiddleBlockContent from "../content/MiddleBlockContent.json";
import AboutContent from "../content/DetailedMission.json";
import MissionContent from "../content/MissionContent.json";
import ProductContent from "../content/ProductContent.json";
import ContactContent from "../content/ContactContent.json";

import Footer from "../components/Footer";
import Header from "../components/Header";

import Contact from "../components/ContactForm";
import MiddleBlock from "../components/MiddleBlock";
import Container from "../common/Container";
import ScrollToTop from "../common/ScrollToTop";
import ContentBlock from "../components/ContentBlock";
import Pricing from "../components/pricing";
import "antd/dist/antd.css";
import {Styles} from "../styles/lstyles";

const Cancellation = () => {
  return (
    <div>
      <Styles/>
  <Header />
    <Container>
      <ScrollToTop />
    
      <MiddleBlock
        title={'Cancellation and Refunds'}
        content={'Customers can cancel an active subscription at any time and if at the time of cancellation you are less than 7 days from the start of the plan your plan will be cancelled immediately and your money will refunded to your account within 48 hours.'}
      />
     
     </Container>
  <Footer />
  </div>
  );
};

export default Cancellation;
