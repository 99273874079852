import React, { Component } from "react";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";

class PdfView extends Component {
  constructor(props) {
    super(props);
    this.state = { pageNumber: 1, numPages: null };
    this.changePage = this.changePage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  }

  componentDidMount() {}
  changePage(offset) {
    this.setState({
      ...this.state,
      pageNumber: this.state.pageNumber + offset
    });
  }
  onDocumentLoadSuccess({ numPages }) {
    this.setState({ ...this.state, numPages: numPages });
  }
  previousPage() {
    this.changePage(-1);
  }

  nextPage() {
    this.changePage(1);
  }
  render() {
    return (
      <center>
        <Document
          file={this.props.url}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          <Page pageNumber={this.state.pageNumber} />
        </Document>
        <div>
          <p>
            Page {this.state.pageNumber || (this.state.numPages ? 1 : "--")} of{" "}
            {this.state.numPages || "--"}
          </p>
          <button
            type="button"
            disabled={this.state.pageNumber <= 1}
            onClick={this.previousPage}
          >
            Previous
          </button>
          <button
            type="button"
            disabled={this.state.pageNumber >= this.state.numPages}
            onClick={this.nextPage}
          >
            Next
          </button>
        </div>
      </center>
    );
  }
}

export default PdfView;
