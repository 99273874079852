import React, { Component } from "react";
import { Row, Col, Divider } from 'antd';
import { withTranslation } from "react-i18next";
import { Slide } from "react-awesome-reveal";
import Button from "../../common/Button";
import { PricingTitle, Features } from "./styles";
import { MiddleBlockSection, Content, ContentWrapper } from "./styles";
import ReactTooltip from 'react-tooltip';
import PricingDetail from "../PricingDetail";


const style = { background: '#FFFF99', color: '#2F4F4F'};

export default class Pricing extends Component {

  constructor(props){
    super(props)
  }
render(){
  return(<MiddleBlockSection>
    <Row justify="center" align="middle">
    <ContentWrapper>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <h6>Pricing</h6>
                  <Content>Apart from the plans mentioned below we also offer tailor made packages depending on your needs. Please contact us through the Whatsapp chat bot or by mail through info@lesson21.com</Content>
                  </Col>
                  </ContentWrapper>
    <PricingDetail country = {this.props.country}/>
    </Row>
    {/* <ReactTooltip />
          <Slide direction="up">
            <Row justify="center" align="middle">
              <ContentWrapper>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <h6>Pricing</h6>
                  <Content>Clear and affordable pricing because we have nothing to hide, we believe in values and part of that is trusting our customers with clear pricing.</Content>
                  </Col>
                  </ContentWrapper>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" xs={24} sm={6} >
          <PricingTitle>Free</PricingTitle>
          <div style={style}>
            <Features>Course Management</Features>
            <Features>Upload Videos</Features>
            <Features>Manage Users</Features>
            <Features>Conduct Tests</Features>
            <Features data-tip="Third party video calling integration like Gmeet, Zoom etc.">
            Live Class *
              </Features>
            <Features>Share Files</Features>
            <Features>Upto 50 Users</Features>
            <Features>2GB Storage</Features>
            <Features>Mobile Support</Features>
            </div>
          </Col>
          <Col className="gutter-row" xs={24} sm={6} >
          {this.props.country == 'IN' ? <PricingTitle>&#8377;1000</PricingTitle>
        :   <PricingTitle>$19</PricingTitle>
        }
            <div style={style}>
            <Features>Previous Plan +</Features>
            <Features>Branded Website</Features>
            <Features>Kanban Board</Features>
            <Features>Conduct Exams</Features>
            <Features data-tip="Third party video calling integration like Gmeet, Zoom etc.">
            Live Class *
              </Features>
            <Features>Curated Content</Features>
            <Features>Upto 100 Users</Features>
            <Features>100GB Storage</Features>
            <Features>Branded App</Features>
            </div>
          </Col>
          <Col className="gutter-row" xs={24} sm={6} >
          {this.props.country == 'IN' ? <PricingTitle>&#8377;10,000</PricingTitle> :
 <PricingTitle>$130</PricingTitle>
 }
          <div style={style}>
          <Features>Previous Plan +</Features>
            <Features>Premium support</Features>
            <Features>Custom Integrations</Features>
            <Features>Upto 1000 Users</Features>
            <Features data-tip="Third party video calling integration like Gmeet, Zoom etc.">Live Class *</Features>
            <Features data-tip="Users and Courses like adding new users or courses will be handled by the Purple LMS team. The user could communicate preferences via Whatsapp or Call">Remote Management</Features>
            <Features>1TB Storage</Features>
            <Features>Branded App</Features>
            </div>
          </Col>
          <Col className="gutter-row" xs={24} sm={6} >
          <PricingTitle>Custom</PricingTitle>
          <div style={style}>
            <Features>Previous Plan +</Features>
            <Features>Dedicated support</Features>
            <Features>Custom Code</Features>
            <Features>Unlimited Users</Features>
            <Features data-tip="In house video calling solution">Live Class *</Features>
            <Features>Dedicated Hosting</Features>
            <Features>Marketing Support</Features>
            <Features>Unlimited Storage</Features>
            <Features>Custom Design</Features>
            </div>
          </Col>
        </Row>
    
        <Col xs={24} sm={24} style={{marginTop: '1vw'}}>
                  { (
                    <Button onClick = {()=>{window.location.href = '/signup'}} name="submit">
                      Signup Now
                    </Button>
                  )}
                  </Col>
                
             
            </Row>
          </Slide> */}
        </MiddleBlockSection>)
}
}
