import React, { Component } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { Container, Row, Col } from "shards-react";
import Board from "react-trello";
import PageTitle from "./../components/common/PageTitle";
import {  getPlan } from "../services/api";
import { Store } from "../flux";
import { getTasks, addTask } from "../services/api";
import Load from "../Load";
import { Dispatcher, Constants } from "../flux";

const handleDragStart = (cardId, laneId) => {

};

const handleDragEnd = (cardId, sourceLaneId, targetLaneId) => {

};

class Tasks extends Component {
  constructor(props) {
    super(props);
    this.state = { User: Store.getCurrentUser(),plan:Store.getCurrentPlan(), loading: true, boardData: { lanes: [] } };
  }

  componentDidMount(){
    getPlan(this.state.User.Institution,this.state.User).then((data)=>{
      this.setState({...this.state,plan: data});
      Dispatcher.dispatch({
        actionType: Constants.PLAN,
        payload: data
      });
     
    }).catch(()=>{});
  }
  setEventBus = eventBus => {
    this.setState({ eventBus });
  };

  componentWillMount() {
    this.getBoard();
  }

  getBoard() {
    let result = {};
    getTasks(this.state.User.id, this.state.User)
      .then(data => {
        let count = 0;
        let board = JSON.parse(data.Content);
       board.lanes.forEach((x)=>{
        x.label = x.cards.length;
        count+= x.cards.length;
       });
       board.lanes.forEach((x)=>{
        x.label = `${x.cards.length}/${count}`;
       });

        this.setState({ boardData: board,loading: false });
      })
      .catch(err => {this.setState({...this.state,loading:false})});
  }


  shouldReceiveNewData = nextData => {
    let count = 0;
    let board = nextData;
   board.lanes.forEach((x)=>{
    x.label = x.cards.length;
    count+= x.cards.length;
   });
   board.lanes.forEach((x)=>{
    x.label = `${x.cards.length}/${count}`;
   });
   this.setState({ ...this.state, boardData: board });
    try{
    addTask(this.state.User.id, JSON.stringify(nextData), this.state.User)
      .then(data => {
       
        this.setState({ ...this.state,loading:false });
      })
      .catch(err => {this.setState({...this.state,loading:false})});
    } catch {
      this.setState({...this.state,loading:false})
    }
  }

  handleCardAdd = (card, laneId) => {
 
  };

  render() {
    return this.state.loading ? (
      <Container fluid className="main-content-container px-4">
        <Load />
      </Container>
    ) :(
      <Container fluid className={`main-content-container px-4 ${this.state.plan.Tasks == '1'?'':'grey'}`}>
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Tasks"
            subtitle="Personal"
            className="text-sm-left mb-3"
          />
        </Row>

        {/* Small Stats Blocks */}
        <Row>
          <Col className="col-lg mb-4">
            <ScrollContainer className="scroll-container">
              <Board
                editable
                onCardAdd={this.handleCardAdd}
                data={this.state.boardData}
                draggable
                onDataChange={this.shouldReceiveNewData}
                eventBusHandle={this.setEventBus}
                handleDragStart={handleDragStart}
                handleDragEnd={handleDragEnd}
              />
            </ScrollContainer>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Tasks;
