  
import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import { v4 as uuidv4 } from "uuid";
import "./notice.css";
import { Store } from "../../flux";
import {
  getNotice, postNotice
} from "../../services/api";

var randomColor = require("randomcolor");


export default class NoticeBoard extends React.Component {
  constructor(props){
    super(props)
    this.state={item:"", items:[],currentUser: Store.getCurrentUser()}
  }
  
componentDidMount(){
  getNotice(this.state.currentUser).then((res)=>{
   this.setState({...this.state, items: res});
  }).catch(()=>{})
}
postBoard(data){
  postNotice(data, this.state.currentUser).then(()=>{}).catch(()=>{});
}
    newitem = () => {
    if (this.state.item.trim() !== "") {
      const newitem = {
        id: uuidv4(),
        item: this.state.item,
        // color: randomColor({
        //   luminosity: "light",
        // }),
        color: 'rgb(255 250 123)',
        defaultPos: { x: 100, y: 0 },
      };
     this.setState({...this.state, items: [...this.state.items, newitem], item:""});
     this.postBoard([...this.state.items, newitem]);
    } else {
      this.setState({...this.state, item:""});
    }
  };

  keyPress = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.newitem();
    }
  };

  // componentDidUpdate(){
  //   localStorage.setItem("items", JSON.stringify(this.state.items));
  // }
  

  updatePos = (data, index) => {
   
    let newArr = [...this.state.items];
    newArr[index].defaultPos = { x: data.x, y: data.y };
    this.setState({...this.state, items: [...newArr]});
    this.postBoard(this.state.items);
  };

  deleteNote = (id) => {
    let stack = this.state.items.filter((item) => item.id != id);
    this.setState({...this.state, items: stack});
    this.postBoard(stack);
  };
render(){
  return (
    <div className="Notice">
      <div id="new-item">
        <input
          value={this.state.item}
          onChange={(e) => this.setState({...this.state, item: e.target.value})}
          placeholder="New Notice"
          onKeyPress={(e) => this.keyPress(e)}
        />
        <button onClick={this.newitem}>ANNOUNCE</button>
      </div>
      {this.state.items.map((item, index) => {
        return (
          <Draggable
            key={item.id}
            defaultPosition={item.defaultPos}
            onStop={(e, data) => {
              this.updatePos(data, index);
            }}
          >
            <div style={{ backgroundColor: item.color }} className="box">
              {`${item.item}`}
              <button id="delete" onClick={(e) => this.deleteNote(item.id)}>
                X
              </button>
            </div>
          </Draggable>
        );
      })}
    </div>
  );
    }
}