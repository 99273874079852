import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
import { Store } from "../flux";
import PageTitle from "./../components/common/PageTitle";
import youtubeThumbnail from "youtube-thumbnail";
import Gallery from "react-grid-gallery";
import ModalVideo from "react-modal-video";
import { Dispatcher, Constants } from "../flux";
import { getPlan } from "../services/api";
import {isMobile} from 'react-device-detect';

class VideoLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, videoId: null,plan: Store.getCurrentPlan(),currentUser: Store.getCurrentUser(), };
  }
  componentDidMount(){
    getPlan(this.state.currentUser.Institution,this.state.currentUser).then((data)=>{
      this.setState({...this.state,plan: data});
      Dispatcher.dispatch({
        actionType: Constants.PLAN,
        payload: data
      });
      
    }).catch(()=>{});
  }
  setOpen(val, id) {
    this.setState({
      isOpen: val,
      videoId: id != null ? this.props.smallStats[id].videoId : id
    });
   
  }
  render() {
    return (
      <Container fluid className={`main-content-container px-4 ${this.state.plan.VideoLibrary == '1' ? '': 'grey'}`}>
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Video Library"
            subtitle="Lesson 21"
            className="text-sm-left mb-3"
          />
        </Row>

        {/* Small Stats Blocks */}
        <Row>
          <Col className="col-lg mb-4">
            <Gallery
              onClickThumbnail={index => {
                this.setOpen(true, index);
              }}
              images={this.props.smallStats}
            />
            <React.Fragment>
              <ModalVideo
                channel="youtube"
                autoplay
                isOpen={this.state.isOpen}
                videoId={this.state.videoId}
                onClose={() => this.setOpen(false, null)}
              />
            </React.Fragment>
          </Col>
        </Row>
      </Container>
    );
  }
}

VideoLibrary.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array
};

VideoLibrary.defaultProps = isMobile ? {
  smallStats: [{
    "src": "https://youtu.be/IJWEtCRWGvI",
    "thumbnail": "https://www.lesson21.com/youtube/default.jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "IJWEtCRWGvI",
    "caption": "Force and Pressure"
  }, {
    "src": "https://youtu.be/W55qMEF1a_E",
    "thumbnail": "https://www.lesson21.com/youtube/default(1).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "W55qMEF1a_E",
    "caption": "Contact & Non-Contact force"
  }, {
    "src": "https://youtu.be/n2gQs1mcZHA",
    "thumbnail": "https://www.lesson21.com/youtube/default(2).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "n2gQs1mcZHA",
    "caption": "What is Friction?"
  }, {
    "src": "https://youtu.be/ybpFEB6Mt88",
    "thumbnail": "https://www.lesson21.com/youtube/default(3).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "ybpFEB6Mt88",
    "caption": "Factors affecting Friction"
  }, {
    "src": "https://youtu.be/R10tuvCdl8c",
    "thumbnail": "https://www.lesson21.com/youtube/default(4).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "R10tuvCdl8c",
    "caption": "Types of frictional forces"
  }, {
    "src": "https://youtu.be/zlLpKzPz84Q",
    "thumbnail": "https://www.lesson21.com/youtube/default(5).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "zlLpKzPz84Q",
    "caption": "Pressure"
  }, {
    "src": "https://youtu.be/KndNN28OcEI",
    "thumbnail": "https://www.lesson21.com/youtube/default(6).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "KndNN28OcEI",
    "caption": "Atmospheric Pressure"
  }, {
    "src": "https://youtu.be/OWETRP_eabg",
    "thumbnail": "https://www.lesson21.com/youtube/default(7).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "OWETRP_eabg",
    "caption": "Sound "
  }, {
    "src": "https://youtu.be/BFWLb_MKyRE",
    "thumbnail": "https://www.lesson21.com/youtube/default(8).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "BFWLb_MKyRE",
    "caption": "What produces Sound?"
  }, {
    "src": "https://youtu.be/WqnF-VPfGPw",
    "thumbnail": "https://www.lesson21.com/youtube/default(9).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "WqnF-VPfGPw",
    "caption": "Propagation of Sound"
  }, {
    "src": "https://youtu.be/EM_sN_YHNow",
    "thumbnail": "https://www.lesson21.com/youtube/default(10).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "EM_sN_YHNow",
    "caption": "Lightning"
  }, {
    "src": "https://youtu.be/OrobTDEYs2M",
    "thumbnail": "https://www.lesson21.com/youtube/default(11).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "OrobTDEYs2M",
    "caption": "What are the Laws of Reflection of Light?"
  }, {
    "src": "https://youtu.be/A8DgGmIykk8",
    "thumbnail": "https://www.lesson21.com/youtube/default(12).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "A8DgGmIykk8",
    "caption": "Specular and Diffusion Reflection"
  }, {
    "src": "https://youtu.be/21BwUNDOQno",
    "thumbnail": "https://www.lesson21.com/youtube/default(13).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "21BwUNDOQno",
    "caption": "Distance and displacement"
  }, {
    "src": "https://youtu.be/apewLkLAR-U",
    "thumbnail": "https://www.lesson21.com/youtube/default(14).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "apewLkLAR-U",
    "caption": "What is velocity?"
  }, {
    "src": "https://youtu.be/vxFYfumAAlY",
    "thumbnail": "https://www.lesson21.com/youtube/default(15).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "vxFYfumAAlY",
    "caption": "What is Acceleration"
  }, {
    "src": "https://youtu.be/VFfF3F-G9Uk",
    "thumbnail": "https://www.lesson21.com/youtube/default(16).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "VFfF3F-G9Uk",
    "caption": "Uniform Motion and Non-uniform Motion"
  }, {
    "src": "https://youtu.be/5oi5j11FkQg",
    "thumbnail": "https://www.lesson21.com/youtube/default(17).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "5oi5j11FkQg",
    "caption": "Newton's First Law of Motion"
  }, {
    "src": "https://youtu.be/8YhYqN9BwB4",
    "thumbnail": "https://www.lesson21.com/youtube/default(18).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "8YhYqN9BwB4",
    "caption": "Newton's Second Law of Motion"
  }, {
    "src": "https://youtu.be/TVAxASr0iUY",
    "thumbnail": "https://www.lesson21.com/youtube/default(19).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "TVAxASr0iUY",
    "caption": "Newton's Third Law of Motion"
  }, {
    "src": "https://youtu.be/qBjmO8w-QqU",
    "thumbnail": "https://www.lesson21.com/youtube/default(20).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "qBjmO8w-QqU",
    "caption": "Inertia "
  }, {
    "src": "https://youtu.be/EzNfvwPCFs0",
    "thumbnail": "https://www.lesson21.com/youtube/default(21).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "EzNfvwPCFs0",
    "caption": "What Is Momentum?"
  }, {
    "src": "https://youtu.be/Jk5E-CrE1zg",
    "thumbnail": "https://www.lesson21.com/youtube/default(22).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "Jk5E-CrE1zg",
    "caption": "Newtons Universal Law of Gravitation"
  }, {
    "src": "https://youtu.be/Sw2P1hpwpqU",
    "thumbnail": "https://www.lesson21.com/youtube/default(23).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "Sw2P1hpwpqU",
    "caption": "Motion and Gravity "
  }, {
    "src": "https://youtu.be/iWSpdgGAsuE",
    "thumbnail": "https://www.lesson21.com/youtube/default(24).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "iWSpdgGAsuE",
    "caption": "What is Kinetic & Potential Energy?"
  }, {
    "src": "https://youtu.be/skGmQC87Bvg",
    "thumbnail": "https://www.lesson21.com/youtube/default(25).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "skGmQC87Bvg",
    "caption": "Reflection of Light"
  }, {
    "src": "https://youtu.be/EwBK_cXUTZI",
    "thumbnail": "https://www.lesson21.com/youtube/default(26).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "EwBK_cXUTZI",
    "caption": "What are Real and Virtual Images? "
  }, {
    "src": "https://youtu.be/v5SuSB_93FM",
    "thumbnail": "https://www.lesson21.com/youtube/default(27).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "v5SuSB_93FM",
    "caption": "Refraction of Light"
  }, {
    "src": "https://youtu.be/X3BYFv-CaJM",
    "thumbnail": "https://www.lesson21.com/youtube/default(28).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "X3BYFv-CaJM",
    "caption": "Why does Refraction occur?"
  }, {
    "src": "https://youtu.be/KgqV975EtA0",
    "thumbnail": "https://www.lesson21.com/youtube/default(29).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "KgqV975EtA0",
    "caption": "Refraction of Light Experiment "
  }, {
    "src": "https://youtu.be/NfcgA1axPLo",
    "thumbnail": "https://www.lesson21.com/youtube/default(30).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "NfcgA1axPLo",
    "caption": "Resistance and Ohm's law"
  }, {
    "src": "https://youtu.be/w82aSjLuD_8",
    "thumbnail": "https://www.lesson21.com/youtube/default(31).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "w82aSjLuD_8",
    "caption": " electricity potential difference"
  }, {
    "src": "https://youtu.be/VSpB3HivkhY",
    "thumbnail": "https://www.lesson21.com/youtube/default(32).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "VSpB3HivkhY",
    "caption": "Power and watts"
  }, {
    "src": "https://youtu.be/R4ht2RcWVlI",
    "thumbnail": "https://www.lesson21.com/youtube/default(33).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "R4ht2RcWVlI",
    "caption": "Magnetic Force and Magnetic Field"
  }, {
    "src": "https://youtu.be/qvB1mmfo7MQ",
    "thumbnail": "https://www.lesson21.com/youtube/default(34).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "qvB1mmfo7MQ",
    "caption": "Fleming’s Left Hand Rule"
  }, {
    "src": "https://youtu.be/UMDb0tlwQAg",
    "thumbnail": "https://www.lesson21.com/youtube/default(35).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "UMDb0tlwQAg",
    "caption": "Fleming's right hand rule"
  }, {
    "src": "https://youtu.be/0wsdkyrtfaY",
    "thumbnail": "https://www.lesson21.com/youtube/default(36).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "0wsdkyrtfaY",
    "caption": "Variables, Units, the SI, Fundamental vs Derived Units, and Prefixes"
  }, {
    "src": "https://youtu.be/WJN_F3PYp58",
    "thumbnail": "https://www.lesson21.com/youtube/default(37).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "WJN_F3PYp58",
    "caption": "Equations of motion"
  }, {
    "src": "https://youtu.be/Pj8Zh0A-uLU",
    "thumbnail": "https://www.lesson21.com/youtube/default(38).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "Pj8Zh0A-uLU",
    "caption": "Scalar Quantity and Vector Quantity"
  }, {
    "src": "https://youtu.be/lmipUGrQjBM",
    "thumbnail": "https://www.lesson21.com/youtube/default(39).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "lmipUGrQjBM",
    "caption": "Understanding vectors"
  }, {
    "src": "https://youtu.be/i5PtaCJJFjw",
    "thumbnail": "https://www.lesson21.com/youtube/default(40).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "i5PtaCJJFjw",
    "caption": "Newtons First and Second Laws"
  }, {
    "src": "https://youtu.be/DpQ_ikFKru0",
    "thumbnail": "https://www.lesson21.com/youtube/default(41).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "DpQ_ikFKru0",
    "caption": "Newton’s Third Law"
  }, {
    "src": "https://youtu.be/y2FmgoOht7Y",
    "thumbnail": "https://www.lesson21.com/youtube/default(42).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "zlLpKzPz84Q",
    "caption": "Understanding Circular Motion"
  }, {
    "src": "https://youtu.be/g7u6pIfUVy4",
    "thumbnail": "https://www.lesson21.com/youtube/default(43).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "g7u6pIfUVy4",
    "caption": "Kinetic Energy and Potential Energy"
  }, {
    "src": "https://youtu.be/Bls5KnQOWkY",
    "thumbnail": "https://www.lesson21.com/youtube/default(44).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "Bls5KnQOWkY",
    "caption": "Understanding the Area Moment of Inertia"
  }, {
    "src": "https://youtu.be/6zXkYjmvLuI",
    "thumbnail": "https://www.lesson21.com/youtube/default(45).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "6zXkYjmvLuI",
    "caption": "Equilibrium of a Body"
  }, {
    "src": "https://youtu.be/c9shwPMpSq8",
    "thumbnail": "https://www.lesson21.com/youtube/default(46).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "c9shwPMpSq8",
    "caption": "The Universal Law of Gravitation - Part 1"
  }, {
    "src": "https://youtu.be/lbOXZ2tcTgc",
    "thumbnail": "https://www.lesson21.com/youtube/default(47).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "lbOXZ2tcTgc",
    "caption": "Universal Law of Gravitation - Part 2"
  }, {
    "src": "https://youtu.be/lGlAU4lcneI",
    "thumbnail": "https://www.lesson21.com/youtube/default(48).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "lGlAU4lcneI",
    "caption": "What is gravitational potential energy??"
  }, {
    "src": "https://youtu.be/XzhVK09YReA",
    "thumbnail": "https://www.lesson21.com/youtube/default(49).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "XzhVK09YReA",
    "caption": "Geostationary Satellite"
  }, {
    "src": "https://youtu.be/aQf6Q8t1FQE",
    "thumbnail": "https://www.lesson21.com/youtube/default(50).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "aQf6Q8t1FQE",
    "caption": "An Introduction to Stress and Strain"
  }, {
    "src": "https://youtu.be/DLE-ieOVFjI",
    "thumbnail": "https://www.lesson21.com/youtube/default(51).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "DLE-ieOVFjI",
    "caption": "Understanding Young's Modulus"
  }, {
    "src": "https://youtu.be/LaMixSlMDUE",
    "thumbnail": "https://www.lesson21.com/youtube/default(52).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "LaMixSlMDUE",
    "caption": "Pascal's law"
  }, {
    "src": "https://youtu.be/DW4rItB20h4",
    "thumbnail": "https://www.lesson21.com/youtube/default(53).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "DW4rItB20h4",
    "caption": "Bernoulli's Equation"
  }, {
    "src": "https://youtu.be/VvDJyhYSJv8",
    "thumbnail": "https://www.lesson21.com/youtube/default(54).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "VvDJyhYSJv8",
    "caption": "Viscosity"
  }, {
    "src": "https://youtu.be/jatprpYqaos",
    "thumbnail": "https://www.lesson21.com/youtube/default(55).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "jatprpYqaos",
    "caption": "Thermal expansion"
  }, {
    "src": "https://youtu.be/8hpSl2MeyA",
    "thumbnail": "https://www.lesson21.com/youtube/default(56).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "8hpSl2MeyA",
    "caption": "First Law of Thermodynamics"
  }, {
    "src": "https://youtu.be/WTtxlaeC9PY",
    "thumbnail": "https://www.lesson21.com/youtube/default(57).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "WTtxlaeC9PY",
    "caption": "Second Law of Thermodynamics"
  }, {
    "src": "https://youtu.be/PE_zpk-EznQ",
    "thumbnail": "https://www.lesson21.com/youtube/default(58).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "PE_zpk-EznQ",
    "caption": "Zeroth Law of Thermodynamics"
  }, {
    "src": "https://youtu.be/13i3xbuaCqI",
    "thumbnail": "https://www.lesson21.com/youtube/default(59).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "13i3xbuaCqI",
    "caption": "Avogadro's Number"
  }, {
    "src": "https://youtu.be/XjkUcJkGd3Y",
    "thumbnail": "https://www.lesson21.com/youtube/default(60).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "XjkUcJkGd3Y",
    "caption": "SHM (Simple Harmonic Motion)"
  }, {
    "src": "https://youtu.be/0Anh9HthWgQ",
    "thumbnail": "https://www.lesson21.com/youtube/default(61).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "0Anh9HthWgQ",
    "caption": "Transverse & Longitudinal Waves"
  }, {
    "src": "https://youtu.be/yOv4xxopQFQ",
    "thumbnail": "https://www.lesson21.com/youtube/default(62).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "yOv4xxopQFQ",
    "caption": "Electric Flux and Gauss’s Law"
  }, {
    "src": "https://youtu.be/PafSqL1riS4",
    "thumbnail": "https://www.lesson21.com/youtube/default(63).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "PafSqL1riS4",
    "caption": "What are Conductors and Insulators?"
  }, {
    "src": "https://youtu.be/X4EUwTwZ110",
    "thumbnail": "https://www.lesson21.com/youtube/default(64).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "X4EUwTwZ110",
    "caption": "Capacitors Explained"
  }, {
    "src": "https://youtu.be/HsLLq6Rm5tU",
    "thumbnail": "https://www.lesson21.com/youtube/default(65).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "HsLLq6Rm5tU",
    "caption": "Ohms Law Explained - The basics circuit theory"
  }, {
    "src": "https://youtu.be/ZqAM_wQ35ow",
    "thumbnail": "https://www.lesson21.com/youtube/default(66).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "ZqAM_wQ35ow",
    "caption": "Wheatstone bridge"
  }, {
    "src": "https://youtu.be/RwilgsQ9xaM",
    "thumbnail": "https://www.lesson21.com/youtube/default(67).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "RwilgsQ9xaM",
    "caption": "What Oersted Discovered with his Compass"
  }, {
    "src": "https://youtu.be/7PZx-7XFNX4",
    "thumbnail": "https://www.lesson21.com/youtube/default(68).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "7PZx-7XFNX4",
    "caption": "THE BIOT SAVART'S LAW"
  }, {
    "src": "https://youtu.be/Y18N-hi5P1o",
    "thumbnail": "https://www.lesson21.com/youtube/default(69).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "Y18N-hi5P1o",
    "caption": "Faraday's Law of Induction"
  }, {
    "src": "https://youtu.be/wsuBld3Bo00",
    "thumbnail": "https://www.lesson21.com/youtube/default(70).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "wsuBld3Bo00",
    "caption": "Lenz's Law and Conservation of Energy"
  }, {
    "src": "https://youtu.be/_7u6nh35dJ8",
    "thumbnail": "https://www.lesson21.com/youtube/default(71).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "_7u6nh35dJ8",
    "caption": "Alternating Current"
  }, {
    "src": "https://youtu.be/7v2gs8rdQzU",
    "thumbnail": "https://www.lesson21.com/youtube/default(72).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "7v2gs8rdQzU",
    "caption": "Electromagnetic Waves"
  }, {
    "src": "https://youtu.be/v5SuSB_93FM",
    "thumbnail": "https://www.lesson21.com/youtube/default(73).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "v5SuSB_93FM",
    "caption": "Refraction of Light"
  }, {
    "src": "https://youtu.be/he8jSyRWvxE",
    "thumbnail": "https://www.lesson21.com/youtube/default(74).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "he8jSyRWvxE",
    "caption": "Diffraction and Huygens's Principle"
  }, {
    "src": "https://youtu.be/0b0axfyJ4oo",
    "thumbnail": "https://www.lesson21.com/youtube/default(75).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "0b0axfyJ4oo",
    "caption": "The Photoelectric Effect"
  }, {
    "src": "https://youtu.be/u7xrDhpY-oA",
    "thumbnail": "https://www.lesson21.com/youtube/default(76).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "u7xrDhpY-oA",
    "caption": "Rutherford's atom and the Gold Foil Experiment"
  }, {
    "src": "https://youtu.be/y9kWdegtrs0",
    "thumbnail": "https://www.lesson21.com/youtube/default(77).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "y9kWdegtrs0",
    "caption": "Understanding Bohr's Atom"
  }, {
    "src": "https://youtu.be/xrk7Mt2fx6Y",
    "thumbnail": "https://www.lesson21.com/youtube/default(78).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "xrk7Mt2fx6Y",
    "caption": "Nuclear fission and nuclear fusion"
  }, {
    "src": "https://youtu.be/pw_tzpgytcE",
    "thumbnail": "https://www.lesson21.com/youtube/default(79).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "pw_tzpgytcE",
    "caption": "Energy band for conductors, semiconductors & insulator"
  }, {
    "src": "https://youtu.be/L_q6LRgKpTw",
    "thumbnail": "https://www.lesson21.com/youtube/default(80).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "L_q6LRgKpTw",
    "caption": "How do Solar cells work?"
  }, {
    "src": "https://youtu.be/JNi6WY7WKAI",
    "thumbnail": "https://www.lesson21.com/youtube/default(81).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "JNi6WY7WKAI",
    "caption": "How does a Diode work ?"
  }, {
    "src": "https://youtu.be/4BX2mEQf09c",
    "thumbnail": "https://www.lesson21.com/youtube/default(82).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "4BX2mEQf09c",
    "caption": "the LED & solar cell"
  }, {
    "src": "https://youtu.be/g-tE6MN-wrE",
    "thumbnail": "https://www.lesson21.com/youtube/default(83).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "g-tE6MN-wrE",
    "caption": "Chemical Bonding"
  }, {
    "src": "https://youtu.be/uKpr-9vmgsc",
    "thumbnail": "https://www.lesson21.com/youtube/default(84).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "uKpr-9vmgsc",
    "caption": "Hexagonal Closed Packed Structure"
  }, {
    "src": "https://youtu.be/Z0Ks3wjFnrk",
    "thumbnail": "https://www.lesson21.com/youtube/default(85).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "Z0Ks3wjFnrk",
    "caption": "Imperfections in Solids"
  }, {
    "src": "https://youtu.be/9h2f1Bjr0p4",
    "thumbnail": "https://www.lesson21.com/youtube/default(86).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "9h2f1Bjr0p4",
    "caption": "Solutions"
  }, {
    "src": "https://youtu.be/5onYR2emPb0",
    "thumbnail": "https://www.lesson21.com/youtube/default(87).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "5onYR2emPb0",
    "caption": "Raoult's law"
  }, {
    "src": "https://youtu.be/4i1MUWJoI0U",
    "thumbnail": "https://www.lesson21.com/youtube/default(88).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "4i1MUWJoI0U",
    "caption": "Thermodynamics"
  }, {
    "src": "https://youtu.be/CTtf5s2HFkA",
    "thumbnail": "https://www.lesson21.com/youtube/default(89).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "CTtf5s2HFkA",
    "caption": "Gold Bullion Vault"
  }, {
    "src": "https://youtu.be/HhT2E7wuAgg",
    "thumbnail": "https://www.lesson21.com/youtube/default(90).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "HhT2E7wuAgg",
    "caption": "Basics of Nomenclature"
  }, {
    "src": "https://youtu.be/M0uw4ZNpqcI",
    "thumbnail": "https://www.lesson21.com/youtube/default(91).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "M0uw4ZNpqcI",
    "caption": "Radioactivity"
  }, {
    "src": "https://youtu.be/MjCFw3EQNBU",
    "thumbnail": "https://www.lesson21.com/youtube/default(92).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "MjCFw3EQNBU",
    "caption": "Chernobyl Incident"
  }, {
    "src": "https://youtu.be/QLZMzsRB86E",
    "thumbnail": "https://www.lesson21.com/youtube/default(93).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "QLZMzsRB86E",
    "caption": "Plutonium Core of an Atom Bomb"
  }, {
    "src": "https://youtu.be/xMa1BQ8z9C0",
    "thumbnail": "https://www.lesson21.com/youtube/default(94).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "xMa1BQ8z9C0",
    "caption": "IR Spectroscopy and Mass Spectrometry"
  }, {
    "src": "https://youtu.be/H8WJ2KENlK0",
    "thumbnail": "https://www.lesson21.com/youtube/default(95).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "H8WJ2KENlK0",
    "caption": "Biomolecules"
  }, {
    "src": "https://youtu.be/qxlR7ZdgV7w",
    "thumbnail": "https://www.lesson21.com/youtube/default(96).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "qxlR7ZdgV7w",
    "caption": "Tyndall Effect"
  }, {
    "src": "https://youtu.be/jyvcVjrZnJA",
    "thumbnail": "https://www.lesson21.com/youtube/default(97).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "jyvcVjrZnJA",
    "caption": "Redox Reactilons"
  }, {
    "src": "https://youtu.be/shJAV59NS6k",
    "thumbnail": "https://www.lesson21.com/youtube/default(98).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "shJAV59NS6k",
    "caption": "EMF of a cell"
  }, {
    "src": "https://youtu.be/IV4IUsholjg",
    "thumbnail": "https://www.lesson21.com/youtube/default(99).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "IV4IUsholjg",
    "caption": "Electrochemistry"
  }, {
    "src": "https://youtu.be/gou_uogLKN8",
    "thumbnail": "https://www.lesson21.com/youtube/default(100).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "gou_uogLKN8",
    "caption": "Catalysts"
  }, {
    "src": "https://youtu.be/vD0kbdIS6kE",
    "thumbnail": "https://www.lesson21.com/youtube/default(101).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "vD0kbdIS6kE",
    "caption": "How does petroleum refining work ?"
  }, {
    "src": "https://youtu.be/P99C051arMo",
    "thumbnail": "https://www.lesson21.com/youtube/default(102).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "P99C051arMo",
    "caption": "Inside a nuclear reactor"
  }, {
    "src": "https://youtu.be/hbfiJHW-LP4",
    "thumbnail": "https://www.lesson21.com/youtube/default(103).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "hbfiJHW-LP4",
    "caption": "How is honey made ?"
  }, {
    "src": "https://youtu.be/wvTv8TqWC48",
    "thumbnail": "https://www.lesson21.com/youtube/default(104).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "wvTv8TqWC48",
    "caption": "Poteins"
  }, {
    "src": "https://youtu.be/wxzc_2c6GMg",
    "thumbnail": "https://www.lesson21.com/youtube/default(105).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "wxzc_2c6GMg",
    "caption": "Carbohydrates"
  }, {
    "src": "https://youtu.be/2tM1LFFxeKg",
    "thumbnail": "https://www.lesson21.com/youtube/default(106).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "2tM1LFFxeKg",
    "caption": "What makes muscle grow ?"
  }, {
    "src": "https://youtu.be/sX-uzr8xhWY",
    "thumbnail": "https://www.lesson21.com/youtube/default(107).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "sX-uzr8xhWY",
    "caption": "Reaction of metals with water"
  }, {
    "src": "https://youtu.be/8kOHaRqwMLw",
    "thumbnail": "https://www.lesson21.com/youtube/default(108).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "8kOHaRqwMLw",
    "caption": "Recreating Acid rain in a lab"
  }, {
    "src": "https://youtu.be/NokislH5Q1M",
    "thumbnail": "https://www.lesson21.com/youtube/default(109).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "NokislH5Q1M",
    "caption": "Cleaning the Taj Mahal"
  }, {
    "src": "https://youtu.be/pdwiwuLVzaE",
    "thumbnail": "https://www.lesson21.com/youtube/default(110).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "pdwiwuLVzaE",
    "caption": "CV Ramans Nobel Prize"
  }, {
    "src": "https://youtu.be/jb4CMnT2-ao",
    "thumbnail": "https://www.lesson21.com/youtube/default(111).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "jb4CMnT2-ao",
    "caption": "6 Chemical Reactions That Changed History"
  }, {
    "src": "https://youtu.be/1EdTw4I6L0U",
    "thumbnail": "https://www.lesson21.com/youtube/default(112).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "1EdTw4I6L0U",
    "caption": "Rutherford's model of an atom"
  }, {
    "src": "https://youtu.be/syi3pXJNe58",
    "thumbnail": "https://www.lesson21.com/youtube/default(113).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "syi3pXJNe58",
    "caption": "Daltons model of an atom"
  }, {
    "src": "https://youtu.be/S1LDJUu4nko",
    "thumbnail": "https://www.lesson21.com/youtube/default(114).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "S1LDJUu4nko",
    "caption": "Bohrs model of atom"
  }, {
    "src": "https://youtu.be/IOf-z0D1mHk",
    "thumbnail": "https://www.lesson21.com/youtube/default(115).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "IOf-z0D1mHk",
    "caption": "How the body absorbs medicines"
  }, {
    "src": "https://youtu.be/B_em0quwuSw",
    "thumbnail": "https://www.lesson21.com/youtube/default(116).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "B_em0quwuSw",
    "caption": "Why do metals rust ?"
  }, {
    "src": "https://youtu.be/fWXPgC86EXE",
    "thumbnail": "https://www.lesson21.com/youtube/default(117).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "fWXPgC86EXE",
    "caption": "How are soft drinks made ?"
  }, {
    "src": "https://youtu.be/gCrmFbgT37I",
    "thumbnail": "https://www.lesson21.com/youtube/default(118).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "gCrmFbgT37I",
    "caption": "How does alcohol make people drunk?"
  }, {
    "src": "https://youtu.be/ndG-1kGD8k0",
    "thumbnail": "https://www.lesson21.com/youtube/default(119).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "ndG-1kGD8k0",
    "caption": "Chemistry of fragrances"
  }, {
    "src": "https://youtu.be/gIicTlGaGQE",
    "thumbnail": "https://www.lesson21.com/youtube/default(120).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "gIicTlGaGQE",
    "caption": "Chemistry of film photography"
  }, {
    "src": "https://youtu.be/ckSoDW2-wrc",
    "thumbnail": "https://www.lesson21.com/youtube/default(121).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "ckSoDW2-wrc",
    "caption": "5 most dangerous chemicals"
  }, {
    "src": "https://youtu.be/t_-8peN67PY",
    "thumbnail": "https://www.lesson21.com/youtube/default(122).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "t_-8peN67PY",
    "caption": "The chemistry of Vaccines"
  }, {
    "src": "https://youtu.be/jJ5K78_TIEY",
    "thumbnail": "https://www.lesson21.com/youtube/default(123).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "jJ5K78_TIEY",
    "caption": "Flower structure"
  }, {
    "src": "https://youtu.be/aT-_ueLkQKw",
    "thumbnail": "https://www.lesson21.com/youtube/default(124).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "aT-_ueLkQKw",
    "caption": "pollination"
  }, {
    "src": "https://youtu.be/bUjVHUf4d1I",
    "thumbnail": "https://www.lesson21.com/youtube/default(125).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "bUjVHUf4d1I",
    "caption": "double fertilisation"
  }, {
    "src": "https://youtu.be/f2dvh0YNDwM",
    "thumbnail": "https://www.lesson21.com/youtube/default(126).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "f2dvh0YNDwM",
    "caption": "development of embryo"
  }, {
    "src": "https://youtu.be/PidOBjeY6MI",
    "thumbnail": "https://www.lesson21.com/youtube/default(127).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "PidOBjeY6MI",
    "caption": "development of seed and formation of fruit"
  }, {
    "src": "https://youtu.be/T8q3JpTWhrY",
    "thumbnail": "https://www.lesson21.com/youtube/default(128).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "T8q3JpTWhrY",
    "caption": "Male reproductive system"
  }, {
    "src": "https://youtu.be/5SOvWaW_OY",
    "thumbnail": "https://www.lesson21.com/youtube/default(129).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "5SOvWaW_OY",
    "caption": "female reproductive systems"
  }, {
    "src": "https://youtu.be/WGJsrGmWeKE",
    "thumbnail": "https://www.lesson21.com/youtube/default(130).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "WGJsrGmWeKE",
    "caption": "menstrual cycle"
  }, {
    "src": "https://youtu.be/tH1SJpg0DU8",
    "thumbnail": "https://www.lesson21.com/youtube/default(131).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "tH1SJpg0DU8",
    "caption": "embryo development"
  }, {
    "src": "https://youtu.be/bped-RVWsLk",
    "thumbnail": "https://www.lesson21.com/youtube/default(132).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "bped-RVWsLk",
    "caption": "placenta formation"
  }, {
    "src": "https://youtu.be/v8mRNJihA-k",
    "thumbnail": "https://www.lesson21.com/youtube/default(133).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "v8mRNJihA-k",
    "caption": "parturition"
  }, {
    "src": "https://youtu.be/ybSquGsyTko",
    "thumbnail": "https://www.lesson21.com/youtube/default(134).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "ybSquGsyTko",
    "caption": "Lactation"
  }, {
    "src": "https://youtu.be/YaD4P3S6wUA",
    "thumbnail": "https://www.lesson21.com/youtube/default(135).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "YaD4P3S6wUA",
    "caption": "Sexually Transmitted Diseases"
  }, {
    "src": "https://youtu.be/A0wI5K-Y234",
    "thumbnail": "https://www.lesson21.com/youtube/default(136).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "A0wI5K-Y234",
    "caption": "birth control"
  }, {
    "src": "https://youtu.be/004tbkwE8HA",
    "thumbnail": "https://www.lesson21.com/youtube/default(137).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "004tbkwE8HA",
    "caption": "assisted reproductive technologies"
  }, {
    "src": "https://youtu.be/ni5jyO0g1_w",
    "thumbnail": "https://www.lesson21.com/youtube/default(138).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "ni5jyO0g1_w",
    "caption": "Mendelian inheritance"
  }, {
    "src": "https://youtu.be/mcEV3m9SG9M",
    "thumbnail": "https://www.lesson21.com/youtube/default(139).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "mcEV3m9SG9M",
    "caption": "chromosomes and genes"
  }, {
    "src": "https://youtu.be/AzkXQBzZElE",
    "thumbnail": "https://www.lesson21.com/youtube/default(140).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "AzkXQBzZElE",
    "caption": "Sex determination"
  }, {
    "src": "https://youtu.be/k03JClhz9Fo",
    "thumbnail": "https://www.lesson21.com/youtube/default(141).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "k03JClhz9Fo",
    "caption": "Structure of DNA "
  }, {
    "src": "https://youtu.be/LDYkt1RN_YY",
    "thumbnail": "https://www.lesson21.com/youtube/default(142).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "LDYkt1RN_YY",
    "caption": "structure of RNA and types"
  }, {
    "src": "https://youtu.be/7onjVBsQwQ8",
    "thumbnail": "https://www.lesson21.com/youtube/default(143).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "7onjVBsQwQ8",
    "caption": "DNA fingerprinting"
  }, {
    "src": "https://youtu.be/Qqe4thU-os8",
    "thumbnail": "https://www.lesson21.com/youtube/default(144).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "Qqe4thU-os8",
    "caption": "DNA replication"
  }, {
    "src": "https://youtu.be/PzunOgYHeyg",
    "thumbnail": "https://www.lesson21.com/youtube/default(145).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "PzunOgYHeyg",
    "caption": "Basic concepts of immunology"
  }, {
    "src": "https://youtu.be/6RHLRFjnOzw",
    "thumbnail": "https://www.lesson21.com/youtube/default(146).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "6RHLRFjnOzw",
    "caption": "cancer"
  }, {
    "src": "https://youtu.be/EG3h71vwzYc",
    "thumbnail": "https://www.lesson21.com/youtube/default(147).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "EG3h71vwzYc",
    "caption": "HIV and AIDS"
  }, {
    "src": "https://youtu.be/eksagPy5tmQ",
    "thumbnail": "https://www.lesson21.com/youtube/default(148).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "eksagPy5tmQ",
    "caption": "Microbes in food processing"
  }, {
    "src": "https://youtu.be/jYaZyZ_Bmkc",
    "thumbnail": "https://www.lesson21.com/youtube/default(149).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "jYaZyZ_Bmkc",
    "caption": "antibiotics"
  }, {
    "src": "https://youtu.be/4fBQ2umTaMA",
    "thumbnail": "https://www.lesson21.com/youtube/default(150).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "4fBQ2umTaMA",
    "caption": "Genetic Engineering"
  }, {
    "src": "https://youtu.be/m29Y3qhCNyk",
    "thumbnail": "https://www.lesson21.com/youtube/default(151).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "m29Y3qhCNyk",
    "caption": "Application of biotechnology"
  }, {
    "src": "https://youtu.be/vribRyVQ6G8",
    "thumbnail": "https://www.lesson21.com/youtube/default(152).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "vribRyVQ6G8",
    "caption": "genetically modified organisms"
  }, {
    "src": "https://youtu.be/D9lulTRx8-o",
    "thumbnail": "https://www.lesson21.com/youtube/default(153).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "D9lulTRx8-o",
    "caption": "Habitat and niche"
  }, {
    "src": "https://youtu.be/b6Ua_zWDH6U",
    "thumbnail": "https://www.lesson21.com/youtube/default(154).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "b6Ua_zWDH6U",
    "caption": "Biodiversity"
  }, {
    "src": "https://youtu.be/uqEUzgVAF6g",
    "thumbnail": "https://www.lesson21.com/youtube/default(155).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "uqEUzgVAF6g",
    "caption": "ecological succession"
  }, {
    "src": "https://youtu.be/Kq6faK3XHuM",
    "thumbnail": "https://www.lesson21.com/youtube/default(156).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "Kq6faK3XHuM",
    "caption": "binomial nomenclature"
  }, {
    "src": "https://youtu.be/DVouQRAKxYo",
    "thumbnail": "https://www.lesson21.com/youtube/default(157).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "DVouQRAKxYo",
    "caption": "Five kingdom classification"
  }, {
    "src": "https://youtu.be/0TdQeTM0xec",
    "thumbnail": "https://www.lesson21.com/youtube/default(158).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "0TdQeTM0xec",
    "caption": "introduction to bacteria"
  }, {
    "src": "https://youtu.be/B1CFVuQVG2U",
    "thumbnail": "https://www.lesson21.com/youtube/default(159).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "B1CFVuQVG2U",
    "caption": "protozoa"
  }, {
    "src": "https://youtu.be/VVuYGkk_I8s",
    "thumbnail": "https://www.lesson21.com/youtube/default(160).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "VVuYGkk_I8s",
    "caption": "fungi"
  }, {
    "src": "https://youtu.be/OU4_7DKmVCk",
    "thumbnail": "https://www.lesson21.com/youtube/default(161).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "OU4_7DKmVCk",
    "caption": "Morphology of inflorescence and flower"
  }, {
    "src": "https://youtu.be/4OpBylwH9DU",
    "thumbnail": "https://www.lesson21.com/youtube/default(162).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "4OpBylwH9DU",
    "caption": "Cell theory"
  }, {
    "src": "https://youtu.be/Pxujitlv8wc",
    "thumbnail": "https://www.lesson21.com/youtube/default(163).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "Pxujitlv8wc",
    "caption": "prokaryotic and eukaryotic cells"
  }, {
    "src": "https://youtu.be/192M4oDLTdc",
    "thumbnail": "https://www.lesson21.com/youtube/default(164).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "192M4oDLTdc",
    "caption": "Plant cell and animal cell"
  }, {
    "src": "https://youtu.be/fV-sG3od1k0",
    "thumbnail": "https://www.lesson21.com/youtube/default(165).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "fV-sG3od1k0",
    "caption": "nucleus"
  }, {
    "src": "https://youtu.be/r6C54HHFTwE",
    "thumbnail": "https://www.lesson21.com/youtube/default(166).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "r6C54HHFTwE",
    "caption": "mitochondria"
  }, {
    "src": "https://youtu.be/YO244P1e9QM",
    "thumbnail": "https://www.lesson21.com/youtube/default(167).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "YO244P1e9QM",
    "caption": "biomolecules"
  }, {
    "src": "https://youtu.be/qgVFkRn8f10",
    "thumbnail": "https://www.lesson21.com/youtube/default(168).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "qgVFkRn8f10",
    "caption": "Enzymes"
  }, {
    "src": "https://youtu.be/_AtHhradflA",
    "thumbnail": "https://www.lesson21.com/youtube/default(169).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "_AtHhradflA",
    "caption": "Cell cycle"
  }, {
    "src": "https://youtu.be/f-ldPgEfAHI",
    "thumbnail": "https://www.lesson21.com/youtube/default(170).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "f-ldPgEfAHI",
    "caption": "mitosis"
  }, {
    "src": "https://youtu.be/VzDMG7ke69g",
    "thumbnail": "https://www.lesson21.com/youtube/default(171).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "VzDMG7ke69g",
    "caption": "meiosis"
  }, {
    "src": "https://youtu.be/KfvYQgT2M-k",
    "thumbnail": "https://www.lesson21.com/youtube/default(172).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "KfvYQgT2M-k",
    "caption": "Photosynthesis"
  }, {
    "src": "https://youtu.be/hDq1rhUkV-g",
    "thumbnail": "https://www.lesson21.com/youtube/default(173).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "hDq1rhUkV-g",
    "caption": "glycolysis"
  }, {
    "src": "https://youtu.be/YbdkbCU20_M",
    "thumbnail": "https://www.lesson21.com/youtube/default(174).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "YbdkbCU20_M",
    "caption": "fermentation"
  }, {
    "src": "https://youtu.be/HR9KHW-e0pY",
    "thumbnail": "https://www.lesson21.com/youtube/default(175).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "HR9KHW-e0pY",
    "caption": "introduction to plant Growth regulators"
  }, {
    "src": "https://youtu.be/a_D1UVP7eSY",
    "thumbnail": "https://www.lesson21.com/youtube/default(176).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "a_D1UVP7eSY",
    "caption": "Respiratory system in humans 1"
  }, {
    "src": "https://youtu.be/A8xGmKLm6RY",
    "thumbnail": "https://www.lesson21.com/youtube/default(177).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "A8xGmKLm6RY",
    "caption": "Respiratory system in humans 2"
  }, {
    "src": "https://youtu.be/j9Hdl9w-K0M",
    "thumbnail": "https://www.lesson21.com/youtube/default(178).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "j9Hdl9w-K0M",
    "caption": "coagulation of blood"
  }, {
    "src": "https://youtu.be/_qmNCJxpsr0",
    "thumbnail": "https://www.lesson21.com/youtube/default(179).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "_qmNCJxpsr0",
    "caption": "human circulatory system"
  }, {
    "src": "https://youtu.be/SwHjwO7BnsI",
    "thumbnail": "https://www.lesson21.com/youtube/default(180).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "SwHjwO7BnsI",
    "caption": "cardiac cycle"
  }, {
    "src": "https://youtu.be/0yXMGQaVVXg",
    "thumbnail": "https://www.lesson21.com/youtube/default(181).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "0yXMGQaVVXg",
    "caption": "Nervous system in humans"
  }, {
    "src": "https://youtu.be/YxSrahHzwgw",
    "thumbnail": "https://www.lesson21.com/youtube/default(182).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "YxSrahHzwgw",
    "caption": "human endocrine system"
  }, {
    "src": "https://youtu.be/-SPRPkLoKp8",
    "thumbnail": "https://www.lesson21.com/youtube/default(183).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "-SPRPkLoKp8",
    "caption": "mechanism of hormone action"
  }, {
    "src": "https://youtu.be/zSCR2K81IRo",
    "thumbnail": "https://www.lesson21.com/youtube/default(184).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "zSCR2K81IRo",
    "caption": "Crop production"
  }, {
    "src": "https://youtu.be/tCrgTV20BD4",
    "thumbnail": "https://www.lesson21.com/youtube/default(185).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "tCrgTV20BD4",
    "caption": "nitrogen fixation"
  }, {
    "src": "https://youtu.be/7KkX78c8fRo",
    "thumbnail": "https://www.lesson21.com/youtube/default(186).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "7KkX78c8fRo",
    "caption": "Nitrogen cycle"
  }, {
    "src": "https://youtu.be/JZjzQhFG6Ec",
    "thumbnail": "https://www.lesson21.com/youtube/default(187).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "JZjzQhFG6Ec",
    "caption": "Micro organisms – useful and harmful."
  }, {
    "src": "https://youtu.be/2XdeLpm42i8",
    "thumbnail": "https://www.lesson21.com/youtube/default(188).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "2XdeLpm42i8",
    "caption": "endangered species"
  }, {
    "src": "https://youtu.be/kCpQQy-G0i4",
    "thumbnail": "https://www.lesson21.com/youtube/default(189).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "kCpQQy-G0i4",
    "caption": "endemic species"
  }, {
    "src": "https://youtu.be/jLzprmI1l20",
    "thumbnail": "https://www.lesson21.com/youtube/default(190).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "jLzprmI1l20",
    "caption": "migration"
  }, {
    "src": "https://youtu.be/-01T9e6VDWU",
    "thumbnail": "https://www.lesson21.com/youtube/default(191).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "-01T9e6VDWU",
    "caption": "Consequences of deforestation"
  }, {
    "src": "https://youtu.be/-EhvWXKSggo",
    "thumbnail": "https://www.lesson21.com/youtube/default(192).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "-EhvWXKSggo",
    "caption": "Reforestation"
  }, {
    "src": "https://youtu.be/sAKyhfxxr7s",
    "thumbnail": "https://www.lesson21.com/youtube/default(193).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "sAKyhfxxr7s",
    "caption": " air pollution"
  }, {
    "src": "https://youtu.be/93BqLewm3bA",
    "thumbnail": "https://www.lesson21.com/youtube/default(194).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "93BqLewm3bA",
    "caption": "water pollution"
  }, {
    "src": "https://youtu.be/Hnfdq2htoKU",
    "thumbnail": "https://www.lesson21.com/youtube/default(195).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "Hnfdq2htoKU",
    "caption": "soil polluition"
  }, {
    "src": "https://youtu.be/8919Zm8Gi4U",
    "thumbnail": "https://www.lesson21.com/youtube/default(196).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "8919Zm8Gi4U",
    "caption": "Infectious and Non-infectious diseases"
  }, {
    "src": "https://youtu.be/putoeGrNVg8",
    "thumbnail": "https://www.lesson21.com/youtube/default(197).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "putoeGrNVg8",
    "caption": "Holes in ozone layer"
  }, {
    "src": "https://youtu.be/ncORPosDrjI",
    "thumbnail": "https://www.lesson21.com/youtube/default(198).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "ncORPosDrjI",
    "caption": "Water Cycle"
  }, {
    "src": "https://youtu.be/pGWJ9GgDNhM",
    "thumbnail": "https://www.lesson21.com/youtube/default(199).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "pGWJ9GgDNhM",
    "caption": "Oxygen Cycle"
  }, {
    "src": "https://youtu.be/KNLUzqW8IuA",
    "thumbnail": "https://www.lesson21.com/youtube/default(200).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "KNLUzqW8IuA",
    "caption": "Carbon Cycle"
  }, {
    "src": "https://youtu.be/K5EOZenSSB8",
    "thumbnail": "https://www.lesson21.com/youtube/default(201).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "K5EOZenSSB8",
    "caption": "Nitrogen Cycle"
  }, {
    "src": "https://youtu.be/a-vhqQTLpw4",
    "thumbnail": "https://www.lesson21.com/youtube/default(202).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "a-vhqQTLpw4",
    "caption": "Basic concept of nutrition in plants"
  }, {
    "src": "https://youtu.be/rvrao5V89nE",
    "thumbnail": "https://www.lesson21.com/youtube/default(203).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "rvrao5V89nE",
    "caption": "respiration in plants"
  }, {
    "src": "https://youtu.be/JTlUip0-boQ",
    "thumbnail": "https://www.lesson21.com/youtube/default(204).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "JTlUip0-boQ",
    "caption": "respiration in animals"
  }, {
    "src": "https://youtu.be/MPT1ope-NFU",
    "thumbnail": "https://www.lesson21.com/youtube/default(205).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "MPT1ope-NFU",
    "caption": "excretion in animals"
  }, {
    "src": "https://youtu.be/J0hjnC14uMM",
    "thumbnail": "https://www.lesson21.com/youtube/default(206).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "J0hjnC14uMM",
    "caption": "excretion in plants"
  }, {
    "src": "https://youtu.be/HP21hIVJhWI",
    "thumbnail": "https://www.lesson21.com/youtube/default(207).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "HP21hIVJhWI",
    "caption": "Reproduction in plants"
  }, {
    "src": "https://youtu.be/4h4tdQxgWP0",
    "thumbnail": "https://www.lesson21.com/youtube/default(208).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "4h4tdQxgWP0",
    "caption": "Reproduction in animals"
  }, {
    "src": "https://youtu.be/FXXAF_B7vN0",
    "thumbnail": "https://www.lesson21.com/youtube/default(209).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "FXXAF_B7vN0",
    "caption": "reproductive health- need"
  }, {
    "src": "https://youtu.be/ng22Ucr33aw&ab",
    "thumbnail": "https://www.lesson21.com/youtube/default(210).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "ng22Ucr33aw&ab",
    "caption": "Safe sex vs HIV/AIDS"
  }, {
    "src": "https://youtu.be/x0ksaQhAl-g&ab",
    "thumbnail": "https://www.lesson21.com/youtube/default(211).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "x0ksaQhAl-g&ab",
    "caption": "Mendel’s contribution- Laws for inheritance of traits"
  }, {
    "src": "https://youtu.be/FAEEuhZR7vg&ab",
    "thumbnail": "https://www.lesson21.com/youtube/default(212).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "FAEEuhZR7vg&ab",
    "caption": "Eco-system"
  }, {
    "src": "https://youtu.be/hn7W_C2nbwE",
    "thumbnail": "https://www.lesson21.com/youtube/default(213).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "hn7W_C2nbwE",
    "caption": "Ozone depletion"
  }, {
    "src": "https://youtu.be/YeVLBkypPRU",
    "thumbnail": "https://www.lesson21.com/youtube/default(214).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "YeVLBkypPRU",
    "caption": "Biodegradable and non-biodegradable substances"
  }, {
    "src": "https://youtu.be/ADpxUQMCSng",
    "thumbnail": "https://www.lesson21.com/youtube/default(215).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "ADpxUQMCSng",
    "caption": "Inverse Trigonometric Functions"
  }, {
    "src": "https://youtu.be/F37rd-BpVOo",
    "thumbnail": "https://www.lesson21.com/youtube/default(216).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "F37rd-BpVOo",
    "caption": "Basics of Matrices"
  }, {
    "src": "https://youtu.be/g_Rz94DXvNo",
    "thumbnail": "https://www.lesson21.com/youtube/default(217).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "g_Rz94DXvNo",
    "caption": "Transpose of a Matrix"
  }, {
    "src": "https://youtu.be/AMLUikdDQGk",
    "thumbnail": "https://www.lesson21.com/youtube/default(218).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "AMLUikdDQGk",
    "caption": "Inverse of a Matrix"
  }, {
    "src": "https://youtu.be/YFGTpSkfT40",
    "thumbnail": "https://www.lesson21.com/youtube/default(219).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "YFGTpSkfT40",
    "caption": "Determinant of a Matrix"
  }, {
    "src": "https://youtu.be/wrIAFfZBoBM",
    "thumbnail": "https://www.lesson21.com/youtube/default(220).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "wrIAFfZBoBM",
    "caption": "Determinant of a Matrix"
  }, {
    "src": "https://youtu.be/lhgFVZOgleg",
    "thumbnail": "https://www.lesson21.com/youtube/default(221).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "lhgFVZOgleg",
    "caption": "Determinant of a Matrix"
  }, {
    "src": "https://youtu.be/rowWM-MijXU",
    "thumbnail": "https://www.lesson21.com/youtube/default(222).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "rowWM-MijXU",
    "caption": "The application of Determinants"
  }, {
    "src": "https://youtu.be/HYWeEx21WWw",
    "thumbnail": "https://www.lesson21.com/youtube/default(223).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "HYWeEx21WWw",
    "caption": "Adjoint of a Matrix"
  }, {
    "src": "https://youtu.be/AMLUikdDQGk",
    "thumbnail": "https://www.lesson21.com/youtube/default(224).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "AMLUikdDQGk",
    "caption": "Inverse of a matrix"
  }, {
    "src": "https://youtu.be/bPLyCH1WaEY",
    "thumbnail": "https://www.lesson21.com/youtube/default(225).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "bPLyCH1WaEY",
    "caption": "Rules of Logarithm"
  }, {
    "src": "https://youtu.be/ifbaAqfqpc4",
    "thumbnail": "https://www.lesson21.com/youtube/default(226).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "ifbaAqfqpc4",
    "caption": "Differential Equation"
  }, {
    "src": "https://youtu.be/W55qMEF1a_E",
    "thumbnail": "https://www.lesson21.com/youtube/default(227).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "W55qMEF1a_E",
    "caption": "Contact & Non-Contact force"
  }, {
    "src": "https://youtu.be/-n1pzh81KA8",
    "thumbnail": "https://www.lesson21.com/youtube/default(228).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "-n1pzh81KA8",
    "caption": "Tangents to a Circle"
  }, {
    "src": "https://youtu.be/odBiKFAdEXc",
    "thumbnail": "https://www.lesson21.com/youtube/default(229).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "odBiKFAdEXc",
    "caption": "Integration"
  }, {
    "src": "https://youtu.be/cRWxioj-aVs",
    "thumbnail": "https://www.lesson21.com/youtube/default(230).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "cRWxioj-aVs",
    "caption": "Integration of a Function"
  }, {
    "src": "https://youtu.be/IoMdxDIJgVc",
    "thumbnail": "https://www.lesson21.com/youtube/default(231).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "IoMdxDIJgVc",
    "caption": "Integral of a Function"
  }, {
    "src": "https://youtu.be/W1uKClY3sN4",
    "thumbnail": "https://www.lesson21.com/youtube/default(232).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "W1uKClY3sN4",
    "caption": "Differentiation and Integration"
  }, {
    "src": "https://youtu.be/fNk_zzaMoSs",
    "thumbnail": "https://www.lesson21.com/youtube/default(233).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "fNk_zzaMoSs",
    "caption": "Vectors"
  }, {
    "src": "https://youtu.be/QS3hyhUqHOQ",
    "thumbnail": "https://www.lesson21.com/youtube/default(234).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "QS3hyhUqHOQ",
    "caption": "Types of Vectors"
  }, {
    "src": "https://youtu.be/VFRW0f0XUU8",
    "thumbnail": "https://www.lesson21.com/youtube/default(235).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "VFRW0f0XUU8",
    "caption": "Operation of Vectors"
  }, {
    "src": "https://youtu.be/jB7Ro3w77MM",
    "thumbnail": "https://www.lesson21.com/youtube/default(236).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "jB7Ro3w77MM",
    "caption": "Coplanar Points and Lines"
  }, {
    "src": "https://youtu.be/j1kZY25ZOLc",
    "thumbnail": "https://www.lesson21.com/youtube/default(237).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "j1kZY25ZOLc",
    "caption": "Parallel,Intersecting and Skew Lines"
  }, {
    "src": "https://youtu.be/Uo6aRV-mbeg",
    "thumbnail": "https://www.lesson21.com/youtube/default(238).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "Uo6aRV-mbeg",
    "caption": "Linear Programming"
  }, {
    "src": "https://youtu.be/qQFAvPF2OSI",
    "thumbnail": "https://www.lesson21.com/youtube/default(239).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "qQFAvPF2OSI",
    "caption": "Linear Programming and Graphical Solution"
  }, {
    "src": "https://youtu.be/BQrQBnsKUOU",
    "thumbnail": "https://www.lesson21.com/youtube/default(240).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "BQrQBnsKUOU",
    "caption": "Probability"
  }, {
    "src": "https://youtu.be/FdK9_Fp76cw",
    "thumbnail": "https://www.lesson21.com/youtube/default(241).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "FdK9_Fp76cw",
    "caption": "Functions,Domain and Range"
  }, {
    "src": "https://youtu.be/hqr1DtXXHpY",
    "thumbnail": "https://www.lesson21.com/youtube/default(242).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "hqr1DtXXHpY",
    "caption": "Complex Numbers"
  }, {
    "src": "https://youtu.be/BHgewRcuoRM",
    "thumbnail": "https://www.lesson21.com/youtube/default(243).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "BHgewRcuoRM",
    "caption": "Graphs of Quadratic Equations"
  }, {
    "src": "https://youtu.be/gua96ju_FBk",
    "thumbnail": "https://www.lesson21.com/youtube/default(244).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "gua96ju_FBk",
    "caption": "Arithmetic Progression"
  }, {
    "src": "https://youtu.be/3YwrcJxEbZw",
    "thumbnail": "https://www.lesson21.com/youtube/default(245).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "3YwrcJxEbZw",
    "caption": "Contact & Non-Contact force"
  }, {
    "src": "https://youtu.be/nO12O2bvDDw",
    "thumbnail": "https://www.lesson21.com/youtube/default(246).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "nO12O2bvDDw",
    "caption": "Rationalising Factor"
  }, {
    "src": "https://youtu.be/ffLLmV4mZwU",
    "thumbnail": "https://www.lesson21.com/youtube/default(247).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "ffLLmV4mZwU",
    "caption": "Polynomials"
  }, {
    "src": "https://youtu.be/W55qMEF1a_E",
    "thumbnail": "https://www.lesson21.com/youtube/default(248).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "W55qMEF1a_E",
    "caption": "Contact & Non-Contact force"
  }, {
    "src": "https://youtu.be/MPcZ3nhZO-M",
    "thumbnail": "https://www.lesson21.com/youtube/default(249).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "MPcZ3nhZO-M",
    "caption": "Degree of a polynomial"
  }, {
    "src": "https://youtu.be/4YXE7HDyInM",
    "thumbnail": "https://www.lesson21.com/youtube/default(250).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "4YXE7HDyInM",
    "caption": "Factor theorem"
  }, {
    "src": "https://youtu.be/W55qMEF1a_E",
    "thumbnail": "https://www.lesson21.com/youtube/default(251).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "W55qMEF1a_E",
    "caption": "Contact & Non-Contact force"
  }, {
    "src": "https://youtu.be/lDOYdBgtnjY",
    "thumbnail": "https://www.lesson21.com/youtube/default(252).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "lDOYdBgtnjY",
    "caption": "Linear Equation"
  }, {
    "src": "https://youtu.be/9Uc62CuQjc4",
    "thumbnail": "https://www.lesson21.com/youtube/default(253).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "9Uc62CuQjc4",
    "caption": "Graphing on the co-ordinate plane"
  }, {
    "src": "https://youtu.be/LqrHvGAaNDI",
    "thumbnail": "https://www.lesson21.com/youtube/default(254).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "LqrHvGAaNDI",
    "caption": "Contact & Non-Contact force"
  }, {
    "src": "https://youtu.be/DGKwdHMiqCg",
    "thumbnail": "https://www.lesson21.com/youtube/default(255).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "DGKwdHMiqCg",
    "caption": "Angle basics"
  }, {
    "src": "https://youtu.be/mLeNaZcy-hE",
    "thumbnail": "https://www.lesson21.com/youtube/default(256).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "mLeNaZcy-hE",
    "caption": "Triangles"
  }, {
    "src": "https://youtu.be/yiREqzDsMP8",
    "thumbnail": "https://www.lesson21.com/youtube/default(257).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "yiREqzDsMP8",
    "caption": "Quadrilaterals"
  }, {
    "src": "https://youtu.be/Y5Z69g7A0eI",
    "thumbnail": "https://www.lesson21.com/youtube/default(258).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "Y5Z69g7A0eI",
    "caption": "Circles"
  }, {
    "src": "https://youtu.be/lhfCv0kQhb4",
    "thumbnail": "https://www.lesson21.com/youtube/default(259).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "lhfCv0kQhb4",
    "caption": "Constructions"
  }, {
    "src": "https://youtu.be/gBbHym3y_NM",
    "thumbnail": "https://www.lesson21.com/youtube/default(260).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "gBbHym3y_NM",
    "caption": "Area of triangle using h=Herons Formula"
  }, {
    "src": "https://youtu.be/eBAq_caikJ4",
    "thumbnail": "https://www.lesson21.com/youtube/default(261).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "eBAq_caikJ4",
    "caption": "Surface Area and Volume"
  }, {
    "src": "https://youtu.be/tB41caiQ4jI",
    "thumbnail": "https://www.lesson21.com/youtube/default(262).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "tB41caiQ4jI",
    "caption": "Statistics"
  }, {
    "src": "https://youtu.be/lBHd7FgO-JU",
    "thumbnail": "https://www.lesson21.com/youtube/default(263).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "lBHd7FgO-JU",
    "caption": "Presentation of Data"
  }, {
    "src": "https://youtu.be/KzfWUEJjG18",
    "thumbnail": "https://www.lesson21.com/youtube/default(264).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "KzfWUEJjG18",
    "caption": "Probability"
  }, {
    "src": "https://youtu.be/T9lt6MZKLck",
    "thumbnail": "https://www.lesson21.com/youtube/default(265).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "T9lt6MZKLck",
    "caption": "Trignometry"
  }, {
    "src": "https://youtu.be/T7D1W1oD8wo",
    "thumbnail": "https://www.lesson21.com/youtube/default(266).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "T7D1W1oD8w",
    "caption": "Super Hexagon for Trignometric identities"
  }, {
    "src": "https://youtu.be/BGXgcvGPRLI",
    "thumbnail": "https://www.lesson21.com/youtube/default(267).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "BGXgcvGPRLI",
    "caption": "Heights and distances"
  }, {
    "src": "https://youtu.be/6DYtC7lrVuY",
    "thumbnail": "https://www.lesson21.com/youtube/default(268).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "6DYtC7lrVuY",
    "caption": "Mean,Median and Mode"
  }, {
    "src": "https://youtu.be/fy-bd5AC-Ms",
    "thumbnail": "https://www.lesson21.com/youtube/default(269).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "fy-bd5AC-Ms",
    "caption": "3 Different English accents"
  }, {
    "src": "https://youtu.be/i5mYphUoOCs",
    "thumbnail": "https://www.lesson21.com/youtube/default(270).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "i5mYphUoOCs",
    "caption": "Public Speaking for beginners"
  }, {
    "src": "https://youtu.be/KJS77wXttpI",
    "thumbnail": "https://www.lesson21.com/youtube/default(271).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "KJS77wXttpI",
    "caption": "Learn idioms with TV series -part 1"
  }, {
    "src": "https://youtu.be/DAj-c4DuVu8",
    "thumbnail": "https://www.lesson21.com/youtube/default(272).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "DAj-c4DuVu8",
    "caption": "15 classical books everyone should read"
  }, {
    "src": "https://youtu.be/dRrvQ1vZxcg",
    "thumbnail": "https://www.lesson21.com/youtube/default(273).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "dRrvQ1vZxcg",
    "caption": "Shakespears Romeo and Juliet - Summary "
  }, {
    "src": "https://youtu.be/KxhUTAi7UTQ",
    "thumbnail": "https://www.lesson21.com/youtube/default(274).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "KxhUTAi7UTQ",
    "caption": "The count of Monte Cristo - Summary"
  }, {
    "src": "https://youtu.be/4hzzamfIqDs",
    "thumbnail": "https://www.lesson21.com/youtube/default(275).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "4hzzamfIqDs",
    "caption": "Les Miserables- Summary"
  }, {
    "src": "https://youtu.be/kM7PfJFSI1s",
    "thumbnail": "https://www.lesson21.com/youtube/default(276).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "kM7PfJFSI1s",
    "caption": "The Three Musketeers- Summary"
  }, {
    "src": "https://youtu.be/g4nDN0W7u20",
    "thumbnail": "https://www.lesson21.com/youtube/default(277).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "g4nDN0W7u20",
    "caption": "Daffodils - A poem by William Wordsworth"
  }, {
    "src": "https://youtu.be/qtMAsek_0UQ",
    "thumbnail": "https://www.lesson21.com/youtube/default(278).jpg",
    "thumbnailWidth": 320,
    "thumbnailHeight": 174,
    "isSelected": false,
    "videoId": "qtMAsek_0UQ",
    "caption": "Learn idioms with TV series -part 2"
  }]
}: {
  smallStats: [{"src":"https://youtu.be/IJWEtCRWGvI",
  "thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=IJWEtCRWGvI').default.url,
  "thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"IJWEtCRWGvI",
  "caption":"Force and Pressure"},
{"src":"https://youtu.be/W55qMEF1a_E",
  "thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=W55qMEF1a_E').default.url,
  "thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"W55qMEF1a_E",
  "caption":"Contact & Non-Contact force"},
{"src":"https://youtu.be/n2gQs1mcZHA",
  "thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=n2gQs1mcZHA').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"n2gQs1mcZHA","caption":"What is Friction?"},
{"src":"https://youtu.be/ybpFEB6Mt88","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=ybpFEB6Mt88').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"ybpFEB6Mt88","caption":"Factors affecting Friction"},
{"src":"https://youtu.be/R10tuvCdl8c","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=R10tuvCdl8c').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"R10tuvCdl8c","caption":"Types of frictional forces"},
{"src":"https://youtu.be/zlLpKzPz84Q","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=zlLpKzPz84Q').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"zlLpKzPz84Q","caption":"Pressure"},
{"src":"https://youtu.be/KndNN28OcEI","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=KndNN28OcEI').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"KndNN28OcEI","caption":"Atmospheric Pressure"},
{"src":"https://youtu.be/OWETRP_eabg","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=OWETRP_eabg').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"OWETRP_eabg","caption":"Sound "},
{"src":"https://youtu.be/BFWLb_MKyRE","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=BFWLb_MKyRE').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"BFWLb_MKyRE","caption":"What produces Sound?"},
{"src":"https://youtu.be/WqnF-VPfGPw","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=WqnF-VPfGPw').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"WqnF-VPfGPw","caption":"Propagation of Sound"},
{"src":"https://youtu.be/EM_sN_YHNow","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=EM_sN_YHNow').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"EM_sN_YHNow","caption":"Lightning"},
{"src":"https://youtu.be/OrobTDEYs2M","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=OrobTDEYs2M').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"OrobTDEYs2M","caption":"What are the Laws of Reflection of Light?"},
{"src":"https://youtu.be/A8DgGmIykk8","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=A8DgGmIykk8').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"A8DgGmIykk8","caption":"Specular and Diffusion Reflection"},
{"src":"https://youtu.be/21BwUNDOQno","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=21BwUNDOQno').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"21BwUNDOQno","caption":"Distance and displacement"},
{"src":"https://youtu.be/apewLkLAR-U","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=apewLkLAR-U').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"apewLkLAR-U","caption":"What is velocity?"},
{"src":"https://youtu.be/vxFYfumAAlY","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=vxFYfumAAlY').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"vxFYfumAAlY","caption":"What is Acceleration"},
{"src":"https://youtu.be/VFfF3F-G9Uk","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=VFfF3F-G9Uk').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"VFfF3F-G9Uk","caption":"Uniform Motion and Non-uniform Motion"},
{"src":"https://youtu.be/5oi5j11FkQg","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=5oi5j11FkQg').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"5oi5j11FkQg","caption":"Newton's First Law of Motion"},
{"src":"https://youtu.be/8YhYqN9BwB4","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=8YhYqN9BwB4').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"8YhYqN9BwB4","caption":"Newton's Second Law of Motion"},
{"src":"https://youtu.be/TVAxASr0iUY","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=TVAxASr0iUY').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"TVAxASr0iUY","caption":"Newton's Third Law of Motion"},
{"src":"https://youtu.be/qBjmO8w-QqU","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=qBjmO8w-QqU').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"qBjmO8w-QqU","caption":"Inertia "},
{"src":"https://youtu.be/EzNfvwPCFs0","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=EzNfvwPCFs0').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"EzNfvwPCFs0","caption":"What Is Momentum?"},
{"src":"https://youtu.be/Jk5E-CrE1zg","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=Jk5E-CrE1zg').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"Jk5E-CrE1zg","caption":"Newtons Universal Law of Gravitation"},
{"src":"https://youtu.be/Sw2P1hpwpqU","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=Sw2P1hpwpqU').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"Sw2P1hpwpqU","caption":"Motion and Gravity "},
{"src":"https://youtu.be/iWSpdgGAsuE","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=iWSpdgGAsuE').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"iWSpdgGAsuE","caption":"What is Kinetic & Potential Energy?"},
{"src":"https://youtu.be/skGmQC87Bvg","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=skGmQC87Bvg').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"skGmQC87Bvg","caption":"Reflection of Light"},
{"src":"https://youtu.be/EwBK_cXUTZI","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=EwBK_cXUTZI').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"EwBK_cXUTZI","caption":"What are Real and Virtual Images? "},
{"src":"https://youtu.be/v5SuSB_93FM","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=v5SuSB_93FM').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"v5SuSB_93FM","caption":"Refraction of Light"},
{"src":"https://youtu.be/X3BYFv-CaJM","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=X3BYFv-CaJM').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"X3BYFv-CaJM","caption":"Why does Refraction occur?"},
{"src":"https://youtu.be/KgqV975EtA0","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=KgqV975EtA0').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"KgqV975EtA0","caption":"Refraction of Light Experiment "},
{"src":"https://youtu.be/NfcgA1axPLo","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=NfcgA1axPLo').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"NfcgA1axPLo","caption":"Resistance and Ohm's law"},
{"src":"https://youtu.be/w82aSjLuD_8","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=w82aSjLuD_8').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"w82aSjLuD_8","caption":" electricity potential difference"},
{"src":"https://youtu.be/VSpB3HivkhY","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=VSpB3HivkhY').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"VSpB3HivkhY","caption":"Power and watts"},
{"src":"https://youtu.be/R4ht2RcWVlI","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=R4ht2RcWVlI').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"R4ht2RcWVlI","caption":"Magnetic Force and Magnetic Field"},
{"src":"https://youtu.be/qvB1mmfo7MQ","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=qvB1mmfo7MQ').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"qvB1mmfo7MQ","caption":"Fleming’s Left Hand Rule"},
{"src":"https://youtu.be/UMDb0tlwQAg","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=UMDb0tlwQAg').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"UMDb0tlwQAg","caption":"Fleming's right hand rule"},
{"src":"https://youtu.be/0wsdkyrtfaY","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=0wsdkyrtfaY').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"0wsdkyrtfaY","caption":"Variables, Units, the SI, Fundamental vs Derived Units, and Prefixes"},
{"src":"https://youtu.be/WJN_F3PYp58","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=WJN_F3PYp58').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"WJN_F3PYp58","caption":"Equations of motion"},
{"src":"https://youtu.be/Pj8Zh0A-uLU","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=Pj8Zh0A-uLU').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"Pj8Zh0A-uLU","caption":"Scalar Quantity and Vector Quantity"},
{"src":"https://youtu.be/lmipUGrQjBM","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=lmipUGrQjBM').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"lmipUGrQjBM","caption":"Understanding vectors"},
{"src":"https://youtu.be/i5PtaCJJFjw","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=i5PtaCJJFjw').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"i5PtaCJJFjw","caption":"Newtons First and Second Laws"},
{"src":"https://youtu.be/DpQ_ikFKru0","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=DpQ_ikFKru0').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"DpQ_ikFKru0",
  "caption":"Newton’s Third Law"},
{"src":"https://youtu.be/y2FmgoOht7Y",
  "thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=y2FmgoOht7Y').default.url,
  "thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"zlLpKzPz84Q",
  "caption":"Understanding Circular Motion"},
{"src":"https://youtu.be/g7u6pIfUVy4",
  "thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=g7u6pIfUVy4').default.url,
  "thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"g7u6pIfUVy4","caption":"Kinetic Energy and Potential Energy"},
{"src":"https://youtu.be/Bls5KnQOWkY","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=Bls5KnQOWkY').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"Bls5KnQOWkY","caption":"Understanding the Area Moment of Inertia"},
{"src":"https://youtu.be/6zXkYjmvLuI","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=6zXkYjmvLuI').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"6zXkYjmvLuI","caption":"Equilibrium of a Body"},
{"src":"https://youtu.be/c9shwPMpSq8","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=c9shwPMpSq8').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"c9shwPMpSq8","caption":"The Universal Law of Gravitation - Part 1"},
{"src":"https://youtu.be/lbOXZ2tcTgc","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=lbOXZ2tcTgc').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"lbOXZ2tcTgc","caption":"Universal Law of Gravitation - Part 2"},
{"src":"https://youtu.be/lGlAU4lcneI","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=lGlAU4lcneI').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"lGlAU4lcneI","caption":"What is gravitational potential energy??"},
{"src":"https://youtu.be/XzhVK09YReA","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=XzhVK09YReA').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"XzhVK09YReA","caption":"Geostationary Satellite"},
{"src":"https://youtu.be/aQf6Q8t1FQE","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=aQf6Q8t1FQE').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"aQf6Q8t1FQE","caption":"An Introduction to Stress and Strain"},
{"src":"https://youtu.be/DLE-ieOVFjI","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=DLE-ieOVFjI').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"DLE-ieOVFjI","caption":"Understanding Young's Modulus"},
{"src":"https://youtu.be/LaMixSlMDUE","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=LaMixSlMDUE').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"LaMixSlMDUE","caption":"Pascal's law"},
{"src":"https://youtu.be/DW4rItB20h4","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=DW4rItB20h4').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"DW4rItB20h4","caption":"Bernoulli's Equation"},
{"src":"https://youtu.be/VvDJyhYSJv8","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=VvDJyhYSJv8').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"VvDJyhYSJv8","caption":"Viscosity"},
{"src":"https://youtu.be/jatprpYqaos","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=jatprpYqaos').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"jatprpYqaos","caption":"Thermal expansion"},
{"src":"https://youtu.be/8hpSl2MeyA","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=8hpSl2MeyA').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"8hpSl2MeyA","caption":"First Law of Thermodynamics"},
{"src":"https://youtu.be/WTtxlaeC9PY","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=WTtxlaeC9PY').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"WTtxlaeC9PY","caption":"Second Law of Thermodynamics"},
{"src":"https://youtu.be/PE_zpk-EznQ","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=PE_zpk-EznQ').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"PE_zpk-EznQ","caption":"Zeroth Law of Thermodynamics"},
{"src":"https://youtu.be/13i3xbuaCqI","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=13i3xbuaCqI').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"13i3xbuaCqI","caption":"Avogadro's Number"},
{"src":"https://youtu.be/XjkUcJkGd3Y","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=XjkUcJkGd3Y').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"XjkUcJkGd3Y","caption":"SHM (Simple Harmonic Motion)"},
{"src":"https://youtu.be/0Anh9HthWgQ","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=0Anh9HthWgQ').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"0Anh9HthWgQ","caption":"Transverse & Longitudinal Waves"},
{"src":"https://youtu.be/yOv4xxopQFQ","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=yOv4xxopQFQ').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"yOv4xxopQFQ","caption":"Electric Flux and Gauss’s Law"},
{"src":"https://youtu.be/PafSqL1riS4","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=PafSqL1riS4').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"PafSqL1riS4","caption":"What are Conductors and Insulators?"},
{"src":"https://youtu.be/X4EUwTwZ110","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=X4EUwTwZ110').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"X4EUwTwZ110","caption":"Capacitors Explained"},
{"src":"https://youtu.be/HsLLq6Rm5tU","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=HsLLq6Rm5tU').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"HsLLq6Rm5tU","caption":"Ohms Law Explained - The basics circuit theory"},
{"src":"https://youtu.be/ZqAM_wQ35ow","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=ZqAM_wQ35ow').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"ZqAM_wQ35ow","caption":"Wheatstone bridge"},
{"src":"https://youtu.be/RwilgsQ9xaM","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=RwilgsQ9xaM').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"RwilgsQ9xaM","caption":"What Oersted Discovered with his Compass"},
{"src":"https://youtu.be/7PZx-7XFNX4","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=7PZx-7XFNX4').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"7PZx-7XFNX4","caption":"THE BIOT SAVART'S LAW"},
{"src":"https://youtu.be/Y18N-hi5P1o","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=Y18N-hi5P1o').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"Y18N-hi5P1o","caption":"Faraday's Law of Induction"},
{"src":"https://youtu.be/wsuBld3Bo00","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=wsuBld3Bo00').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"wsuBld3Bo00","caption":"Lenz's Law and Conservation of Energy"},
{"src":"https://youtu.be/_7u6nh35dJ8","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=_7u6nh35dJ8').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"_7u6nh35dJ8","caption":"Alternating Current"},
{"src":"https://youtu.be/7v2gs8rdQzU","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=7v2gs8rdQzU').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"7v2gs8rdQzU","caption":"Electromagnetic Waves"},
{"src":"https://youtu.be/v5SuSB_93FM","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=v5SuSB_93FM').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"v5SuSB_93FM","caption":"Refraction of Light"},
{"src":"https://youtu.be/he8jSyRWvxE","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=he8jSyRWvxE').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"he8jSyRWvxE","caption":"Diffraction and Huygens's Principle"},
{"src":"https://youtu.be/0b0axfyJ4oo","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=0b0axfyJ4oo').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"0b0axfyJ4oo","caption":"The Photoelectric Effect"},
{"src":"https://youtu.be/u7xrDhpY-oA","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=u7xrDhpY-oA').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"u7xrDhpY-oA","caption":"Rutherford's atom and the Gold Foil Experiment"},
{"src":"https://youtu.be/y9kWdegtrs0","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=y9kWdegtrs0').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"y9kWdegtrs0","caption":"Understanding Bohr's Atom"},
{"src":"https://youtu.be/xrk7Mt2fx6Y","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=xrk7Mt2fx6Y').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"xrk7Mt2fx6Y","caption":"Nuclear fission and nuclear fusion"},
{"src":"https://youtu.be/pw_tzpgytcE","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=pw_tzpgytcE').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"pw_tzpgytcE","caption":"Energy band for conductors, semiconductors & insulator"},
{"src":"https://youtu.be/L_q6LRgKpTw","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=L_q6LRgKpTw').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"L_q6LRgKpTw","caption":"How do Solar cells work?"},
{"src":"https://youtu.be/JNi6WY7WKAI","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=JNi6WY7WKAI').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"JNi6WY7WKAI","caption":"How does a Diode work ?"},
{"src":"https://youtu.be/4BX2mEQf09c","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=4BX2mEQf09c').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"4BX2mEQf09c","caption":"the LED & solar cell"},
{"src":"https://youtu.be/g-tE6MN-wrE","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=g-tE6MN-wrE').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"g-tE6MN-wrE","caption":"Chemical Bonding"},
{"src":"https://youtu.be/uKpr-9vmgsc","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=uKpr-9vmgsc').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"uKpr-9vmgsc","caption":"Hexagonal Closed Packed Structure"},
{"src":"https://youtu.be/Z0Ks3wjFnrk","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=Z0Ks3wjFnrk').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"Z0Ks3wjFnrk","caption":"Imperfections in Solids"},
{"src":"https://youtu.be/9h2f1Bjr0p4","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=9h2f1Bjr0p4').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"9h2f1Bjr0p4","caption":"Solutions"},
{"src":"https://youtu.be/5onYR2emPb0","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=5onYR2emPb0').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"5onYR2emPb0","caption":"Raoult's law"},
{"src":"https://youtu.be/4i1MUWJoI0U","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=4i1MUWJoI0U').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"4i1MUWJoI0U","caption":"Thermodynamics"},
{"src":"https://youtu.be/CTtf5s2HFkA","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=CTtf5s2HFkA').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"CTtf5s2HFkA","caption":"Gold Bullion Vault"},
{"src":"https://youtu.be/HhT2E7wuAgg","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=HhT2E7wuAgg').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"HhT2E7wuAgg","caption":"Basics of Nomenclature"},
{"src":"https://youtu.be/M0uw4ZNpqcI","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=M0uw4ZNpqcI').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"M0uw4ZNpqcI","caption":"Radioactivity"},
{"src":"https://youtu.be/MjCFw3EQNBU","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=MjCFw3EQNBU').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"MjCFw3EQNBU","caption":"Chernobyl Incident"},
{"src":"https://youtu.be/QLZMzsRB86E","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=QLZMzsRB86E').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"QLZMzsRB86E","caption":"Plutonium Core of an Atom Bomb"},
{"src":"https://youtu.be/xMa1BQ8z9C0","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=xMa1BQ8z9C0').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"xMa1BQ8z9C0","caption":"IR Spectroscopy and Mass Spectrometry"},
{"src":"https://youtu.be/H8WJ2KENlK0","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=H8WJ2KENlK0').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"H8WJ2KENlK0","caption":"Biomolecules"},
{"src":"https://youtu.be/qxlR7ZdgV7w","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=qxlR7ZdgV7w').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"qxlR7ZdgV7w","caption":"Tyndall Effect"},
{"src":"https://youtu.be/jyvcVjrZnJA","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=jyvcVjrZnJA').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"jyvcVjrZnJA","caption":"Redox Reactilons"},
{"src":"https://youtu.be/shJAV59NS6k","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=https://youtu.be/shJAV59NS6k').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"shJAV59NS6k","caption":"EMF of a cell"},
{"src":"https://youtu.be/IV4IUsholjg","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=IV4IUsholjg').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"IV4IUsholjg","caption":"Electrochemistry"},
{"src":"https://youtu.be/gou_uogLKN8","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=gou_uogLKN8').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"gou_uogLKN8","caption":"Catalysts"},
{"src":"https://youtu.be/vD0kbdIS6kE","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=vD0kbdIS6kE').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"vD0kbdIS6kE","caption":"How does petroleum refining work ?"},
{"src":"https://youtu.be/P99C051arMo","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=P99C051arMo').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"P99C051arMo","caption":"Inside a nuclear reactor"},
{"src":"https://youtu.be/hbfiJHW-LP4","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=hbfiJHW-LP4').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"hbfiJHW-LP4","caption":"How is honey made ?"},
{"src":"https://youtu.be/wvTv8TqWC48","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=wvTv8TqWC48').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"wvTv8TqWC48","caption":"Poteins"},
{"src":"https://youtu.be/wxzc_2c6GMg","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=wxzc_2c6GMg').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"wxzc_2c6GMg","caption":"Carbohydrates"},
{"src":"https://youtu.be/2tM1LFFxeKg","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=2tM1LFFxeKg').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"2tM1LFFxeKg","caption":"What makes muscle grow ?"},
{"src":"https://youtu.be/sX-uzr8xhWY","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=sX-uzr8xhWY').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"sX-uzr8xhWY","caption":"Reaction of metals with water"},
{"src":"https://youtu.be/8kOHaRqwMLw","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=8kOHaRqwMLw').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"8kOHaRqwMLw","caption":"Recreating Acid rain in a lab"},
{"src":"https://youtu.be/NokislH5Q1M","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=NokislH5Q1M').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"NokislH5Q1M","caption":"Cleaning the Taj Mahal"},
{"src":"https://youtu.be/pdwiwuLVzaE","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=pdwiwuLVzaE').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"pdwiwuLVzaE","caption":"CV Ramans Nobel Prize"},
{"src":"https://youtu.be/jb4CMnT2-ao","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=jb4CMnT2-ao').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"jb4CMnT2-ao","caption":"6 Chemical Reactions That Changed History"},
{"src":"https://youtu.be/1EdTw4I6L0U","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=1EdTw4I6L0U').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"1EdTw4I6L0U","caption":"Rutherford's model of an atom"},
{"src":"https://youtu.be/syi3pXJNe58","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=syi3pXJNe58').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"syi3pXJNe58","caption":"Daltons model of an atom"},
{"src":"https://youtu.be/S1LDJUu4nko","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=S1LDJUu4nko').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"S1LDJUu4nko","caption":"Bohrs model of atom"},
{"src":"https://youtu.be/IOf-z0D1mHk","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=IOf-z0D1mHk').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"IOf-z0D1mHk","caption":"How the body absorbs medicines"},
{"src":"https://youtu.be/B_em0quwuSw","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=B_em0quwuSw').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"B_em0quwuSw","caption":"Why do metals rust ?"},
{"src":"https://youtu.be/fWXPgC86EXE","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=fWXPgC86EXE').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"fWXPgC86EXE","caption":"How are soft drinks made ?"},
{"src":"https://youtu.be/gCrmFbgT37I","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=gCrmFbgT37I').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"gCrmFbgT37I","caption":"How does alcohol make people drunk?"},
{"src":"https://youtu.be/ndG-1kGD8k0","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=ndG-1kGD8k0').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"ndG-1kGD8k0","caption":"Chemistry of fragrances"},
{"src":"https://youtu.be/gIicTlGaGQE","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=gIicTlGaGQE').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"gIicTlGaGQE","caption":"Chemistry of film photography"},
{"src":"https://youtu.be/ckSoDW2-wrc","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=ckSoDW2-wrc').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"ckSoDW2-wrc","caption":"5 most dangerous chemicals"},
{"src":"https://youtu.be/t_-8peN67PY","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=t_-8peN67PY').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"t_-8peN67PY","caption":"The chemistry of Vaccines"},
{"src":"https://youtu.be/jJ5K78_TIEY","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=jJ5K78_TIEY').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"jJ5K78_TIEY","caption":"Flower structure"},
{"src":"https://youtu.be/aT-_ueLkQKw","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=aT-_ueLkQKw').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"aT-_ueLkQKw","caption":"pollination"},
{"src":"https://youtu.be/bUjVHUf4d1I","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=bUjVHUf4d1I').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"bUjVHUf4d1I","caption":"double fertilisation"},
{"src":"https://youtu.be/f2dvh0YNDwM","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=f2dvh0YNDwM').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"f2dvh0YNDwM","caption":"development of embryo"},
{"src":"https://youtu.be/PidOBjeY6MI","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=PidOBjeY6MI').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"PidOBjeY6MI","caption":"development of seed and formation of fruit"},
{"src":"https://youtu.be/T8q3JpTWhrY","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=T8q3JpTWhrY').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"T8q3JpTWhrY","caption":"Male reproductive system"},
{"src":"https://youtu.be/5SOvWaW_OY","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=5SOvWaW_OY').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"5SOvWaW_OY","caption":"female reproductive systems"},
{"src":"https://youtu.be/WGJsrGmWeKE","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=WGJsrGmWeKE').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"WGJsrGmWeKE","caption":"menstrual cycle"},
{"src":"https://youtu.be/tH1SJpg0DU8","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=tH1SJpg0DU8').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"tH1SJpg0DU8","caption":"embryo development"},
{"src":"https://youtu.be/bped-RVWsLk","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=bped-RVWsLk').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"bped-RVWsLk","caption":"placenta formation"},
{"src":"https://youtu.be/v8mRNJihA-k","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=v8mRNJihA-k').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"v8mRNJihA-k","caption":"parturition"},
{"src":"https://youtu.be/ybSquGsyTko","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=ybSquGsyTko').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"ybSquGsyTko","caption":"Lactation"},
{"src":"https://youtu.be/YaD4P3S6wUA","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=YaD4P3S6wUA').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"YaD4P3S6wUA","caption":"Sexually Transmitted Diseases"},
{"src":"https://youtu.be/A0wI5K-Y234","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=A0wI5K-Y234').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"A0wI5K-Y234","caption":"birth control"},
{"src":"https://youtu.be/004tbkwE8HA","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=004tbkwE8HA').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"004tbkwE8HA","caption":"assisted reproductive technologies"},
{"src":"https://youtu.be/ni5jyO0g1_w","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=ni5jyO0g1_w').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"ni5jyO0g1_w","caption":"Mendelian inheritance"},
{"src":"https://youtu.be/mcEV3m9SG9M","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=mcEV3m9SG9M').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"mcEV3m9SG9M","caption":"chromosomes and genes"},
{"src":"https://youtu.be/AzkXQBzZElE","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=AzkXQBzZElE').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"AzkXQBzZElE","caption":"Sex determination"},
{"src":"https://youtu.be/k03JClhz9Fo","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=k03JClhz9Fo').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"k03JClhz9Fo","caption":"Structure of DNA "},
{"src":"https://youtu.be/LDYkt1RN_YY","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=LDYkt1RN_YY').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"LDYkt1RN_YY","caption":"structure of RNA and types"},
{"src":"https://youtu.be/7onjVBsQwQ8","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=7onjVBsQwQ8').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"7onjVBsQwQ8","caption":"DNA fingerprinting"},
{"src":"https://youtu.be/Qqe4thU-os8","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=Qqe4thU-os8').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"Qqe4thU-os8","caption":"DNA replication"},
{"src":"https://youtu.be/PzunOgYHeyg","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=PzunOgYHeyg').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"PzunOgYHeyg","caption":"Basic concepts of immunology"},
{"src":"https://youtu.be/6RHLRFjnOzw","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=6RHLRFjnOzw').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"6RHLRFjnOzw","caption":"cancer"},
{"src":"https://youtu.be/EG3h71vwzYc","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=EG3h71vwzYc').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"EG3h71vwzYc","caption":"HIV and AIDS"},
{"src":"https://youtu.be/eksagPy5tmQ","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=eksagPy5tmQ').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"eksagPy5tmQ","caption":"Microbes in food processing"},
{"src":"https://youtu.be/jYaZyZ_Bmkc","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=jYaZyZ_Bmkc').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"jYaZyZ_Bmkc","caption":"antibiotics"},
{"src":"https://youtu.be/4fBQ2umTaMA","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=4fBQ2umTaMA').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"4fBQ2umTaMA","caption":"Genetic Engineering"},
{"src":"https://youtu.be/m29Y3qhCNyk","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=m29Y3qhCNyk').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"m29Y3qhCNyk","caption":"Application of biotechnology"},
{"src":"https://youtu.be/vribRyVQ6G8","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=vribRyVQ6G8').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"vribRyVQ6G8","caption":"genetically modified organisms"},
{"src":"https://youtu.be/D9lulTRx8-o","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=D9lulTRx8-o').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"D9lulTRx8-o","caption":"Habitat and niche"},
{"src":"https://youtu.be/b6Ua_zWDH6U","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=b6Ua_zWDH6U').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"b6Ua_zWDH6U","caption":"Biodiversity"},
{"src":"https://youtu.be/uqEUzgVAF6g","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=uqEUzgVAF6g').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"uqEUzgVAF6g","caption":"ecological succession"},
{"src":"https://youtu.be/Kq6faK3XHuM","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=Kq6faK3XHuM').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"Kq6faK3XHuM","caption":"binomial nomenclature"},
{"src":"https://youtu.be/DVouQRAKxYo","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=DVouQRAKxYo').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"DVouQRAKxYo","caption":"Five kingdom classification"},
{"src":"https://youtu.be/0TdQeTM0xec","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=0TdQeTM0xec').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"0TdQeTM0xec","caption":"introduction to bacteria"},
{"src":"https://youtu.be/B1CFVuQVG2U","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=B1CFVuQVG2U').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"B1CFVuQVG2U","caption":"protozoa"},
{"src":"https://youtu.be/VVuYGkk_I8s","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=VVuYGkk_I8s').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"VVuYGkk_I8s","caption":"fungi"},
{"src":"https://youtu.be/OU4_7DKmVCk","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=OU4_7DKmVCk').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"OU4_7DKmVCk","caption":"Morphology of inflorescence and flower"},
{"src":"https://youtu.be/4OpBylwH9DU","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=4OpBylwH9DU').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"4OpBylwH9DU","caption":"Cell theory"},
{"src":"https://youtu.be/Pxujitlv8wc","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=Pxujitlv8wc').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"Pxujitlv8wc","caption":"prokaryotic and eukaryotic cells"},
{"src":"https://youtu.be/192M4oDLTdc","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=192M4oDLTdc').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"192M4oDLTdc","caption":"Plant cell and animal cell"},
{"src":"https://youtu.be/fV-sG3od1k0","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=fV-sG3od1k0').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"fV-sG3od1k0","caption":"nucleus"},
{"src":"https://youtu.be/r6C54HHFTwE","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=r6C54HHFTwE').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"r6C54HHFTwE","caption":"mitochondria"},
{"src":"https://youtu.be/YO244P1e9QM","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=YO244P1e9QM').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"YO244P1e9QM","caption":"biomolecules"},
{"src":"https://youtu.be/qgVFkRn8f10","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=qgVFkRn8f10').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"qgVFkRn8f10","caption":"Enzymes"},
{"src":"https://youtu.be/_AtHhradflA","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=_AtHhradflA').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"_AtHhradflA","caption":"Cell cycle"},
{"src":"https://youtu.be/f-ldPgEfAHI","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=f-ldPgEfAHI').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"f-ldPgEfAHI","caption":"mitosis"},
{"src":"https://youtu.be/VzDMG7ke69g","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=VzDMG7ke69g').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"VzDMG7ke69g","caption":"meiosis"},
{"src":"https://youtu.be/KfvYQgT2M-k","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=KfvYQgT2M-k').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"KfvYQgT2M-k","caption":"Photosynthesis"},
{"src":"https://youtu.be/hDq1rhUkV-g","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=hDq1rhUkV-g').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"hDq1rhUkV-g","caption":"glycolysis"},
{"src":"https://youtu.be/YbdkbCU20_M","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=YbdkbCU20_M').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"YbdkbCU20_M","caption":"fermentation"},
{"src":"https://youtu.be/HR9KHW-e0pY","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=HR9KHW-e0pY').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"HR9KHW-e0pY","caption":"introduction to plant Growth regulators"},
{"src":"https://youtu.be/a_D1UVP7eSY","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=a_D1UVP7eSY').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"a_D1UVP7eSY","caption":"Respiratory system in humans 1"},
{"src":"https://youtu.be/A8xGmKLm6RY","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=A8xGmKLm6RY').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"A8xGmKLm6RY","caption":"Respiratory system in humans 2"},
{"src":"https://youtu.be/j9Hdl9w-K0M","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=j9Hdl9w-K0M').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"j9Hdl9w-K0M","caption":"coagulation of blood"},
{"src":"https://youtu.be/_qmNCJxpsr0","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=_qmNCJxpsr0').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"_qmNCJxpsr0","caption":"human circulatory system"},
{"src":"https://youtu.be/SwHjwO7BnsI","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=SwHjwO7BnsI').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"SwHjwO7BnsI","caption":"cardiac cycle"},
{"src":"https://youtu.be/0yXMGQaVVXg","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=0yXMGQaVVXg').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"0yXMGQaVVXg","caption":"Nervous system in humans"},
{"src":"https://youtu.be/YxSrahHzwgw","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=YxSrahHzwgw').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"YxSrahHzwgw","caption":"human endocrine system"},
{"src":"https://youtu.be/-SPRPkLoKp8","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=-SPRPkLoKp8').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"-SPRPkLoKp8","caption":"mechanism of hormone action"},
{"src":"https://youtu.be/zSCR2K81IRo","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=zSCR2K81IRo').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"zSCR2K81IRo","caption":"Crop production"},
{"src":"https://youtu.be/tCrgTV20BD4","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=tCrgTV20BD4').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"tCrgTV20BD4","caption":"nitrogen fixation"},
{"src":"https://youtu.be/7KkX78c8fRo","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=7KkX78c8fRo').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"7KkX78c8fRo","caption":"Nitrogen cycle"},
{"src":"https://youtu.be/JZjzQhFG6Ec","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=JZjzQhFG6Ec').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"JZjzQhFG6Ec","caption":"Micro organisms – useful and harmful."},
{"src":"https://youtu.be/2XdeLpm42i8","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=2XdeLpm42i8').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"2XdeLpm42i8","caption":"endangered species"},
{"src":"https://youtu.be/kCpQQy-G0i4","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=kCpQQy-G0i4').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"kCpQQy-G0i4","caption":"endemic species"},
{"src":"https://youtu.be/jLzprmI1l20","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=jLzprmI1l20').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"jLzprmI1l20","caption":"migration"},
{"src":"https://youtu.be/-01T9e6VDWU","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=-01T9e6VDWU').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"-01T9e6VDWU","caption":"Consequences of deforestation"},
{"src":"https://youtu.be/-EhvWXKSggo","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=-EhvWXKSggo').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"-EhvWXKSggo","caption":"Reforestation"},
{"src":"https://youtu.be/sAKyhfxxr7s","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=sAKyhfxxr7s').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"sAKyhfxxr7s","caption":" air pollution"},
{"src":"https://youtu.be/93BqLewm3bA","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=93BqLewm3bA').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"93BqLewm3bA","caption":"water pollution"},
{"src":"https://youtu.be/Hnfdq2htoKU","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=Hnfdq2htoKU').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"Hnfdq2htoKU","caption":"soil polluition"},
{"src":"https://youtu.be/8919Zm8Gi4U","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=8919Zm8Gi4U').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"8919Zm8Gi4U","caption":"Infectious and Non-infectious diseases"},
{"src":"https://youtu.be/putoeGrNVg8","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=putoeGrNVg8').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"putoeGrNVg8","caption":"Holes in ozone layer"},
{"src":"https://youtu.be/ncORPosDrjI","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=ncORPosDrjI').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"ncORPosDrjI","caption":"Water Cycle"},
{"src":"https://youtu.be/pGWJ9GgDNhM","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=pGWJ9GgDNhM').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"pGWJ9GgDNhM","caption":"Oxygen Cycle"},
{"src":"https://youtu.be/KNLUzqW8IuA","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=KNLUzqW8IuA').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"KNLUzqW8IuA","caption":"Carbon Cycle"},
{"src":"https://youtu.be/K5EOZenSSB8","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=K5EOZenSSB8').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"K5EOZenSSB8","caption":"Nitrogen Cycle"},
{"src":"https://youtu.be/a-vhqQTLpw4","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=a-vhqQTLpw4').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"a-vhqQTLpw4","caption":"Basic concept of nutrition in plants"},
{"src":"https://youtu.be/rvrao5V89nE","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=rvrao5V89nE').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"rvrao5V89nE","caption":"respiration in plants"},
{"src":"https://youtu.be/JTlUip0-boQ","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=JTlUip0-boQ').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"JTlUip0-boQ","caption":"respiration in animals"},
{"src":"https://youtu.be/MPT1ope-NFU","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=MPT1ope-NFU').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"MPT1ope-NFU","caption":"excretion in animals"},
{"src":"https://youtu.be/J0hjnC14uMM","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=J0hjnC14uMM').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"J0hjnC14uMM","caption":"excretion in plants"},
{"src":"https://youtu.be/HP21hIVJhWI","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=HP21hIVJhWI').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"HP21hIVJhWI","caption":"Reproduction in plants"},
{"src":"https://youtu.be/4h4tdQxgWP0","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=4h4tdQxgWP0').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"4h4tdQxgWP0","caption":"Reproduction in animals"},
{"src":"https://youtu.be/FXXAF_B7vN0","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=FXXAF_B7vN0').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"FXXAF_B7vN0","caption":"reproductive health- need"},
{"src":"https://youtu.be/ng22Ucr33aw&ab","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=ng22Ucr33aw&ab').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"ng22Ucr33aw&ab","caption":"Safe sex vs HIV/AIDS"},
{"src":"https://youtu.be/x0ksaQhAl-g&ab","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=x0ksaQhAl-g&ab').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"x0ksaQhAl-g&ab","caption":"Mendel’s contribution- Laws for inheritance of traits"},
{"src":"https://youtu.be/FAEEuhZR7vg&ab","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=FAEEuhZR7vg&ab').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"FAEEuhZR7vg&ab","caption":"Eco-system"},
{"src":"https://youtu.be/hn7W_C2nbwE","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=hn7W_C2nbwE').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"hn7W_C2nbwE","caption":"Ozone depletion"},
{"src":"https://youtu.be/YeVLBkypPRU","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=YeVLBkypPRU').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"YeVLBkypPRU","caption":"Biodegradable and non-biodegradable substances"},
{"src":"https://youtu.be/ADpxUQMCSng","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=ADpxUQMCSng').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"ADpxUQMCSng","caption":"Inverse Trigonometric Functions"},
{"src":"https://youtu.be/F37rd-BpVOo","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=F37rd-BpVOo').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"F37rd-BpVOo","caption":"Basics of Matrices"},
{"src":"https://youtu.be/g_Rz94DXvNo","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=g_Rz94DXvNo').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"g_Rz94DXvNo","caption":"Transpose of a Matrix"},
{"src":"https://youtu.be/AMLUikdDQGk","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=AMLUikdDQGk').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"AMLUikdDQGk","caption":"Inverse of a Matrix"},
{"src":"https://youtu.be/YFGTpSkfT40","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=YFGTpSkfT40').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"YFGTpSkfT40","caption":"Determinant of a Matrix"},
{"src":"https://youtu.be/wrIAFfZBoBM","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=wrIAFfZBoBM').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"wrIAFfZBoBM","caption":"Determinant of a Matrix"},
{"src":"https://youtu.be/lhgFVZOgleg","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=lhgFVZOgleg').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"lhgFVZOgleg","caption":"Determinant of a Matrix"},
{"src":"https://youtu.be/rowWM-MijXU","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=rowWM-MijXU').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"rowWM-MijXU","caption":"The application of Determinants"},
{"src":"https://youtu.be/HYWeEx21WWw","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=HYWeEx21WWw').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"HYWeEx21WWw","caption":"Adjoint of a Matrix"},
{"src":"https://youtu.be/AMLUikdDQGk","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=AMLUikdDQGk').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"AMLUikdDQGk","caption":"Inverse of a matrix"},
{"src":"https://youtu.be/bPLyCH1WaEY","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=bPLyCH1WaEY').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"bPLyCH1WaEY","caption":"Rules of Logarithm"},
{"src":"https://youtu.be/ifbaAqfqpc4","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=ifbaAqfqpc4').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"ifbaAqfqpc4","caption":"Differential Equation"},
{"src":"https://youtu.be/W55qMEF1a_E","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=W55qMEF1a_E').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"W55qMEF1a_E","caption":"Contact & Non-Contact force"},
{"src":"https://youtu.be/-n1pzh81KA8","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=-n1pzh81KA8').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"-n1pzh81KA8","caption":"Tangents to a Circle"},
{"src":"https://youtu.be/odBiKFAdEXc","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=odBiKFAdEXc').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"odBiKFAdEXc","caption":"Integration"},
{"src":"https://youtu.be/cRWxioj-aVs","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=cRWxioj-aVs').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"cRWxioj-aVs","caption":"Integration of a Function"},
{"src":"https://youtu.be/IoMdxDIJgVc","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=IoMdxDIJgVc').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"IoMdxDIJgVc","caption":"Integral of a Function"},
{"src":"https://youtu.be/W1uKClY3sN4","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=W1uKClY3sN4').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"W1uKClY3sN4","caption":"Differentiation and Integration"},
{"src":"https://youtu.be/fNk_zzaMoSs","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=fNk_zzaMoSs').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"fNk_zzaMoSs","caption":"Vectors"},
{"src":"https://youtu.be/QS3hyhUqHOQ","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=QS3hyhUqHOQ').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"QS3hyhUqHOQ","caption":"Types of Vectors"},
{"src":"https://youtu.be/VFRW0f0XUU8","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=VFRW0f0XUU8').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"VFRW0f0XUU8","caption":"Operation of Vectors"},
{"src":"https://youtu.be/jB7Ro3w77MM","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=jB7Ro3w77MM').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"jB7Ro3w77MM","caption":"Coplanar Points and Lines"},
{"src":"https://youtu.be/j1kZY25ZOLc","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=j1kZY25ZOLc').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"j1kZY25ZOLc","caption":"Parallel,Intersecting and Skew Lines"},
{"src":"https://youtu.be/Uo6aRV-mbeg","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=Uo6aRV-mbeg').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"Uo6aRV-mbeg","caption":"Linear Programming"},
{"src":"https://youtu.be/qQFAvPF2OSI","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=qQFAvPF2OSI').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"qQFAvPF2OSI","caption":"Linear Programming and Graphical Solution"},
{"src":"https://youtu.be/BQrQBnsKUOU","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=BQrQBnsKUOU').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"BQrQBnsKUOU","caption":"Probability"},
{"src":"https://youtu.be/FdK9_Fp76cw","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=FdK9_Fp76cw').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"FdK9_Fp76cw","caption":"Functions,Domain and Range"},
{"src":"https://youtu.be/hqr1DtXXHpY","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=hqr1DtXXHpY').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"hqr1DtXXHpY","caption":"Complex Numbers"},
{"src":"https://youtu.be/BHgewRcuoRM","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=BHgewRcuoRM').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"BHgewRcuoRM","caption":"Graphs of Quadratic Equations"},
{"src":"https://youtu.be/gua96ju_FBk","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=gua96ju_FBk').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"gua96ju_FBk","caption":"Arithmetic Progression"},
{"src":"https://youtu.be/3YwrcJxEbZw","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=3YwrcJxEbZw').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"3YwrcJxEbZw","caption":"Contact & Non-Contact force"},
{"src":"https://youtu.be/nO12O2bvDDw","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=nO12O2bvDDw').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"nO12O2bvDDw","caption":"Rationalising Factor"},
{"src":"https://youtu.be/ffLLmV4mZwU","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=ffLLmV4mZwU').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"ffLLmV4mZwU","caption":"Polynomials"},
{"src":"https://youtu.be/W55qMEF1a_E","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=W55qMEF1a_E').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"W55qMEF1a_E","caption":"Contact & Non-Contact force"},
{"src":"https://youtu.be/MPcZ3nhZO-M","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=MPcZ3nhZO-M').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"MPcZ3nhZO-M","caption":"Degree of a polynomial"},
{"src":"https://youtu.be/4YXE7HDyInM","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=4YXE7HDyInM').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"4YXE7HDyInM","caption":"Factor theorem"},
{"src":"https://youtu.be/W55qMEF1a_E","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=W55qMEF1a_E').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"W55qMEF1a_E","caption":"Contact & Non-Contact force"},
{"src":"https://youtu.be/lDOYdBgtnjY","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=lDOYdBgtnjY').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"lDOYdBgtnjY","caption":"Linear Equation"},
{"src":"https://youtu.be/9Uc62CuQjc4","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=9Uc62CuQjc4').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"9Uc62CuQjc4","caption":"Graphing on the co-ordinate plane"},
{"src":"https://youtu.be/LqrHvGAaNDI","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=LqrHvGAaNDI').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"LqrHvGAaNDI","caption":"Contact & Non-Contact force"},
{"src":"https://youtu.be/DGKwdHMiqCg","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=DGKwdHMiqCg').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"DGKwdHMiqCg","caption":"Angle basics"},
{"src":"https://youtu.be/mLeNaZcy-hE","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=mLeNaZcy-hE').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"mLeNaZcy-hE","caption":"Triangles"},
{"src":"https://youtu.be/yiREqzDsMP8","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=yiREqzDsMP8').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"yiREqzDsMP8","caption":"Quadrilaterals"},
{"src":"https://youtu.be/Y5Z69g7A0eI","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=Y5Z69g7A0eI').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"Y5Z69g7A0eI","caption":"Circles"},
{"src":"https://youtu.be/lhfCv0kQhb4","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=lhfCv0kQhb4').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"lhfCv0kQhb4","caption":"Constructions"},
{"src":"https://youtu.be/gBbHym3y_NM","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=gBbHym3y_NM').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"gBbHym3y_NM","caption":"Area of triangle using h=Herons Formula"},
{"src":"https://youtu.be/eBAq_caikJ4","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=eBAq_caikJ4').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"eBAq_caikJ4","caption":"Surface Area and Volume"},
{"src":"https://youtu.be/tB41caiQ4jI","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=tB41caiQ4jI').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"tB41caiQ4jI","caption":"Statistics"},
{"src":"https://youtu.be/lBHd7FgO-JU","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=lBHd7FgO-JU').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"lBHd7FgO-JU","caption":"Presentation of Data"},
{"src":"https://youtu.be/KzfWUEJjG18","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=KzfWUEJjG18').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"KzfWUEJjG18","caption":"Probability"},
{"src":"https://youtu.be/T9lt6MZKLck","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=T9lt6MZKLck').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"T9lt6MZKLck","caption":"Trignometry"},
{"src":"https://youtu.be/T7D1W1oD8wo","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=T7D1W1oD8w').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"T7D1W1oD8w","caption":"Super Hexagon for Trignometric identities"},
{"src":"https://youtu.be/BGXgcvGPRLI","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=BGXgcvGPRLI').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"BGXgcvGPRLI","caption":"Heights and distances"},
{"src":"https://youtu.be/6DYtC7lrVuY","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=6DYtC7lrVuY').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"6DYtC7lrVuY","caption":"Mean,Median and Mode"},
{"src":"https://youtu.be/fy-bd5AC-Ms","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=fy-bd5AC-Ms').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"fy-bd5AC-Ms","caption":"3 Different English accents"},
{"src":"https://youtu.be/i5mYphUoOCs","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=i5mYphUoOCs').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"i5mYphUoOCs","caption":"Public Speaking for beginners"},
{"src":"https://youtu.be/KJS77wXttpI","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=KJS77wXttpI').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"KJS77wXttpI","caption":"Learn idioms with TV series -part 1"},
{"src":"https://youtu.be/DAj-c4DuVu8","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=DAj-c4DuVu8').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"DAj-c4DuVu8","caption":"15 classical books everyone should read"},
{"src":"https://youtu.be/dRrvQ1vZxcg","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=dRrvQ1vZxcg').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"dRrvQ1vZxcg","caption":"Shakespears Romeo and Juliet - Summary "},
{"src":"https://youtu.be/KxhUTAi7UTQ","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=KxhUTAi7UTQ').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"KxhUTAi7UTQ","caption":"The count of Monte Cristo - Summary"},
{"src":"https://youtu.be/4hzzamfIqDs","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=4hzzamfIqDs').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"4hzzamfIqDs","caption":"Les Miserables- Summary"},
{"src":"https://youtu.be/kM7PfJFSI1s","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=kM7PfJFSI1s').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"kM7PfJFSI1s","caption":"The Three Musketeers- Summary"},
{"src":"https://youtu.be/g4nDN0W7u20","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=g4nDN0W7u20').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"g4nDN0W7u20","caption":"Daffodils - A poem by William Wordsworth"},
{"src":"https://youtu.be/qtMAsek_0UQ","thumbnail":youtubeThumbnail('https://www.youtube.com/watch?v=qtMAsek_0UQ').default.url,"thumbnailWidth":320,"thumbnailHeight":174,"isSelected":false,"videoId":"qtMAsek_0UQ","caption":"Learn idioms with TV series -part 2"}]
};

export default VideoLibrary;
