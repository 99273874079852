import React from "react";
import { Container, Row, Col } from "shards-react";
import { Store } from "../flux";
import Modal from "react-modal";
import {
  chunkUpload,
  getStudentList,
  getTeacherList,
  newCourse,
  passGen
} from "../services/api";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  FormInput,
  Button
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { Form } from "shards-react";
import MultiSelect from "react-multi-select-component";


class AddCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStudents: [],
      selectedTeachers: [],
      teachers: [],
      students: [],
      course: {
        ShortCode: "",
        Name: "",
        School: "",
        Image:
          "https://www.inspirelearning.in/placeholder.jpg",
        Live: ""
      },
      currentUser: Store.getCurrentUser(),
      showModal: false,
      buttonTheme: "primary",
      buttonText: "Publish"
    };
    this.setParentState = this.setParentState.bind(this);
  }
  config = {
    onUploadProgress: progressEvent => {
      this.progressSum+=progressEvent.loaded;
      let progress = (this.progressSum / this.state.fileSize) * 100;
      if(parseInt(progress) >=100){
        this.progressSum = 0;
        progress = 100;
      }
      this.setState({...this.state,buttonText:`Uploading ${parseInt(progress)}%`})
    }
  }
  progressSum = 0;
  componentDidMount() {
    getStudentList(this.state.currentUser).then(data => {
      this.setState({ ...this.state, students: data });
    });
    getTeacherList(this.state.currentUser).then(data => {
      this.setState({ ...this.state, teachers: data });
    });
  }
  setParentState(state) {
  
    this.setState({ ...this.state, user: state });
  }
  render() {
    let userType = "";

    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Course Detail"
            subtitle="Overview"
            md="12"
            className="ml-sm-auto mr-sm-auto"
          />
        </Row>
        <Row>
          <Col lg="4">
            <Card small className="mb-4 pt-3">
              <CardHeader className="border-bottom text-center">
                <div className="mb-3 mx-auto">
                  <img
                    className='step-course-image'
                    src={this.state.course.Image}
                    alt={``}
                    style={{ borderRadius: "6px" }}
                    width="100%"
                    onClick={() => {
                      document.getElementById("profilePic").click();
                    }}
                  />
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={e => {
                    
                      chunkUpload(e,this.config,this.state.currentUser)
                        .then(data => {
                          this.setState({
                            ...this.state,
                            course: { ...this.state.course, Image: data }
                          });
                        })
                        .catch((err) => {
                          if(err=="You have exceeded your storage quota. Please Upgrade."){
                            alert(err);
                            this.setState({ ...this.state, showModal: false });
                          }
                        });
                    }}
                    className="custom-file-input"
                    id="profilePic"
                  />
                </div>
                <h4 className="mb-0">{`${this.state.course.Name}`}</h4>
                <span className="text-muted d-block mb-2">{userType}</span>
                {/* <Button pill outline size="sm" className="mb-2">
        <i className="material-icons mr-1">person_add</i> Follow
      </Button> */}
              </CardHeader>
            </Card>
          </Col>
          <Col lg="8">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">{`Course Details`}</h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form>
                        <Row form>
                          {/* First Name */}
                          <Col md="6" className="form-group">
                            <label htmlFor="feFirstName" className='step-course-name'>Name</label>
                            <FormInput
                              id="feFirstName"
                              placeholder="Course Name"
                              value={this.state.course.Name}
                              onChange={e => {
                               
                                this.setState({
                                  ...this.state,
                                  course: {
                                    ...this.state.course,
                                    Name: e.target.value,
                                    ShortCode: passGen(6)
                                  }
                                });
                              }}
                            />
                          </Col>
                          {/* Last Name */}
                          <Col md="6" className="form-group">
                            <label htmlFor="feLastName" class='step-course-code'>Short Code</label>
                            <FormInput
                              id="feLastName"
                              placeholder="Short Code"
                              value={this.state.course.ShortCode}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="12" className="form-group">
                            <label htmlFor="feLastName" className='step-course-live'>Live</label>
                            <FormInput
                              id="feLastName"
                              placeholder="Live Class Link for the Course"
                              value={this.state.course.Live}
                              onChange={(e)=>{this.setState({...this.state,course :{...this.state.course, Live: e.target.value}})}}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          {/* Email */}
                          <Col md="6" className="form-group">
                            <label htmlFor="feEmail" className='step-course-students'>Students</label>
                            <MultiSelect
                              options={this.state.students}
                              value={this.state.selectedStudents}
                              onChange={x => {
                                this.setState({
                                  ...this.state,
                                  selectedStudents: x
                                });
                              }}
                              labelledBy="Select"
                            />
                          </Col>
                          {/* Password */}
                          <Col md="6" className="form-group">
                            <label htmlFor="fePassword" className='step-course-teachers'>Teachers</label>
                            <MultiSelect
                              options={this.state.teachers}
                              value={this.state.selectedTeachers}
                              onChange={x => {
                                this.setState({
                                  ...this.state,
                                  selectedTeachers: x
                                });
                              }}
                              labelledBy="Select"
                            />
                          </Col>
                        </Row>
                        <Row form></Row>
                        <Button
                          onClick={() => {
                            let data = {
                              Name: this.state.course.Name,
                              ShortCode: this.state.course.ShortCode,
                              Image: this.state.course.Image,
                              Live: this.state.course.Live,
                              School: this.state.currentUser.Institution,
                              Students: this.state.students,
                              Teachers: this.state.teachers
                            };
                            newCourse(data, this.state.currentUser).then(
                              res => {
                               
                                this.props.nav();
                              }
                            ).catch(()=>{});
                          }}
                          theme="accent"
                          className = 'step-create-course'
                        >
                          Add Course
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.showModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick={true}
          className="library_modal"
          overlayClassName="library_overlay"
          ariaHideApp={false}
        >
          <Card small>
            {/* Files & Dropdowns */}
            <CardHeader className="border-bottom">
              <h6 className="m-0">Upload an Ebook</h6>
            </CardHeader>

            <ListGroup flush>
              <ListGroupItem className="px-3">
                <strong className="text-muted d-block mb-2">Name</strong>
                <div className="custom-file mb-3">
                  <FormInput
                    onChange={e => {
                      this.setState({
                        ...this.state,
                        fileName: e.target.value
                      });
                    }}
                  />
                </div>
                <strong className="text-muted d-block mb-2">Upload File</strong>
                <div className="custom-file mb-3">
                  <input
                    type="file"
                    onChange={e => {
                      this.setState({
                        ...this.state,
                        buttonTheme: "warning",
                        buttonText: "Uploading"
                      });
                      chunkUpload(e,this.config,this.state.currentUser)
                        .then(data => {
                          this.setState({
                            ...this.state,
                            user: { ...this.state.user, pic: data },
                            buttonTheme: "success",
                            buttonText: "Publish"
                          });
                        })
                        .catch((err) => {
                          if(err=="You have exceeded your storage quota. Please Upgrade."){
                            alert(err);
                            this.setState({ ...this.state, showModal: false });
                          }
                        });
                    }}
                    className="custom-file-input"
                    id="customFile2"
                  />
                  <label className="custom-file-label" htmlFor="customFile2">
                    Choose file...
                  </label>
                </div>
                <div className="custom-file mb-3">
                  <Button
                    disabled={this.state.buttonText !== "Publish"}
                    theme={this.state.buttonTheme}
                    onClick={() => {}}
                  >
                    {this.state.buttonText}
                  </Button>
                </div>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Modal>
      </Container>
    );
  }
}

export default AddCourse;
