import React from "react";
import { Redirect } from "react-router-dom";
import { DefaultLayout } from "./layouts";
import Home from "./views/Home";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import Login from "./views/Login";
import VideoLibrary from "./views/VideoLibrary";
import Library from "./views/Library";
import Planner from "./views/Planner";
import Videos from "./views/Videos";
import CourseLibrary from "./views/CourseLibrary";
import Tasks from "./views/Tasks";
import MCQ from "./views/MCQ";
import CreateQuiz from "./views/CreateQuiz";
import QuizGallery from "./views/QuizGallery";
import Lesson from "./views/Lesson";
import EditLesson from "./views/EditLesson";
import Course from "./views/Course";
import Assignments from "./views/Assignments";
import AssignmentSubmissions from "./views/AssignmentSubmissions";
import ManageStudents from "./views/ManageStudents";
import ManageTeachers from "./views/ManageTeachers";
import ManageCourses from "./views/ManageCourses";
import Dashboard from "./views/Dashboard";
import Manage from "./views/Manage";
import Account from "./views/Account";
import AdminManage from "./views/AdminManage";
import Pricing from "./views/Pricing";
import Upgrade from "./views/Upgrade";
import Branding from "./views/Branding";
import CustomMeeting from "./CustomMeeting";
import BulletBoard from "./views/NoticeBoard";
import LandingPage from "./views/LandingPage";
import Exam from "./components/Exam";
import AttendExam from "./components/AttendExam";
import About from "./views/About";
import Mission from "./views/Mission";
import Enterprise from "./views/Enterprise";
import Cancellation from "./views/Cancellation";
import Franchise from "./views/Franchise";
import Privacy from "./views/Privacy";
import Signup from "./views/Signup";
import Attendance from "./views/Attendance";
import AttendanceList from "./views/AttendanceList";
import Grades from "./views/Grades";
import MeetingEnd from "./views/MeetingEnd";
import Choose from "./views/Choose";
import EditPage from "./views/EditPage";
import PricingDetail from "./components/PricingDetail";
import AutoAttendance from "./views/AutoAttendance";

export default [
  {
    path: "/",
    exact: true,
    layout: props => (
      <DefaultLayout {...props} noNavbar={true} noFooter={true} />
    ),
    component: LandingPage
  },
  {
    path: "/choose",
    layout: DefaultLayout,
    component: Choose
  },
  {
    path: "/managestudents",
    layout: DefaultLayout,
    component: ManageStudents
  },
  {
    path: "/about",
    layout: props => (
      <DefaultLayout {...props} noNavbar={true} noFooter={true} />
    ),
    component: About
  },
  {
    path: "/mission",
    layout: props => (
      <DefaultLayout {...props} noNavbar={true} noFooter={true} />
    ),
    component: Mission
  },
  {
    path: "/enterprise",
    layout: props => (
      <DefaultLayout {...props} noNavbar={true} noFooter={true} />
    ),
    component: Enterprise
  },
  {
    path: "/cancellation",
    layout: props => (
      <DefaultLayout {...props} noNavbar={true} noFooter={true} />
    ),
    component: Cancellation
  },
  {
    path: "/privacy",
    layout: props => (
      <DefaultLayout {...props} noNavbar={true} noFooter={true} />
    ),
    component: Privacy
  },
  {
    path: "/franchise",
    layout: props => (
      <DefaultLayout {...props} noNavbar={true} noFooter={true} />
    ),
    component: Franchise
  },
  {
    path: "/exam/:id",
    layout: DefaultLayout,
    component: Exam
  },
  {
    path: "/attendexam",
    layout: props => (
      <DefaultLayout {...props} noNavbar={true} noFooter={true} />
    ),
    component: AttendExam
  },
  {
    path: "/meetingend",
    layout: props => (
      <DefaultLayout {...props} noNavbar={true} noFooter={true} />
    ),
    component: MeetingEnd
  },
  {
    path: "/manageteachers",
    layout: DefaultLayout,
    component: ManageTeachers
  },
  {
    path: "/managecourses",
    layout: DefaultLayout,
    component: ManageCourses
  },
  {
    path: "/home",
    layout: DefaultLayout,
    component: Home
  },
  {
    path: "/attendance/:id",
    layout: DefaultLayout,
    component: Attendance
  },
  {
    path: "/attendancelist/:id",
    layout: DefaultLayout,
    component: AttendanceList
  },
  {
    path: "/grades/:id",
    layout: DefaultLayout,
    component: Grades
  },
  {
    path: "/userprofile",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    layout: props => (
      <DefaultLayout {...props} noNavbar={true} noFooter={true} />
    ),
    component: Errors
  },
  {
    path: "/account",
    layout: DefaultLayout,
    component: Account
  },
  {
    path: "/login",
    layout: props => (
      <DefaultLayout {...props} noNavbar={true} noFooter={true} />
    ),
    component: Login
  },
  {
    path: "/signup",
    layout: props => (
      <DefaultLayout {...props} noNavbar={true} noFooter={true} />
    ),
    component: Signup
  },
  // {
  //   path: "/call",
  //   layout: props => (
  //     <DefaultLayout {...props} noNavbar={true} noFooter={true} />
  //   ),
  //   component: Call
  // }
  // ,
  {
    path: "/video-library",
    layout: DefaultLayout,
    component: VideoLibrary
  },
  {
    path: "/library",
    layout: DefaultLayout,
    component: Library
  },
  {
    path: "/planner",
    layout: DefaultLayout,
    component: Planner
  },
  {
    path: "/videos/:id",
    layout: DefaultLayout,
    component: Videos
  },
  {
    path: "/adminmanage/:id",
    layout: DefaultLayout,
    component: AdminManage
  },
  {
    path: "/course_library/:id",
    layout: DefaultLayout,
    component: CourseLibrary
  },
  {
    path: "/manage",
    layout: DefaultLayout,
    component: Manage
  },
  {
    path: "/tasks",
    layout: DefaultLayout,
    component: Tasks
  },
  {
    path: "/quiz",
    layout: DefaultLayout,
    component: MCQ
  },
  {
    path: "/questions/:id",
    layout: DefaultLayout,
    component: CreateQuiz
  },
  {
    path: "/quizgallery/:id",
    layout: DefaultLayout,
    component: QuizGallery
  },
  {
    path: "/lesson/:id",
    layout: DefaultLayout,
    component: Lesson
  },
  {
    path: "/editlesson/:id",
    layout: DefaultLayout,
    component: EditLesson
  },
  {
    path: "/addlesson",
    layout: DefaultLayout,
    component: EditLesson
  },
  {
    path: "/noticeboard",
    layout: DefaultLayout,
    component: BulletBoard
  },
  {
    path: "/course/:id",
    layout: DefaultLayout,
    component: Course
  },
  {
    path: "/assignments/:id",
    layout: DefaultLayout,
    component: Assignments
  },
  {
    path: "/assignmentsubmissions",
    layout: DefaultLayout,
    component: AssignmentSubmissions
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard
  },
  {
    path: "/autoattendance",
    layout: DefaultLayout,
    component: AutoAttendance
  },
  {
    path: "/pricing",
    layout: props => (
      <DefaultLayout {...props} noNavbar={true} noFooter={true} />
    ),
    component: Pricing
  },
  {
    path: "/upgrade",
    layout: props => (
      <DefaultLayout {...props} noNavbar={true} noFooter={true} />
    ),
    component: Upgrade
  },
  {
    path: "/editwebsite/:id",
    layout: props => (
      <DefaultLayout {...props} noNavbar={true} noFooter={true} />
    ),
    component: EditPage
  },
  {
    path: "/pricingdetail",
    layout: props => (
      <DefaultLayout {...props} noNavbar={true} noFooter={true} />
    ),
    component: PricingDetail
  },
  {
    path: "/branding",
    layout: DefaultLayout,
    component: Branding
  },
  {
    path: "/mymeeting",
    layout: props => (
      <DefaultLayout {...props} noNavbar={true} noFooter={true} />
    ),
    component: CustomMeeting
  }
];
