import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { Store } from "../flux";

const chunkSize = 1048576 * 50;

const apiCache = {};

function getChunkCount(data){
 
  let totalCount =
      data.size % chunkSize == 0
        ? data.size / chunkSize
        : Math.floor(data.size / chunkSize) + 1;

  return totalCount; 
}
function getChunkSize(){

  return chunkSize; 
}

function getLibrary(currentUser) {
  const promise = axios.get(
    "https://lesson21functions20220912192803.azurewebsites.net/api/Library",
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => {
    return response.data.map(x =>
      Object.assign(x, { thumbnailWidth: 320, thumbnailHeight: 320 })
    );
  });

  return dataPromise;
}

function upload(file) {
  let formData = new FormData();
  formData.append("avatar", file);

  const promise = axios.post(
    `https://www.lesson21.com/app/upload/upload.php`,
    formData,
    {
      headers: {
        "Content-type": "multipart/form-data"
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}

async function uploadFile(file,currentUser){
  let url = "";
 await getStorageSize(currentUser).then((x)=>{
      let size = parseInt(x)+file.size;
      let estimate = size/1024/1024/1024;
      let plan = Store.getCurrentPlan();
          if(estimate>=parseInt(plan.UploadLimit)){
           url = "You have exceeded your storage quota. Please Upgrade."
          }
    
    }).catch(()=>{});
  
    if(url=="You have exceeded your storage quota. Please Upgrade."){
      return Promise.reject(url);
    }
  
    let headers = { headers: {
      "x-functions-key": currentUser.token
    }}
  
  
   let config = headers;
   
    
    let filename = file.name;
    let count = getChunkCount(file);
   let bloblist = [];
  
  
    let chunkSize = getChunkSize();
    
    for(var i=0;i<count;i++){
    let start = i*chunkSize;
    let end = start+chunkSize; 
    let chunk = file.slice(start,end)
    let blockid = uuidv4();
    
    await uploadChunk(filename,blockid,chunk,config)
    .then((value)=>{
  bloblist.push(value.data.split('$')[0]);
    }).catch(()=>{}) 
  }
  
  const promise = await axios.post(
   `https://lesson21functions20220912192803.azurewebsites.net/api/CompleteUpload`,
             {FileName: filename,bloblist},{ headers: {
              "x-functions-key": currentUser.token
            }}
           ).then((x)=>{url = x.data});
  
   return Promise.resolve(url);

}

function formDataUpload(data) {
  const promise = axios.post(
    `https://www.lesson21.com/app/upload/upload.php`,
    data,
    {
      headers: {
        "Content-type": "multipart/form-data"
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}

function uploadBook(src, caption,currentUser) {
  const promise = axios.post(
    "https://lesson21functions20220912192803.azurewebsites.net/api/UploadToLibrary",{src,caption},
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );
  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}

function login(data) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/Login`,
    data
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function GetCourseList(currentUser) {
  if(!apiCache[`GetCourseList_${currentUser.UserName}`]){
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/GetCourseList`,
    {
      Institution: currentUser.Institution,
      Enrollments: currentUser.Enrollments
    },
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);
  apiCache[`GetCourseList_${currentUser.UserName}`] = dataPromise;
  return dataPromise;
  } else {
   return apiCache[`GetCourseList_${currentUser.UserName}`];
  }
}
function uploadVideo(src, caption, course, currentUser) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/CreateVideo`,
    { src, caption, ShortCode: course },
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function getVideo(id, currentUser) {
  const promise = axios.get(
    `https://lesson21functions20220912192803.azurewebsites.net/api/GetVideoList/${id}`,
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => {
    return response.data.map(x =>
      Object.assign(x, { thumbnailWidth: 320, thumbnailHeight: 320 })
    );
  });

  return dataPromise;
}
function deleteVideo(id, currentUser,link) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/DeleteVideo`,{id,link},
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function uploadCourseBook(src, caption, course, currentUser) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/CreateResourse`,
    { src, caption, ShortCode: course },
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function getCourseBook(id, currentUser) {
  const promise = axios.get(
    `https://lesson21functions20220912192803.azurewebsites.net/api/GetResourseList/${id}`,
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => {
    return response.data.map(x =>
      Object.assign(x, { thumbnailWidth: 320, thumbnailHeight: 320 })
    );
  });

  return dataPromise;
}

function getTasks(id, currentUser) {
  const promise = axios.get(
    `https://lesson21functions20220912192803.azurewebsites.net/api/GetTasks/${id}`,
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}

function addTask(id, data, currentUser) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/AddTask`,
    { id, Content: data },
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}

function getEvents(id, currentUser) {
  const promise = axios.get(
    `https://lesson21functions20220912192803.azurewebsites.net/api/GetEvents/${id}`,
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}

function addEvent(id, data, currentUser) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/AddEvent`,
    { id, Content: data },
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function updateProfile(id, data, currentUser) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/UpdateProfile`,
    { ...data, id },
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function AdminAddUser(data, currentUser) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/InsertUser`,
    { ...data },
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function addLesson(data, currentUser) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/CreateLesson`,
    data,
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function updateLesson(id, data, currentUser) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/UpdateLesson`,
    { id, Name: data.Name, Content: data.Content },
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}

function uploadAssignment(src, caption, course, due, user, book) {
  let url =
    user.Type != "STUDENT"
      ? "https://lesson21functions20220912192803.azurewebsites.net/api/CreateAssignment"
      : "https://lesson21functions20220912192803.azurewebsites.net/api/SubmitAssignment";
  const promise = axios.post(
    url,
    user.Type != "STUDENT"
      ? { src, caption, course, due }
      : { id: book.id, src, caption, course, due, student: `${user.id}` },
    {
      headers: {
        "x-functions-key": user.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function getAssignment(id, currentUser) {
  const promise = axios.get(
    `https://lesson21functions20220912192803.azurewebsites.net/api/GetAssignmentList/${id}`,
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => {
    return response.data.map(x =>
      Object.assign(x, { thumbnailWidth: 320, thumbnailHeight: 320 })
    );
  });

  return dataPromise;
}
function updateSubmittedAssignment(data, currentUser) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/UpdateSubmittedAssignment`,
    data,
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function deleteAssignment(id, currentUser,link) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/DeleteAssignment`,
    { id , link},
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  return promise;
}
function getAssignmentSubmissions(id, currentUser) {
  const promise = axios.get(
    `https://lesson21functions20220912192803.azurewebsites.net/api/GetSubmittedAssignment/${id}`,
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function getAttendance(id, currentUser) {
  const promise = axios.get(
    `https://lesson21functions20220912192803.azurewebsites.net/api/getattendance/${id}`,
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function postAttendance(data, currentUser) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/postattendance`,
    data,
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function getStudentDetails(institution, currentUser) {
  let url = `https://lesson21functions20220912192803.azurewebsites.net/api/GetUserDetails/${institution}/STUDENT`;
  const promise = axios.get(url, {
    headers: {
      "x-functions-key": currentUser.token
    }
  });

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function getCourseDetails(institution, currentUser) {
  let url = `https://lesson21functions20220912192803.azurewebsites.net/api/GetCourseList`;
  const promise = axios.post(
    url,
    { Institution: institution, Enrollments: "*" },
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function getLessons(shortCode, currentUser) {
  let url = `https://lesson21functions20220912192803.azurewebsites.net/api/GetLessons/${shortCode}`;
  const promise = axios.get(url, {
    headers: {
      "x-functions-key": currentUser.token
    }
  });

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function getLesson(id, currentUser) {
  let url = `https://lesson21functions20220912192803.azurewebsites.net/api/GetLesson/${id}`;
  const promise = axios.get(url, {
    headers: {
      "x-functions-key": currentUser.token
    }
  });

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function getTeacherDetails(institution, currentUser) {
  let url = `https://lesson21functions20220912192803.azurewebsites.net/api/GetUserDetails/${institution}/TEACHER`;
  const promise = axios.get(url, {
    headers: {
      "x-functions-key": currentUser.token
    }
  });

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function getAdminList(institution, currentUser) {
  let url = `https://lesson21functions20220912192803.azurewebsites.net/api/GetAdminList`;

  if(!apiCache[`GetAdminList_${currentUser.UserName}`]){
  const promise = axios.post(url, {Institution:institution},{
    headers: {
      "x-functions-key": currentUser.token
    }
  });

  const dataPromise = promise.then(response => response.data);
  apiCache[`GetAdminList_${currentUser.UserName}`] = dataPromise;
  return dataPromise;
} else {
  return apiCache[`GetAdminList_${currentUser.UserName}`];
}
}
function deleteLesson(id, currentUser) {
  let url = `https://lesson21functions20220912192803.azurewebsites.net/api/DeleteLesson`;
  const promise = axios.post(
    url,
    { id },
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function getQuiz(id, currentUser) {
  const promise = axios.get(
    `https://lesson21functions20220912192803.azurewebsites.net/api/GetQuizList/${id}`,
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function deleteQuiz(id, currentUser) {
  const promise = axios.get(
    `https://lesson21functions20220912192803.azurewebsites.net/api/DeleteQuiz/${id}`,
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function addQuiz(data, currentUser) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/AddQuiz`,
    data,
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function deleteResource(id, currentUser,link) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/DeleteResourse`,
    {id, link},
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}

function deleteUser(id, currentUser) {
  const promise = axios.get(
    `https://lesson21functions20220912192803.azurewebsites.net/api/DeleteUser/${id}`,
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}

function getStudentList(currentUser) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/GetStudentList`,
    { Institution: currentUser.Institution },
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function getTeacherList(currentUser) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/GetTeacherList`,
    { Institution: currentUser.Institution },
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}

function getSelectedStudentList(shortCode,currentUser) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/GetSelectedStudentList`,
    { Institution: currentUser.Institution , ShortCode: shortCode},
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function getSelectedTeacherList(shortCode,currentUser) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/GetSelectedTeacherList`,
    { Institution: currentUser.Institution , ShortCode: shortCode},
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}

function newCourse(data, currentUser) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/NewCourse`,
    data,
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function editCourse(data, currentUser) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/UpdateDashCourse`,
    data,
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function deleteCourse(data, currentUser) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/DeleteCourse`,
    data,
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function getUserCount(Institution, currentUser) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/UserCount`,
    {Institution},
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function inviteUsers(data, currentUser) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/InviteUsers`,
    data,
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}

function download(id,currentUser,type){
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/BulkProcess`,
    {Institution: id, Type : type},
    {
      responseType: 'blob',
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function getPlan(id, currentUser) {
  if(!apiCache[`Plan_${currentUser.UserName}`]){
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/Plan`,
    {Institution: id},
    {
      headers: {
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);
  apiCache[`Plan_${currentUser.UserName}`] = dataPromise;
  return dataPromise;
  } else {
  return apiCache[`Plan_${currentUser.UserName}`];
  }
}
function createPaymentLink(data) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/CreatePaymentLink`,
    data
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function createSubscriptionLink(data) {
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/CreateSubscriptionLink`,
    data
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}

function uploadSheet(id,e, currentUser) {
  let formData = new FormData();
  formData.append('file',  e.target.files[0]);

  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/FileUpload/${id}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        "x-functions-key": currentUser.token
      }
    }
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
async function uploadChunk(filename,blockid,chunk,config){
	
	const promise = axios.post(
      `https://lesson21functions20220912192803.azurewebsites.net/api/ChunkUpload/${filename}/${blockid}`,
      chunk,config
    );
 return promise;
}

async function chunkUpload(e,config,currentUser) {
  
  let url = "";
  let file = e.target.files[0];

  await getStorageSize(currentUser).then((x)=>{
    let size = parseInt(x)+file.size;
    let estimate = size/1024/1024/1024;
    let plan = Store.getCurrentPlan();
        if(estimate>=parseInt(plan.UploadLimit)){
         url = "You have exceeded your storage quota. Please Upgrade."
        }
  
  }).catch(()=>{});

  if(url=="You have exceeded your storage quota. Please Upgrade."){
    return Promise.reject(url);
  }

  let headers = { headers: {
    "x-functions-key": currentUser.token
  }}


 config = Object.assign(config,headers);
 
  
  let filename = file.name;
  let count = getChunkCount(file);
 let bloblist = [];


  let chunkSize = getChunkSize();
  
  for(var i=0;i<count;i++){
  let start = i*chunkSize;
  let end = start+chunkSize; 
  let chunk = file.slice(start,end)
  let blockid = uuidv4();
  
  await uploadChunk(filename,blockid,chunk,config)
  .then((value)=>{
bloblist.push(value.data.split('$')[0]);
  }).catch(()=>{}) 
}

const promise = await axios.post(
 `https://lesson21functions20220912192803.azurewebsites.net/api/CompleteUpload`,
           {FileName: filename,bloblist},{ headers: {
            "x-functions-key": currentUser.token
          }}
         ).then((x)=>{url = x.data});

 return Promise.resolve(url);

  // let idArray = [];
  // for(var i=0;i<count;i++){
  //    let chunkSize = getChunkSize();
  //    let start = i*chunkSize;
  //    let end = start+chunkSize; 
  //    let chunk = e.target.files[0].slice(start,end)
  //    let blockid = uuidv4();
  //    const promise =  await axios.post(
  //     `http://localhost:7071/api/ChunkUpload/${filename}/${blockid}`,
  //     chunk,config
  //   ).then((value)=>{
  //     idArray.push(value.data.split('$')[0]);
      
  //     if(idArray.length == count){
  //       filename = value.data.split('$')[1];
  //       const promise = axios.post(
  //         `http://localhost:7071/api/CompleteUpload`,
  //         {FileName: filename,bloblist:idArray}
  //       ).then((val)=>{ url = val.data})
      
  //     }
  //   }).catch(()=>{

  //   });
  //   }
  //   return url;
}

function signup(data){
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/InitialSignup`,
    data
  );
  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function getAutoAttendance(currentUser){
  const promise = axios.get(
    `https://lesson21functions20220912192803.azurewebsites.net/api/getautoattendance/100`,
    { headers: {
      "x-functions-key": currentUser.token
    }}
  );
  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function postNotice(data, currentUser){
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/NoticeBoard`,
    {Data: data},
    { headers: {
      "x-functions-key": currentUser.token
    }}
  );
  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}

function getNotice(currentUser){
  const promise = axios.get(
    `https://lesson21functions20220912192803.azurewebsites.net/api/NoticeBoard`,
    { headers: {
      "x-functions-key": currentUser.token
    }}
  );
  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}

function checkPayment(email){
  const promise = axios.get(
    `https://lesson21functions20220912192803.azurewebsites.net/api/CheckPayment/${email}`,
  );
  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}

async function getStorageSize(currentUser){
  const promise = axios.get(
    `https://lesson21functions20220912192803.azurewebsites.net/api/StorageUsed`,
    { headers: {
      "x-functions-key": currentUser.token
    }}
  );
  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}

function getLiveLink(id,currentUser)
{
  const promise = axios.get(
    `https://lesson21functions20220912192803.azurewebsites.net/api/GetLiveLink/${id}`,
    { headers: {
      "x-functions-key": currentUser.token
    }}
  );
  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function getMeetingLink(id,currentUser){
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/createmeeting/${id}`,
    {fullName: `${currentUser.FirstName} ${currentUser.LastName}`},
    { headers: {
      "x-functions-key": currentUser.token
    }}
  );
  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function getAssignmentMarks(id,currentUser){
  const promise = axios.get(
    `https://lesson21functions20220912192803.azurewebsites.net/api/getassignmentmarks/${id}`,
    { headers: {
      "x-functions-key": currentUser.token
    }}
  );
  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function talk(data)
{
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/Talk`,
    data
  );
  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function getBranding(id)
{
  const promise = axios.get(
    `https://lesson21functions20220912192803.azurewebsites.net/api/GetBranding/${id}`
  );
  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function updateBranding(id,data)
{
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/UpdateBranding/${id}`,
    data
  );
  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function saveWebsite(id,data)
{
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/SaveWebsite/${id}`,
    data
  );
  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}

function getCountry() {
const promise = axios.get('http://lesson21.com/ploojk9656453944/location.php');
const dataPromise = promise.then(response => response.data);
return dataPromise;
}
function getWebsite(subdomain){
  const promise = axios.get(
    `https://lesson21.com/ploojk9656453944/${subdomain}/index.php`
  );
  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function updateLogin(id,data)
{
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/UpdateLogin/${id}`,
    data
  );
  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}

function passGen(len) {
  var text = "";
  
  var charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  
  for (var i = 0; i < len; i++)
    text += charset.charAt(Math.floor(Math.random() * charset.length));
  
  return text;
}
function forgotPassword(email)
{
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/ForgotPassword`,
    {email}
  );
  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function pay(plan,currentUser)
{
  const promise = axios.post(
    `https://lesson21functions20220912192803.azurewebsites.net/api/CreateCheckoutSession`,
    {plan},
    { headers: {
      "x-functions-key": currentUser.token
    }}
  );
  const dataPromise = promise.then(response => response.data);
  return dataPromise;
}
function getAttendanceList(id,currentUser)
{
  const promise = axios.get(
    `https://lesson21functions20220912192803.azurewebsites.net/api/attendancelist/${id}`,
    { headers: {
      "x-functions-key": currentUser.token
    }}
  );
  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function dateAttendance(id,date,currentUser)
{
  const promise = axios.get(
    `https://lesson21functions20220912192803.azurewebsites.net/api/dateattendance/${id}/${date}`,
    { headers: {
      "x-functions-key": currentUser.token
    }}
  );
  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
function handleStore(name,data){
  let obj = null;
  let myPromise = new Promise(function(myResolve, myReject) {
    // "Producing Code" (May take some time)
    
    if(name == "login")
     {
      obj = Store.getCurrentUser();
      if(obj){
        if(1==2)
        {
          if(obj!=null){  
            myResolve(obj);} else {
             // when successful
            myReject("error");
            }
        } else {
          let promise = login(data);
          promise.then((data)=>{myResolve(data)}).catch(()=>{ myReject("error");})
        }
      } else {
        let promise = login(data);
        promise.then((data)=>{myResolve(data)}).catch(()=>{ myReject("error");})
      }
      
    }
      // when error
    });
  return myPromise;
}
export {
  getAutoAttendance,
  getCountry,
  pay,
  getAssignmentMarks,
  getMeetingLink,
  getAttendanceList,
  dateAttendance,
  getAttendance,
  postAttendance,
  updateSubmittedAssignment,
  getNotice,
  postNotice,
  forgotPassword,
  handleStore,
  getBranding,
  updateBranding,
  updateLogin,
  passGen,
  talk,
  getLiveLink,
  uploadFile,
  getStorageSize,
  checkPayment,
  createPaymentLink,
  createSubscriptionLink,
  signup,
  getChunkSize,
  chunkUpload,
  getChunkCount,
  uploadSheet,
  download,
  getPlan,
  getSelectedStudentList,
  getSelectedTeacherList,
  editCourse,
  getUserCount,
  inviteUsers,
  getAdminList,
  getEvents,
  addEvent,
  deleteResource,
  deleteVideo,
  getLesson,
  deleteCourse,
  newCourse,
  getStudentList,
  getTeacherList,
  AdminAddUser,
  deleteUser,
  deleteAssignment,
  addQuiz,
  getQuiz,
  deleteQuiz,
  deleteLesson,
  getLessons,
  getLibrary,
  upload,
  uploadBook,
  login,
  getVideo,
  uploadVideo,
  uploadCourseBook,
  getCourseBook,
  getTasks,
  addTask,
  formDataUpload,
  addLesson,
  updateLesson,
  uploadAssignment,
  getAssignment,
  getAssignmentSubmissions,
  updateProfile,
  getStudentDetails,
  getTeacherDetails,
  getCourseDetails,
  GetCourseList,
  saveWebsite,
  getWebsite
};
