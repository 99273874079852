import React, { Component } from "react";
import "react-contexify/dist/ReactContexify.css";
import Modal from "react-modal";
import "react-multi-email/style.css";
import { getAdminList, getUserCount,inviteUsers,download,uploadSheet,getPlan, getStorageSize } from "../services/api";
import Load from "../Load";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import { Store } from "../flux";
import DashNavigate from "./DashNavigate";
import Storage from "../views/Storage";
import FileDownload from 'js-file-download';
import { Dispatcher, Constants } from "../flux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Joyride from 'react-joyride';

const steps =  [
  {
    content: (
      <div>
        You can interact with your own components through the spotlight.
        <br />
        Click the menu above!
      </div>
    ),
    disableBeacon: true,
    // disableOverlayClose: true,
    // hideCloseButton: true,
    // hideFooter: true,
    placement: 'left',
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.course-step',
    title: 'Menu',
  },
  {
    content: (
      <div>
        You can interact with your own components through the spotlight.
        <br />
        Click the menu above!
      </div>
    ),
    disableBeacon: true,
    // disableOverlayClose: true,
    // hideCloseButton: true,
    // hideFooter: true,
    placement: 'left',
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.teacher-step',
    title: 'Menu',
  },
  {
    content: (
      <div>
        You can interact with your own components through the spotlight.
        <br />
        Click the menu above!
      </div>
    ),
    disableBeacon: true,
    // disableOverlayClose: true,
    // hideCloseButton: true,
    // hideFooter: true,
    placement: 'left',
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.student-step',
    title: 'Menu',
  },
  {
    content: (
      <div>
        You can interact with your own components through the spotlight.
        <br />
        Click the menu above!
      </div>
    ),
    disableBeacon: true,
    // disableOverlayClose: true,
    // hideCloseButton: true,
    // hideFooter: true,
    placement: 'right',
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.invite-step',
    title: 'Menu',
  },
  {
    content: (
      <div>
        You can interact with your own components through the spotlight.
        <br />
        Click the menu above!
      </div>
    ),
    disableBeacon: true,
    // disableOverlayClose: true,
    // hideCloseButton: true,
    // hideFooter: true,
    placement: 'right',
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.upgrade-step',
    title: 'Menu',
  },
  {
    content: (
      <div>
        You can interact with your own components through the spotlight.
        <br />
        Click the menu above!
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
    placement: 'left',
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.course-step',
    title: 'Menu',
  },
]


class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      run: true,
      stepIndex: 0,
      showModal: false,
      items: [],
      inviteCount: 0,
      max:50,
      value: "",
      error: null,
      storage:0,
      hover: false,
      loading: false,
      currentUser: Store.getCurrentUser(),
      plan:Store.getCurrentPlan(),
      hoverId: 0,
      posts: [],
      courses:[],
      students:[],
      teachers:[]
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
componentDidMount(){
  getPlan(this.state.currentUser.Institution,this.state.currentUser).then((data)=>{
    this.setState({...this.state,plan: data});
    Dispatcher.dispatch({
      actionType: Constants.PLAN,
      payload: data
    });
   
  }).catch(()=>{});
  getAdminList(this.state.currentUser.Institution,this.state.currentUser)
  .then((data)=>{
    this.setState({...this.state,loading:false,courses:data.CourseList == null ? []:data.CourseList,students:data.StudentList==null?[]:data.StudentList,teachers:data.TeacherList==null?[]:data.TeacherList})
  })
  .catch(()=>{
    this.setState({...this.state,loading:false});
  })
  getUserCount(this.state.currentUser.Institution,this.state.currentUser).then((data)=>{
    this.setState({...this.state,inviteCount:parseInt(data.count),max:parseInt(data.max)});
  })
  .catch(()=>{})
}
  handleOpenModal() {
    this.setState({ ...this.state, showModal: true });
  }
  handleKeyDown = evt => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.value.trim();

      if (value && this.isValid(value)) {
        this.setState({
          items: [...this.state.items, this.state.value],
          inviteCount: this.state.inviteCount + 1,
          value: ""
        });
      }
    }
  };
  handleCloseModal() {
    this.setState({ ...this.state, showModal: false });
  }
  handleChange = evt => {
    this.setState({
      value: evt.target.value,
      error: null
    });
  };

  handleDelete = item => {
    this.setState({
      items: this.state.items.filter(i => i !== item),
      inviteCount: this.state.inviteCount - 1
    });
  };

  handlePaste = evt => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter(email => !this.isInList(email));

      this.setState({
        items: [...this.state.items, ...toBeAdded],
        inviteCount: this.state.inviteCount + toBeAdded.length
      });
    }
  };

  isValid(email) {
    let error = null;

    if (this.isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!this.isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      this.setState({ error });

      return false;
    }

    return true;
  }

  isInList(email) {
    return this.state.items.includes(email);
  }

  isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  render() {
    return this.state.loading ? (
      <Container fluid className="main-content-container px-4">
        <Load />
      </Container>
    ) : (
      <Container fluid className="main-content-container px-0 bg-dark">
        {/* Page Header */}
        <ToastContainer />
        <div className="main-content-container px-4">
          <DashNavigate history={this.props.history} match={this.props.match} />
          {/* <Joyride
          continuous={true}
          run={this.state.run && window.location.href.includes('account')}
          steps={steps}
          stepIndex={this.state.stepIndex}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          callback={(x)=>{
            if(x.action == 'next' && x.lifecycle == 'complete'){
              this.setState({...this.state, stepIndex: x.index+1})
              if(x.index == 5){
                Dispatcher.dispatch({
                  actionType: Constants.TOUR,
                  payload: {run: true, stage: 'course'}
                });
              }
            }
            console.log(x);
          //  this.setState({...this.state, stepIndex: this.state.stepIndex+1})
          }}
        /> */}
          <Row>
            <Col lg="8" className="mb-4">
              <Card small className="mb-4">
                <CardHeader className="border-bottom">Manage</CardHeader>

                <ListGroup flush>
                  <ListGroupItem className="p-0 px-3 pt-3">
                    <Row>
                      <Col>
                        <Card small className="mb-4 overflow-hidden">
                          <CardHeader className="bg-dark">
                            <h6 className="m-0 text-white">
                              Courses{" "}
                              
                              <i
                                onClick={() => {
                                  this.props.history.push(
                                    `/adminmanage/COURSE`
                                  );
                                }}
                                style={{
                                  float: "right",
                                  color: "white",
                                  marginRight: "10px"
                                }}
                                class="fa fa-eye course-step"
                                aria-hidden="true"
                              ></i>
                            </h6>
                          </CardHeader>
                          <CardBody className="bg-dark p-0 pb-3">
              {this.state.courses.length > 0 ? (
                <table className="table table-dark mb-0">
                  <thead className="thead-dark">
                    <tr>
                      {Object.keys(this.state.courses[0]).map((head) => (
                        <th scope="col" className="border-0">
                          {head !== "Image" && head!== "Live" ? head : ""}
                        </th>
                      ))}
                      
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.courses.map((row) => (
                      <tr>
                        {Object.values(row).map(col => (
                          <td>
                            {!col.includes("upload/uploads") &&
                            !col.includes(".jpg") &&
                            !col.includes(".jpeg") &&
                            !col.includes(".png") &&
                            !col=="" && !col.includes("/")
                              ? col
                              : ""}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <center>No Data to Display</center>
              )}
            </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </ListGroupItem>

                  <ListGroupItem className="p-3">
                    <Row>
                      <Col>
                        <Card small className="mb-4 overflow-hidden">
                          <CardHeader className="bg-dark">
                            <h6 className="m-0 text-white">
                              Teachers{" "}
                              <i
                                onClick={() => {
                                  this.props.history.push(
                                    `/adminmanage/TEACHER`
                                  );
                                }}
                                style={{
                                  float: "right",
                                  color: "white",
                                  marginRight: "10px"
                                }}
                                class="fa fa-eye teacher-step"
                                aria-hidden="true"
                              ></i>
                              <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={e => {
                      toast.success("Sheet uploaded, the data will process in the background. Feel free to continue using the site during processing.",{position: "top-center"})
                      uploadSheet(this.state.currentUser.Institution,e,this.state.currentUser)
                        .then(data => {
                          this.setState({...this.state,loading:false});
                          toast.info("The uploaded sheet was processed.",{position: "top-center"})
                        })
                        .catch(err => {this.setState({...this.state,loading:false});});
                    }}
                    className="custom-file-input"
                    id="sheetUpload"
                  />
                              {this.state.plan.Premium == '1' ?  <i
                                onClick={() => {
                                  document.getElementById("sheetUpload").click();
                                }}
                                style={{
                                  float: "right",
                                  color: "white",
                                  marginRight: "10px"
                                }}
                                class="fa fa-upload"
                                aria-hidden="true"
                              ></i> : '' }
                               {this.state.plan.Premium == '1' ? <i
                                onClick={() => {
                                  toast.success("Your file will be downloaded shortly",
                                  {position: "top-center"});
                                  download(this.state.currentUser.Institution,this.state.currentUser,'TEACHER')
                                  .then((data)=>{FileDownload(data, 'Users.xlsx');})
                                }}
                                style={{
                                  float: "right",
                                  color: "white",
                                  marginRight: "10px"
                                }}
                                class="fa fa-download"
                                aria-hidden="true"
                              ></i> : '' }
                            </h6>
                          </CardHeader>
                          <CardBody className="bg-dark p-0 pb-3">
              {this.state.teachers.length > 0 ? (
                <table className="table table-dark mb-0">
                  <thead className="thead-dark">
                    <tr>
                      {Object.keys(this.state.teachers[0]).map((head) => (
                        <th scope="col" className="border-0">
                          {head !== "pic" && head!== "Live" ? head : ""}
                        </th>
                      ))}
                      
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.teachers.map((row) => (
                      <tr>
                        {Object.values(row).map(col => (
                          <td>
                            {!col.includes("upload/uploads") &&
                            !col.includes(".jpg") &&
                            !col.includes(".jpeg") &&
                            !col.includes(".png") &&
                            !col=="" && !col.includes("/")
                              ? col
                              : ""}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <center>No Data to Display</center>
              )}
            </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </ListGroupItem>

                  {/* Forms & Form Validation */}
                  <ListGroupItem className="p-3">
                    <Row>
                      <Col>
                        <Card small className="mb-4 overflow-hidden">
                          <CardHeader className="bg-dark">
                            <h6 className="m-0 text-white">
                              Students{" "}
                              <i
                                onClick={() => {
                                  this.props.history.push(
                                    `/adminmanage/STUDENT`
                                  );
                                }}
                                style={{
                                  float: "right",
                                  color: "white",
                                  marginRight: "10px"
                                }}
                                class="fa fa-eye student-step"
                                aria-hidden="true"
                              ></i>
                               {this.state.plan.Premium == '1' ?  <i
                                onClick={() => {
                                  document.getElementById("sheetUpload").click();
                                }}
                                style={{
                                  float: "right",
                                  color: "white",
                                  marginRight: "10px"
                                }}
                                class="fa fa-upload"
                                aria-hidden="true"
                              ></i> : '' }
                                {this.state.plan.Premium == '1' ?  <i
                                onClick={() => {
                                  toast.success("Your file will be downloaded shortly",
                                  {position: "top-center"});
                                  download(this.state.currentUser.Institution,this.state.currentUser,'STUDENT')
                                  .then((data)=>{FileDownload(data, 'Users.xlsx');})
                                }}
                                style={{
                                  float: "right",
                                  color: "white",
                                  marginRight: "10px"
                                }}
                                class="fa fa-download"
                                aria-hidden="true"
                              ></i>:''}
                            </h6>
                          </CardHeader>
                          <CardBody className="bg-dark p-0 pb-3">
              {this.state.students.length > 0 ? (
                <table className="table table-dark mb-0">
                  <thead className="thead-dark">
                    <tr>
                      {Object.keys(this.state.students[0]).map((head) => (
                        <th scope="col" className="border-0">
                          {head !== "pic" && head!== "Live" ? head : ""}
                        </th>
                      ))}
                      
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.students.map((row) => (
                      <tr>
                        {Object.values(row).map(col => (
                          <td>
                            {!col.includes("upload/uploads") &&
                            !col.includes(".jpg") &&
                            !col.includes(".jpeg") &&
                            !col.includes(".png") &&
                            !col=="" && !col.includes("/")
                              ? col
                              : ""}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <center>No Data to Display</center>
              )}
            </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Card>

              {/* Complete Form Example */}
              {/* <Card small>
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Form Example</h6>
                </CardHeader>
                <CompleteFormExample />
              </Card> */}
            </Col>

            <Col lg="4" className="mb-4">
            {this.state.plan.Premium == '1' ?
            <div
        className="bg-primary text-white text-center rounded p-3 upgrade-step"
        style={{ boxShadow: "inset 0 0 5px rgba(0,0,0,.2)", marginBottom : '1.5rem' }}
        onClick = {()=>{
          this.props.history.push('/branding')
        }}
      >
        Branding
      </div> :''}
              {/* Sliders & Progress Bars */}
              {this.state.plan.Premium == '1' ? <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">
                    Invite Users ({this.state.inviteCount}/{this.state.max}){" "}
                  </h6>
                </CardHeader>
                <ListGroup flush>
                  <>
                    {this.state.items.map(item => (
                      <div className="tag-item" key={item}>
                        {item}
                        <button
                          type="button"
                          className="button"
                          onClick={() => this.handleDelete(item)}
                        >
                          &times;
                        </button>
                      </div>
                    ))}

                    <input
                      className={
                        "input form-control " +
                        (this.state.error && " has-error")
                      }
                      value={this.state.value}
                      placeholder="Type or paste email addresses and press `Enter`..."
                      onKeyDown={this.handleKeyDown}
                      onChange={this.handleChange}
                      onPaste={this.handlePaste}
                    />
                    <button
                     disabled = {this.state.inviteCount >= this.state.max}
                     onClick = {()=>{
                       this.setState({...this.state,loading:true});
                       let requestData = {Institution:this.state.currentUser.Institution,Emails:this.state.items.join()}
                      inviteUsers(requestData,this.state.currentUser)
                      .then(()=>{
                        this.setState({...this.state,items:[],loading: false});
                        getUserCount(this.state.currentUser.Institution,this.state.currentUser).then((data)=>{
                          this.setState({...this.state,items:[],value: "",inviteCount:parseInt(data.count),max:parseInt(data.max)});
                        })
                        .catch(()=>{})
                      }).catch(()=>{
                        this.setState({...this.state,items:[],loading: false});
                      });

                      }}
                      className={
                        this.state.error
                          ? "mb-2 mr-1 btn btn-outline-danger btn-sm invite-step"
                          : "mb-2 mr-1 btn btn-info btn-sm invite-step"
                      }
                    >
                      {this.state.error ? "Invalid Email" : "Invite"}
                    </button>
                  </>
                </ListGroup>
              </Card> : '' }
              <Card small className="mb-4">
              <Storage/>
              </Card>
              <div
        className="bg-success text-white text-center rounded p-3 upgrade-step"
        style={{ backgroundColor: '#18A558', marginBottom: 10, boxShadow: "inset 0 0 5px rgba(0,0,0,.2)" }}
        onClick = {()=>{
          this.props.history.push('/autoattendance')
        }}
      >
        Attendance
      </div> 
        <div
        className="bg-danger text-white text-center rounded p-3 upgrade-step"
        style={{ boxShadow: "inset 0 0 5px rgba(0,0,0,.2)" }}
        onClick = {()=>{
          this.props.history.push('/choose')
        }}
      >
        Upgrade
      </div>

            </Col>
          </Row>
        </div>
        
      </Container>
    );
  }
}

export default Account;
