import React, { Component } from "react";
import { Row, Col, TimePicker,InputNumber } from "antd";
import Select from 'react-select';
import { Store } from "../../flux";
import { withTranslation } from "react-i18next";
import { Slide, Zoom } from "react-awesome-reveal";
import Button  from "../../common/Button";
import moment from 'moment';
import { MiddleBlockSection, Content, ContentWrapper,SInput,STextArea } from "./styles";
import {
    chunkUpload, addQuiz
  } from "../../services/api";

 class Exam extends Component {
    constructor(props){
        super(props)
        this.state = { currentUser: Store.getCurrentUser(),createStage: true, quizName: '', synopsis: '', time: '00:00:00', 
        quiz:{Name: '',
              Time: '00:00:00',
              Synopsis: '',
              Questions: []},
        question: {
         Question: '',
         Type: 'text',
         Options:['',''],
         Correct: [],
         Points: 0
        }
    }
    }
    addQuiz(addData) {
      let body = {
        Name: addData.Name,
        ShortCode: this.props.match.params.id,
        ModifiedTime: new Date().toISOString().split('T')[0],
        Status: 1,
        Content: JSON.stringify(addData)
      };
      addQuiz(body, this.state.currentUser).then(res => {
        this.props.history.push(`/quizgallery/${this.props.match.params.id}`);
      });
    }
    config = {
      onUploadProgress: progressEvent => {
        this.progressSum+=progressEvent.loaded;
        let progress = (this.progressSum / this.state.fileSize) * 100;
        if(parseInt(progress) >=100){
          this.progressSum = 0;
          progress = 100;
        }
        // this.setState({...this.state,buttonText:`Uploading ${parseInt(progress)}%`})
      }
    }
    render(){
        return (
            <MiddleBlockSection>
      <Slide direction="up">
        <Row justify="center" align="middle">
          <ContentWrapper>
            {this.state.createStage ?
            <Row justify="space-between" align="middle">
            <Col lg={24} md={24} sm={24} xs={24}>
              <h6>{'Create Quiz'}</h6>
              </Col>
              <Col lg={24} md={24} sm={24} xs={24}>
              <SInput
                  type="text"
                  name="name"
                  placeholder="Title"
                  value={this.state.quizName}
                  onChange={(e)=>{this.setState({...this.state, quizName: e.target.value})}}
                />
                </Col>
                <Col lg={24} md={24} sm={24} xs={24}>
                <TimePicker style={{width: '100%', marginTop: '13px', marginBottom: '13px'}} onChange={(time, timeString)=>{this.setState({...this.state, quiz: {...this.state.quiz, Time: timeString}})}} defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} />
                </Col>
                <Col lg={24} md={24} sm={24} xs={24}>
                <STextArea
                  placeholder="Synopsis"
                  value={this.state.synopsis}
                  name="message"
                  onChange={(e)=>{this.setState({...this.state, synopsis: e.target.value})}}
                />
                </Col>
                <Col lg={24} md={24} sm={24} xs={24}>
              {this.state.quizName !== '' && (
                <Button name="submit" onClick={() => {
                  let quiz = {Name: this.state.quizName,
                  Time: this.state.quiz.Time,
                  Synopsis: this.state.synopsis,
                  Questions: []}
                    this.setState({...this.state, createStage: false, quiz: quiz});
                }}>
                  {'Create Quiz'}
                </Button>
              )}
            </Col></Row> : 
            
            <Row justify="space-between" align="middle">
            <Col lg={24} md={24} sm={24} xs={24}>Total Questions : {this.state.quiz.Questions.length}</Col> 
            <Col lg={24} md={24} sm={24} xs={24}>
            <SInput
                  type="text"
                  name="question"
                  placeholder="Type your Question"
                  value={this.state.question.Question}
                  onChange={(e)=>{this.setState({...this.state, question:{...this.state.question, Question: e.target.value}})}}
                />
            </Col>
            <Col lg={24} md={24} sm={24} xs={24}>
  <Select
  onChange={(value)=>{
    this.setState({...this.state, question:{...this.state.question, Type: value.value, Options: value.value != 'description' ? ['','']: []}})
}}
          className="basic-single"
          classNamePrefix="select"
          // defaultValue={colourOptions[0]}
          name="color"
          options={[{value:'text',label: 'MCQ'},{value:'image',label: 'Image'},{value:'description',label: 'Description'}]}
        />
            </Col> 
            <Col lg={24} md={24} sm={24} xs={24}>
            {this.state.question.Type !== 'description'? this.state.question.Options.map((x,index)=>(
                               <SInput
                              type={this.state.question.Type == 'text' ? 'text' : 'file'}
                              key={index}
                              name="question"
                              placeholder={`Type your option here`}
                              value={this.state.question.Type == 'text' ? x : null}
                              onChange={(e)=>{
                                let options = this.state.question.Options;
                                if(this.state.question.Type != 'text'){
                                  console.log(this.state.currentUser);
                                  //image
                                  chunkUpload(e,this.config,this.state.currentUser)
                                  .then(data => { 
                                    
                                   
                                    if(options.length>index){
                                        options[index] = data;
                                    } else {
                                        options.push(data);
                                    } 
                                    this.setState({...this.state, question:{...this.state.question, Options: options}})
                                  })
                                  .catch((err) => {
                                    if(err=="You have exceeded your storage quota. Please Upgrade."){
                                      alert(err);
                                      //this.setState({ ...this.state, showModal: false });
                                    }
                                  });
                                } else {
                                
                                if(options.length>index){
                                    options[index] = e.target.value;
                                } else {
                                    options.push(e.target.value);
                                } 
                                this.setState({...this.state, question:{...this.state.question, Options: options}})
                              }
                                }}
                            />

                          )):'The students will see a text area to enter the answer'}
                          {this.state.question.Type !== 'description'?<Button onClick ={()=>{
                               let options = this.state.question.Options;
                               options.push('');
                               this.setState({...this.state, question:{...this.state.question, Options: options}})}} type="primary">Add</Button>
                          :''}
                          </Col>
                          Points: <InputNumber min={0} max={100} value = {this.state.question.Points} defaultValue={this.state.question.Points} onChange={(x)=>{this.setState({...this.state, question: {...this.state.question, Points: x}})}} />
            <Col lg={24} md={24} sm={24} xs={24}>
                Correct Answer:
                {this.state.question.Type !== 'description' ? 
                 <Select
                 isMulti
                 style={{ flex: 1, display: 'flex' }}
                 name="colors"
                 options={this.state.question.Options.map((x,index)=>({value: index, label: x}))}
                 className="basic-multi-select"
                 classNamePrefix="select"
                 value = {this.state.question.Correct == [] ? null : this.state.question.Correct}
                 onChange={(value)=>{
                  this.setState({...this.state, question:{...this.state.question, Correct: value}})
              }}
               />
                : ''}
            <Button onClick ={()=>{ 
                let questions = this.state.quiz.Questions;
                questions.push(this.state.question);
                this.setState({...this.state, question: {
                    Question: '',
                    Type: 'text',
                    Options:['',''],
                    Correct: [],
                    Points: 0
                   }, quiz:{...this.state.quiz, Questions: questions}})}}>Next</Button>
                <Button onClick ={()=>{ 
                let questions = this.state.quiz.Questions;
                questions.push(this.state.question);
                this.setState({...this.state, question: {
                    Question: '',
                    Type: 'text',
                    Options:['',''],
                    Correct: [],
                    Points: 0
                   }, quiz:{...this.state.quiz, Questions: questions}})
                   console.log(this.state.quiz)
                   this.addQuiz(this.state.quiz);
                   }}>Complete</Button>
            </Col>
            </Row>}
          </ContentWrapper>
        </Row>
      </Slide>
    </MiddleBlockSection>
        );
    }
}

export default withTranslation()(Exam);