import React from "react";
import { Container, Row, Col } from "shards-react";
import { Store } from "../flux";
import Modal from "react-modal";
import {
  chunkUpload,
  updateProfile
} from "../services/api";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  FormInput,
  Button
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { Form } from "shards-react";

class UserProfileLite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: Store.getCurrentUser(),
      showModal: false,
      buttonTheme: "primary",
      buttonText: "Publish"
    };
    this.setParentState = this.setParentState.bind(this);
  }
  config = {
    onUploadProgress: progressEvent => {
      this.progressSum+=progressEvent.loaded;
      let progress = (this.progressSum / this.state.fileSize) * 100;
      if(parseInt(progress) >=100){
        this.progressSum = 0;
        progress = 100;
      }
      this.setState({...this.state,buttonText:`Uploading ${parseInt(progress)}%`})
    }
  }
  progressSum = 0;
  setParentState(state) {
    this.setState({ ...this.state, user: state });
  }
  render() {
    let userType = "";
    if (this.state.user.Type == "STUDENT") {
      userType = "Student";
    } else if (this.state.user.Type == "PRIMARY") {
      userType = "Admin";
    } else {
      userType = "Teacher";
    }
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="User Profile"
            subtitle="Overview"
            md="12"
            className="ml-sm-auto mr-sm-auto"
          />
        </Row>
        <Row>
          <Col lg="4">
            <Card small className="mb-4 pt-3">
              <CardHeader className="border-bottom text-center">
                <div className="mb-3 mx-auto">
                  <img
                    className="rounded-circle"
                    src={this.state.user.pic}
                    alt={``}
                    width="110"
                    onClick={() => {
                      document.getElementById("profilePic").click();
                    }}
                  />
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={e => {
                      chunkUpload(e,this.config,this.state.user)
                        .then(data => {
                          this.setState({
                            ...this.state,
                            user: { ...this.state.user, pic: data }
                          });
                        })
                        .catch((err) => {
                          if(err=="You have exceeded your storage quota. Please Upgrade."){
                            alert(err);
                            this.setState({ ...this.state, showModal: false });
                          }
                        });
                    }}
                    className="custom-file-input"
                    id="profilePic"
                  />
                </div>
                <h4 className="mb-0">{`${this.state.user.FirstName} ${this.state.user.LastName}`}</h4>
                <span className="text-muted d-block mb-2">{userType}</span>
                {/* <Button pill outline size="sm" className="mb-2">
        <i className="material-icons mr-1">person_add</i> Follow
      </Button> */}
              </CardHeader>
            </Card>
          </Col>
          <Col lg="8">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">{`Account Details`}</h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form>
                        <Row form>
                          {/* First Name */}
                          <Col md="6" className="form-group">
                            <label htmlFor="feFirstName">First Name</label>
                            <FormInput
                              id="feFirstName"
                              placeholder="First Name"
                              value={this.state.user.FirstName}
                              onChange={e => {
                                this.setState({
                                  ...this.state,
                                  user: {
                                    ...this.state.user,
                                    FirstName: e.target.value
                                  }
                                });
                              }}
                            />
                          </Col>
                          {/* Last Name */}
                          <Col md="6" className="form-group">
                            <label htmlFor="feLastName">Last Name</label>
                            <FormInput
                              id="feLastName"
                              placeholder="Last Name"
                              value={this.state.user.LastName}
                              onChange={e => {
                                this.setState({
                                  ...this.state,
                                  user: {
                                    ...this.state.user,
                                    LastName: e.target.value
                                  }
                                });
                              }}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          {/* Email */}
                          <Col md="6" className="form-group">
                            <label htmlFor="feEmail">Email</label>
                            <FormInput
                              type="email"
                              id="feEmail"
                              placeholder="Email Address"
                              value={this.state.user.Email}
                              autoComplete="email"
                            />
                          </Col>
                          {/* Password */}
                          <Col md="6" className="form-group">
                            <label htmlFor="fePassword">Password</label>
                            <FormInput
                              type="text"
                              id="fePassword"
                              placeholder="Password"
                              value={this.state.user.Password}
                              onChange={e => {
                                this.setState({
                                  ...this.state,
                                  user: {
                                    ...this.state.user,
                                    Password: e.target.value
                                  }
                                });
                              }}
                              autoComplete="current-password"
                            />
                          </Col>
                        </Row>
                        <Row form>
                          {/* Description */}
                          {/* <Col md="12" className="form-group">
                  <label htmlFor="feDescription">Description</label>
                  <FormTextarea id="feDescription" rows="5" />
                </Col> */}
                        </Row>
                        <Button
                          onClick={() => {
                            let data = {
                              FirstName: this.state.user.FirstName,
                              LastName: this.state.user.LastName,
                              Password: this.state.user.Password,
                              pic: this.state.user.pic
                            };
                            updateProfile(this.state.user.id, data,this.state.user).then(
                              res => {
                               
                                localStorage.setItem("User1", null);
                                this.props.history.push(`/login`);
                              }
                            );
                          }}
                          theme="accent"
                        >
                          Update Account
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.showModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick={true}
          className="library_modal"
          overlayClassName="library_overlay"
          ariaHideApp={false}
        >
          <Card small>
            {/* Files & Dropdowns */}
            <CardHeader className="border-bottom">
              <h6 className="m-0">Upload an Ebook</h6>
            </CardHeader>

            <ListGroup flush>
              <ListGroupItem className="px-3">
                <strong className="text-muted d-block mb-2">Name</strong>
                <div className="custom-file mb-3">
                  <FormInput
                    onChange={e => {
                      this.setState({
                        ...this.state,
                        fileName: e.target.value
                      });
                    }}
                  />
                </div>
                <strong className="text-muted d-block mb-2">Upload File</strong>
                <div className="custom-file mb-3">
                  <input
                    type="file"
                    onChange={e => {
                      this.setState({
                        ...this.state,
                        buttonTheme: "warning",
                        buttonText: "Uploading"
                      });
                      chunkUpload(e,this.config,this.state.user)
                        .then(data => {
                          this.setState({
                            ...this.state,
                            user: { ...this.state.user, pic: data },
                            buttonTheme: "success",
                            buttonText: "Publish"
                          });
                        })
                        .catch((err) => {
                          if(err=="You have exceeded your storage quota. Please Upgrade."){
                            alert(err);
                            this.setState({ ...this.state, showModal: false });
                          }
                        });
                    }}
                    className="custom-file-input"
                    id="customFile2"
                  />
                  <label className="custom-file-label" htmlFor="customFile2">
                    Choose file...
                  </label>
                </div>
                <div className="custom-file mb-3">
                  <Button
                    disabled={this.state.buttonText !== "Publish"}
                    theme={this.state.buttonTheme}
                    onClick={() => {}}
                  >
                    {this.state.buttonText}
                  </Button>
                </div>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Modal>
      </Container>
    );
  }
}

export default UserProfileLite;
