import React from "react";
import { Card, CardBody } from "shards-react";

import { IconContext } from "react-icons";
import LinesEllipsis from "react-lines-ellipsis";
import ReactTooltip from "react-tooltip";

class IconDisplay extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        onContextMenu={this.props.context}
        data-tip={this.props.title}
        onClick={this.props.bookClick}
        style={{ maxWidth: "98px", marginRight: "20px", float: "left" }}
      >
        <ReactTooltip />
        <Card small>
          <CardBody>
            <IconContext.Provider
              value={{ color: this.props.color, size: this.props.iconSize }}
            >
              <div>{this.props.icon}</div>
            </IconContext.Provider>
          </CardBody>
        </Card>
        <center>
          <LinesEllipsis
            text={this.props.title}
            maxLine="2"
            ellipsis="..."
            style={{ maxWidth: "96px", marginTop: "5px" }}
            trimRight
            basedOn="letters"
          />
        </center>
      </div>
    );
  }
}

export default IconDisplay;
