import React, { Component } from "react";
import { Store } from "../flux";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody} from "shards-react";
import { Menu, Item, contextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import PageTitle from "./../components/common/PageTitle";
import axios from "axios";
import Load from "../Load";
import { getLesson } from "../services/api";

class Lesson extends Component {
  constructor(props) {
    super(props);
    this.state = { Name: "", Content: ``, currentUser: Store.getCurrentUser(), loading: true};
  }
  componentDidMount() {
      getLesson(this.props.match.params.id,this.state.currentUser)
      .then(res => {
        this.setState({ Name: res.Name, Content: res.Content,loading:false });
      }).catch(()=>{
        this.setState({...this.state,loading:false});
      });
  }
  showMenu(e) {
    if (this.state.currentUser.Type !== "STUDENT") {
      contextMenu.show({
        id: `library`,
        event: e,
        props: {
          key: "value"
        }
      });
    }
  }
  render() {
    return ( this.state.loading ? (
      <Container fluid className="main-content-container px-4">
        <Load />
      </Container>
    ) :
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Lesson"
            subtitle="Dashboard"
            className="text-sm-left mb-3"
          />
        </Row>
        <Menu id={`library`}>
          <Item
            onClick={() => {
              this.props.history.push("/questions");
            }}
          >
            Edit
          </Item>
          <Item
            onClick={() => {
              this.props.history.push("/questions");
            }}
          >
            Delete
          </Item>
        </Menu>
        <Menu id={`item`}>
          <Item onClick={this.handleDelete}>Delete</Item>
        </Menu>

        <Row
          onContextMenu={e => {
            this.showMenu(e);
          }}
        >
          <Col lg="12" md="12" sm="12" className="mb-4">
            <Card small className="card-post card-post--1">
              <CardBody>
                <h5 className="card-title">
                  <a href="#" className="text-fiord-blue">
                    {this.state.Name}
                  </a>
                </h5>
                <p
                  className="card-text d-inline-block mb-3"
                  dangerouslySetInnerHTML={{ __html: this.state.Content }}
                ></p>
                <span className="text-muted"></span>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Lesson;
