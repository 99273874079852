import React, { Component } from "react";
import {
    getAssignmentMarks
} from "../services/api";
import { Store } from "../flux";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    ButtonGroup,
    Button,
    Row,
    Col
  } from "shards-react";
  import { FiEdit, FiTrash2, FiPlus } from "react-icons/fi";
  import ViewAttendance from "./ViewAttendance";

export default class Grades extends Component{
    constructor(props){
        super(props)
        this.state = {
            currentUser: Store.getCurrentUser(),
            course: this.props.match.params.id,
            list:[]
        }
    }
    componentDidMount(){
        getAssignmentMarks(this.props.match.params.id, this.state.currentUser).then((res)=>{
          this.setState({...this.state, list: res});
        }).catch();
    }
  
    render(){
       
        return(
            <Row style={{maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto',marginTop: '100px'}}>
            <Col>
              <Card small className="mb-4 overflow-hidden ">
                <CardHeader className="bg-dark">
                 
                </CardHeader>
                <CardBody className="bg-dark p-0 pb-3">
                  {this.state.list.length > 0 ? (
                    <table className="table table-dark mb-0">
                      <thead className="thead-dark">
                        <tr>
                          
                            <th scope="col" className="border-0">
                             Student
                            </th>
                            <th scope="col" className="border-0">
                             Assignment
                            </th>
                         <th scope="col" className="border-0">
                              {`Marks`}
                            </th>
                        
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.list.map((row, index) => (
                          <tr>
                           
                              <td>
                                {row.fullname}
                              </td>

                              <td>
                                {row.assignment}
                              </td>
                           
                           
                              <td>
                              {row.marks}
                              </td>
                           
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <center>No Data to Display</center>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
    
        )
    }
}