import styled from "styled-components";

export const PricingTitle = styled("h6")`
  text-align: center;
  font-size: 2.5vw;
  justify-content: center;
`;

export const Features = styled("div")`
padding: 5px 0 5px 0;
@media only screen and (max-width: 600px) {
    text-align: center;
font-size: 2.8vw;
    word-break: keep-all;
}
@media only screen and (max-width: 2000px) and (min-width: 601px) {
    font-size: 1vw;
    word-break: keep-all;
}
line-height: 1.18;
font-family: 'Motiva Sans Bold', serif;
color: '#2F4F4F';
font-weight: bold;
`;

export const ContentWrapper = styled("div")`
  max-width: 570px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const MiddleBlockSection = styled("section")`
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    padding: 5.5rem 0 3rem;
  }
`;

export const Content = styled("p")`
  padding: 0.75rem 0 0.75rem;
  color: #2F4F4F;
  font-weight: bold;
`;


