import React, { Component } from "react";
import { Container, Row, Col } from "shards-react";
import { Store } from "../flux";
import PageTitle from "./../components/common/PageTitle";
import NoticeBoard from "./../components/noticeboard/noticeboard"

class BulletBoard extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount(){
   
  }
  render() {
    return (
      <Container fluid className={`main-content-container px-4`}>
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Notice Board"
            subtitle="Lesson 21"
            className="text-sm-left mb-3"
          />
        </Row>
<Row>
    <NoticeBoard></NoticeBoard>
</Row>
      </Container>
    );
  }
}

export default BulletBoard;
