import React from "react";
import queryString from 'query-string';
import { signup, login,createPaymentLink,createSubscriptionLink,checkPayment,talk } from "../services/api";
import { Dispatcher, Constants } from "../flux";
import Popup from 'reactjs-popup';
import Iframe from 'react-iframe'
import {Container, Alert}  from "shards-react";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  FormInput,
  Button
} from "shards-react";
import 'reactjs-popup/dist/index.css';
import Modal from "react-modal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Load from "../Load";

class Pricing extends React.Component {
  constructor(props) {
   super(props)
   this.state = {
    paymentType:'NORMAL',
       FirstName:'',
       LastName:'',
       Email:'',
       loading: false,
       Phone:'',
       Plan:'',
       Tenure:'Annual',
       slab: false,
       open:false,
       amount:0,
       quantity:0,
       frame: false,
       School:'',
       url:'',
       Password:''
   }
  }

  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    if(!(params.FirstName.length>0 && params.LastName.length>0 && params.Email.length>0 && params.Phone.length>0)){
     window.location.href="https://www.lesson21.com";
    }
    this.setState({...this.state,...params, Password: this.passGen(6)});
   
  }
 passGen(len) {
    var text = "";
    
    var charset = "abcdefghijklmnopqrstuvwxyz0123456789";
    
    for (var i = 0; i < len; i++)
      text += charset.charAt(Math.floor(Math.random() * charset.length));
    
    return '';
  }
  render() {
    
    return this.state.loading ? (
      <Container fluid className="main-content-container px-4">
        <Load />
      </Container>
    ) :(
      this.state.frame ? <div><Alert className="mb-0 alert-danger">
      <i className="fa fa-info mx-2"></i> Please remain on this page until your payment succeeds and you are automatically logged into your subscription. Maximum wait time is 10 minutes.
    </Alert><Iframe url={this.state.url}
      width="100%"
      height={window.innerHeight}
      id="myId"
      className="calls"
      display="initial"
      position="relative"/></div> : 
        <div className="pricing-container">
            <ToastContainer />
            {/* <div className="pricing-head text-center mb-3">
                Plans & Pricing
            </div> */}
            <div className="top">
              <div className="toggle-btn">
                <span style={{margin : '0.8em'}}>Annually</span>
                <label className="switch">
                  <input type="checkbox" id="checbox" onClick={()=>{
                      let tenure = this.state.Tenure;
                      if(tenure == 'Annual'){
                         this.setState({...this.state,Tenure:'Monthly'});
                      } else {
                        this.setState({...this.state,Tenure:'Annual'});
                      }
                  }}/>
                  <span className="slider round"></span>
                </label>
                <span style={{margin : '0.8em'}}>Monthly</span></div>
            </div>
        
            <div className="package-container">
              <div className="packages">
                  <div className="pricing-title pb-3">
                    Free
                  </div>
                <ul className="list">
                <li>Upto 100 Students </li>
                <li>School Management</li>
                  <li>Assignments and Quiz</li>
                  <li>2 GB free storage</li>
                  <li>Google Meet / Zoom integration </li>
                  <li>Video & File Uploads</li>
                  <li>Email Support</li>
                </ul>
                <button className="button pricing-button" onClick={()=>{
                  this.setState({...this.state, paymentType: 'FREE', open: true});
                    //signup
                    
                }}>Start Now</button>
              </div>
              <div className="packages">
                <div className="pricing-title">
                    Standard  
                </div>                    
                <h2 className="text1">{this.state.Tenure == 'Annual' ? `₹10000.00` : `₹1000.00`} <span className='userMonth'>{this.state.Tenure == 'Annual'?'/ year':'/ month'}</span></h2>
               
                <ul className="list">
                  <li>All Features of Free</li>
                  <li>Curated Content Library</li>
                  <li>100 GB storage</li>
                  <li>Kanban Board</li>
                  <li>Upto 1000 Students</li>
                  <li>Branding</li>
                  <li>Bulk User Management</li>
                  <li>24/7 Live support</li>
                </ul>
                <button className="button pricing-button" onClick={()=>{

                         this.setState({...this.state,open:true,slab: true, paymentType: 'NORMAL'});
                      
                  }}>Start Now</button>
              </div>
              <div className="packages">
                <div className="pricing-title">
                    Pro
                </div>                    
                <h2 className="text1">{this.state.Tenure == 'Annual' ? `₹999.00` : `₹99.00`} <span className='userMonth'>{this.state.Tenure == 'Annual'?'/ user / year':'/ user / month'}</span></h2> 
               
                <ul className="list">
                  <li>All Features of Standard</li>
                  <li>1 Tera Byte storage</li>
                  <li>Premium Admin Dashboard</li>
                  <li>Unlimited No of Students</li>
                  <li>Custom Video Calling Solution</li>
                  <li>Android / IOS App</li>
                  <li>Book Library</li>
                  <li>Marketing Support</li>
                </ul>
                <button className="button pricing-button" onClick={()=>{

                         this.setState({...this.state,open:true,slab: false, paymentType: 'NORMAL'});
                      
                  }}>Start Now</button>
              </div>

              <Modal
          isOpen={this.state.open}
          contentLabel="onRequestClose Example"
          onRequestClose={()=>{this.setState({...this.state,open:false})}}
          shouldCloseOnOverlayClick={true}
          className="pricing_modal"
          overlayClassName="library_overlay"
          ariaHideApp={false}
        >
          <Card small>
            {/* Files & Dropdowns */}
            <CardHeader className="border-bottom">
              <h6 className="m-0">
               Onboard
              </h6>
            </CardHeader>

            <ListGroup flush>
              <ListGroupItem className="px-3">
                <strong className="text-muted d-block mb-2">School Name</strong>
               
                <FormInput
                              type="text"
                              id="fePassword"
                              placeholder="School Name"
                              value={this.state.School}
                              onChange={e => {
                                this.setState({...this.state,School:e.target.value});
                              }}
                              autoComplete="current-password"
                            />
                  <strong className="text-muted d-block mb-2">Password</strong>
               
               <FormInput
                             type="text"
                             id="fePassword"
                             placeholder="Password"
                             value={this.state.Password}
                             onChange={e => {
                               this.setState({...this.state,Password : e.target.value});
                             }}
                             autoComplete="current-password"
                           />
                  {this.state.slab || this.state.paymentType == 'FREE' ? '' :  <div><strong className="text-muted d-block mb-2">Number of Users</strong>
                           <FormInput
                             type="number"
                             id="fePassword"
                             placeholder="Number of Users"
                             value={this.state.quantity}
                             onChange={x => {
                              let amount = this.state.Tenure  == 'Annual' ? 999 : 99;
                amount = parseInt(x.target.value)*amount*100;
               this.setState({...this.state,amount,quantity: parseInt(x.target.value)}) 
                             }}
                             autoComplete="current-password"
                           /></div>
                            }
                { this.state.paymentType == 'FREE' ? '' : <center>
                <span style={{margin : '0.8em'}}>Debit / Other</span>
                <label className="switch">
                <input type="checkbox" id="paymentType" onClick={()=>{
                      let paymentType = this.state.paymentType;
                      if(paymentType == 'CREDIT'){
                         this.setState({...this.state,paymentType:'NORMAL'});
                      } else {
                        this.setState({...this.state,paymentType:'CREDIT'});
                      }
                  }}/>
                  <span className="slider round"></span>
                 
                </label>
                <span style={{margin : '0.8em'}}>Credit Card</span>
                </center>}
                <div className="custom-file mb-3">
                  <Button
                   
                    theme={this.state.buttonTheme}
                    onClick={() => {
                      this.setState({...this.state, loading: true});
                      let tenure = this.state.Tenure;
                      let amount = this.state.amount;
                      let quantity = this.state.quantity;
                      let paymentType = this.state.paymentType;
                      if(paymentType == 'CREDIT'){
                       
                        let data = {
                          plan_id: tenure == 'Annual' ? 'annual' :  'monthly',
                          total_count : tenure == 'Annual' ? 1 :  12,
                          quantity,
                          slab: this.state.slab,
                          name: `${this.state.FirstName} ${this.state.LastName}`,
                          customer_notify: true,
                          tenure: this.state.Tenure,
                          email: this.state.Email,
                          amount,
                          school: this.state.School,
                          phone: this.state.Phone,
                          password: this.state.Password
                        }
                        createSubscriptionLink(data).then((url)=>{
    
                         this.setState({...this.state,loading: false,frame: true , url});
                         window.scrollTo(0,0);
                         let interval = setInterval(()=>{
                         
                          checkPayment(this.state.Email).then((x)=>{
                           if(x=='1'){
                            clearInterval(interval);
                            login(this.state.Email,this.state.Password)
                        .then(data => {
                            this.setState({ ...this.state, loading: false });
                            Dispatcher.dispatch({
                              actionType: Constants.LOGIN,
                              payload: data
                            });
                            this.props.history.push("/userprofile");
                          })
                          .catch(err => { this.setState({ ...this.state, loading: false });});
                           }
                          }).catch(()=>{
                            clearInterval(interval);
                          })
                           
                         }, 5000);
                        }).catch(()=>{});
                     } else if(paymentType == 'FREE'){
                      let data = {
                        Name:"",
                        school: this.state.School,
                        Address:"India",
                        Rate:"0",
                        Currency:"INR",
                        Language:"en-US",
                        Data:"",
                        Country:"",
                        UserName: this.state.Email,
                        FirstName:this.state.FirstName,
                        LastName:this.state.LastName,
                        Email: this.state.Email,
                        Password : this.state.Password,
                        Phone: this.state.Phone
                    };
                   signup(data).then(()=>{
                    
                    login({username: this.state.Email, password: this.state.Password})
                    .then(data => {
                        this.setState({ ...this.state, loading: false });
                        Dispatcher.dispatch({
                          actionType: Constants.LOGIN,
                          payload: data
                        });
                        this.props.history.push("/home");
                      })
                      .catch(err => { this.setState({ ...this.state, loading: false });});

                   }).catch(()=>{ this.props.history.push("/");})
                     } else {
                      let data = {
                        amount : this.state.slab ? this.state.Tenure == 'Annual'? 10000*100 : 1000*100 : this.state.amount,
                        currency: 'INR',
                        name: `${this.state.FirstName} ${this.state.LastName}`,
                        email: this.state.Email,
                        tenure: this.state.Tenure,
                        school: this.state.School,
                        contact: this.state.Phone,
                        password: this.state.Password
                      }
                      createPaymentLink(data).then((url)=>{
                        this.setState({...this.state,loading: false,frame: true, url})
                       let interval = setInterval(()=>{
                        checkPayment(this.state.Email).then((x)=>{
                          if(x=='1'){
                           clearInterval(interval);
                           login(this.state.Email,this.state.Password)
                        .then(data => {
                            this.setState({ ...this.state, loading: false });
                            Dispatcher.dispatch({
                              actionType: Constants.LOGIN,
                              payload: data
                            });
                            this.props.history.push("/userprofile");
                          })
                          .catch(err => { this.setState({ ...this.state, loading: false });});
                          }
                         }).catch(()=>{
                           clearInterval(interval);
                         })
                        }, 5000);
                      }).catch(()=>{});
                     }
                     
                    }}
                  >
                    Signup
                  </Button>
                </div>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Modal>

              <Popup open={false} position="right center" closeOnDocumentClick onClose={()=>{this.setState({...this.state,open: false})}}>
              <a className="close" onClick={()=>{this.setState({...this.state,open: false})}}>
            &times;
          </a>
          <input type="text" id="schoolName" name="schoolName" onChange={(e)=>{
             this.setState({...this.state,School:e.target.value});
          }}></input>
              <p>Please select the number of users and payment type.</p>
              Number of Users: <input type="number" id="amount" name="amount" onChange={(x)=>{

                let amount = this.state.Tenure  == 'Annual' ? 999 : 99;
                amount =parseInt(x.target.value)*amount*100;
               this.setState({...this.state,amount,quantity: parseInt(x.target.value)})            }}/>
                <span style={{margin : '0.8em'}}>Manual Payments</span>
                <label className="switch">
                <input type="checkbox" id="paymentType" onClick={()=>{
                      let paymentType = this.state.paymentType;
                      if(paymentType == 'CREDIT'){
                         this.setState({...this.state,paymentType:'NORMAL'});
                      } else {
                        this.setState({...this.state,paymentType:'CREDIT'});
                      }
                  }}/>
                  <span className="slider round"></span>
                 
                </label>
                <span style={{margin : '0.8em'}}>Automatic</span>
                <button className="primary" onClick={()=>{
                  let tenure = this.state.Tenure;
                  let amount = this.state.amount;
                  let quantity = this.state.quantity;
                  let paymentType = this.state.paymentType;
                  if(paymentType == 'CREDIT'){
                   
                    let data = {
                      plan_id: tenure == 'Annual' ? 'annual' :  'monthly',
                      total_count : tenure == 'Annual' ? 1 :  12,
                      quantity,
                      name: `${this.state.FirstName} ${this.state.LastName}`,
                      customer_notify: true,
                      tenure: this.state.Tenure,
                      email: this.state.Email,
                      amount,
                      school: this.state.School,
                      phone: this.state.Phone,
                      password: this.state.Password
                    }
                    createSubscriptionLink(data).then((url)=>{

                     this.setState({...this.state,frame: true , url});
                     window.scrollTo(0,0);
                     let interval = setInterval(()=>{
                     
                      checkPayment(this.state.Email).then((x)=>{
                       if(x=='1'){
                        clearInterval(interval);
                        login(this.state.Email,this.state.Password)
                    .then(data => {
                        this.setState({ ...this.state, loading: false });
                        Dispatcher.dispatch({
                          actionType: Constants.LOGIN,
                          payload: data
                        });
                        this.props.history.push("/userprofile");
                      })
                      .catch(err => { this.setState({ ...this.state, loading: false });});
                       }
                      }).catch(()=>{
                        clearInterval(interval);
                      })
                       
                     }, 5000);
                    }).catch(()=>{});
                 } else {
                  let data = {
                    amount : this.state.amount,
                    currency: 'INR',
                    name: `${this.state.FirstName} ${this.state.LastName}`,
                    email: this.state.Email,
                    tenure: this.state.Tenure,
                    school: this.state.School,
                    contact: this.state.Phone,
                    password: this.state.Password
                  }
                  createPaymentLink(data).then((url)=>{
                    this.setState({...this.state,frame: true, url})
                   let interval = setInterval(()=>{
                    checkPayment(this.state.Email).then((x)=>{
                      if(x=='1'){
                       clearInterval(interval);
                       login(this.state.Email,this.state.Password)
                    .then(data => {
                        this.setState({ ...this.state, loading: false });
                        Dispatcher.dispatch({
                          actionType: Constants.LOGIN,
                          payload: data
                        });
                        this.props.history.push("/userprofile");
                      })
                      .catch(err => { this.setState({ ...this.state, loading: false });});
                      }
                     }).catch(()=>{
                       clearInterval(interval);
                     })
                    }, 5000);
                  }).catch(()=>{});
                 }

                }}>Next</button> 
  </Popup>
              <div className="packages">
                <div className="pricing-title">
                    Enterprise
                </div>                   
                <h2 className="text1">Custom</h2>
                <ul className="list">
                  <li>All Features of Premium</li>
                  <li>Dedicated Servers</li>
                  <li>Custom Features and Solutions</li>
                  <li>Dedicated Tech Personnel</li>
                  <li>Unlimited No of Students</li>
                  <li>Custom Integration</li>
                  <li>Fee Collection Facility</li>
                  <li>Custom Mobile App</li>
                  <li>Data Segregation Options</li>
                </ul>
                <button className="button pricing-button" onClick={()=>{
                   let data = {
                    name: `${this.state.FirstName} ${this.state.LastName}`,
                    email: this.state.Email,
                    contact: this.state.Phone
                  }
                  toast.success("We will get in touch");
                  talk(data).then(()=>{
                   
                    window.location.href = "https://www.lesson21.com";
                  }).catch(()=>{

                  })
                }}>Let's Talk</button>
              </div>
            </div>
          </div> 
    );
  }
}

export default Pricing;
