import React, { Component } from "react";
import { lazy } from "react";
import IntroContent from "../content/IntroContent.json";
import MiddleBlockContent from "../content/MiddleBlockContent.json";
import AboutContent from "../content/DetailedMission.json";
import MissionContent from "../content/MissionContent.json";
import ProductContent from "../content/ProductContent.json";
import ContactContent from "../content/ContactContent.json";

import Footer from "../components/Footer";
import Header from "../components/Header";

import Contact from "../components/ContactForm";
import MiddleBlock from "../components/MiddleBlock";
import Container from "../common/Container";
import ScrollToTop from "../common/ScrollToTop";
import ContentBlock from "../components/ContentBlock";
import Pricing from "../components/pricing";
import "antd/dist/antd.css";
import {Styles} from "../styles/lstyles";

const Enterprise = () => {
  return (
    <div>
      <Styles/>
  <Header />
    <Container>
      <ScrollToTop />
    
      <MiddleBlock
        title={'Enterprise'}
        content={'Are you an enterprise customer looking for a custom experience? Please drop us a message with your requirements and our team will get back to you with a custom offer.'}
      />
     
     </Container>
  <Footer />
  </div>
  );
};

export default Enterprise;
