import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput
} from "shards-react";
import { signup, login,createPaymentLink,createSubscriptionLink,checkPayment,talk } from "../services/api";
import { Dispatcher, Constants } from "../flux";
import Load from "../Load";
import logo from "../images/content-management/2.png";
import { Store } from "../flux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forgotPassword: false,
      loading: false,
      FirstName:'',
      LastName:'',
      Email: '',
      Password : '',
      Phone: '',
      SchoolName: '',
      title: "Signup",
      PostsListOne: [
        {
          backgroundImage: require("../images/content-management/ucatapult.jpeg"),
          category: "Login",
          categoryTheme: "info",
          author: "",
          authorAvatar: require("../images/avatars/2.jpg"),
          title: "Login to Purple LMS",
          body: "",
          date: ""
        }
      ]
    };
  }
  render() {
    return this.state.loading ? (
      <Container fluid className="main-content-container px-4">
        <Load />
      </Container>
    ) : (
      <Container fluid className="main-content-container px-4">
        <ToastContainer />
        <Row noGutters className="page-header py-4">
        </Row>
        <Row>
            <Col lg="3" md="6" sm="12" className="mb-4 login-center">
              <Card small className="card-post card-post--1">
                <div
                  className="card-post__image"
                ><img 
                src={this.state.brand != null ? this.state.brand.loginpic :
                  "https://www.lesson21.com/app/upload/uploads/960296-blob"
                }
                 className="card-post__image"  height="10%"/></div>
                <CardBody>
                  <h5 className="card-title">
                    <a href="#" className="text-fiord-blue">
                      {this.state.forgotPassword ? 'Forgot Password' : this.state.title}
                    </a>
                  </h5>
                 
                 
                    <div>
                      <InputGroup seamless className="mb-3">
                        
                        <FormInput
                        placeholder="First Name"
                          onChange={e => {
                            this.setState({
                              ...this.state,
                              FirstName: e.target.value
                            });
                          }}
                        />
                        </InputGroup>
                        <InputGroup seamless className="mb-3">
                        <FormInput
                        placeholder="Last Name"
                          onChange={e => {
                            this.setState({
                              ...this.state,
                              LastName: e.target.value
                            });
                          }}
                        />
                        </InputGroup>
                        <InputGroup seamless className="mb-3">
                        <FormInput
                        placeholder="School Name"
                          onChange={e => {
                            this.setState({
                              ...this.state,
                              SchoolName: e.target.value
                            });
                          }}
                        />
                         </InputGroup>
                         <InputGroup seamless className="mb-3">
                        <FormInput
                        placeholder="Email"
                          onChange={e => {
                            this.setState({
                              ...this.state,
                              Email: e.target.value
                            });
                          }}
                        />
                        </InputGroup>
                        <InputGroup seamless className="mb-3">
                        <FormInput
                        type="password"
                        placeholder="Password"
                          onChange={e => {
                            this.setState({
                              ...this.state,
                              Password: e.target.value
                            });
                          }}
                        />
                        
                      </InputGroup>
                      <InputGroup seamless className="mb-3">
                        <FormInput
                        placeholder="Phone"
                          onChange={e => {
                            this.setState({
                              ...this.state,
                              Phone: e.target.value
                            });
                          }}
                        />
                        
                      </InputGroup>
                     
                      
                      <InputGroup seamless className="mb-3">
                     
                        <Button
                          theme="login"
                          onClick={() => {
                            let connectingID = toast.info("Connecting");
                             this.setState({...this.state,loading: true});
                            {
                              let data = {
                                Name:"",
                                school: this.state.SchoolName,
                                Address:"India",
                                Rate:"0",
                                Currency:"INR",
                                Language:"en-US",
                                Data:"",
                                Country:"India",
                                UserName: this.state.Email,
                                FirstName:this.state.FirstName,
                                LastName:this.state.LastName,
                                Email: this.state.Email,
                                Password : this.state.Password,
                                Phone: this.state.Phone
                            };
                            signup(data).then(()=>{
                    
                              login({username: this.state.Email, password: this.state.Password})
                              .then(data => {
                                  this.setState({ ...this.state, loading: false });
                                  Dispatcher.dispatch({
                                    actionType: Constants.LOGIN,
                                    payload: data
                                  });
                                  this.props.history.push("/home");
                                })
                                .catch(err => { this.setState({ ...this.state, loading: false });});
          
                             }).catch(()=>{ this.props.history.push("/");})
                            }
                          }}
                        >
                          Signup
                        </Button>
                          
                      </InputGroup>  
                    </div>
                  
                </CardBody>
              </Card>
            </Col>
        </Row>
      </Container>
    );
  }
}

export default Signup;
