import React, { Component } from "react";
import { Container, Row, Col } from "shards-react";
import { addLesson, updateLesson,getLesson } from "../services/api";
import PageTitle from "../components/common/PageTitle";
import { Store } from "../flux";
import Editor from "../components/add-new-post/Editor";
import SidebarActions from "../components/add-new-post/SidebarActions";
import Load from "../Load";

class EditLesson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      editorHtml: "",
      loading:true,
      currentUser: Store.getCurrentUser()
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleTitle = this.handleTitle.bind(this);
    this.publish = this.publish.bind(this);
  }
  componentDidMount() {
    if (this.props.match.params.id) {
      getLesson(this.props.match.params.id,this.state.currentUser)
      .then((res)=>{
        this.setState({ title: res.Name, editorHtml: res.Content,loading: false });})
      .catch((err)=>{
        this.setState({...this.state,loading:false});});
    } else {
      this.setState({...this.state,loading:false})
    }
  }
  handleChange(value) {
    this.setState({ ...this.state, editorHtml: value });
  }
  handleTitle(value) {
    this.setState({ ...this.state, title: value });
  }
  publish() {
    this.setState({...this.state,loading:true});
    let data = {
      Name: this.state.title,
      ShortCode: this.props.location.course,
      ModifiedTime: "2021-03-02",
      Status: 1,
      Content: this.state.editorHtml
    };
    if (this.props.match.params.id) {
      updateLesson(this.props.match.params.id, data, this.state.currentUser)
        .then(data => {
          this.setState({...this.state,loading:false});
          this.props.history.push(`/course/${this.props.location.course}`);
        })
        .catch(err => {
          this.setState({...this.state,loading:false});
        });
    } else {
      addLesson(data, this.state.currentUser)
        .then(data => {
          this.setState({...this.state,loading:false})
          this.props.history.push(`/course/${this.props.location.course}`);
        })
        .catch(err => {this.setState({...this.state,loading:false})});
    }
  }
  render() {
    return this.state.loading ? (
      <Container fluid className="main-content-container px-4">
        <Load />
      </Container>
    ) :(
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title={
              this.props.match.params.id ? "Edit Lesson" : "Add New Lesson"
            }
            subtitle="Lesson"
            className="text-sm-left"
          />
        </Row>

        <Row>
          {/* Editor */}
          <Col lg="9" md="12">
            <Editor
              src={this.state.editorHtml}
              title={this.state.title}
              handleChange={this.handleChange}
              handleTitle={this.handleTitle}
            />
          </Col>

          {/* Sidebar Widgets */}
          <Col lg="3" md="12">
            <SidebarActions publish={this.publish} />
            {/* <SidebarCategories /> */}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EditLesson;
