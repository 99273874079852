/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";

class MeetingEnd extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
 <section class="hero">
  <h1>This meeting has ended. Please close this tab.</h1>
</section>
    );
  }
}

export default MeetingEnd;
