import React, { Component } from "react";
import {
    getAttendanceList,
    dateAttendance
} from "../services/api";
import { Store } from "../flux";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    ButtonGroup,
    Button,
    Row,
    Col
  } from "shards-react";
  import { FiEdit, FiTrash2, FiPlus } from "react-icons/fi";
  import ViewAttendance from "./ViewAttendance";

export default class AttendanceList extends Component{
    constructor(props){
        super(props)
        this.state = {
            viewMode: false,
            date: null,
            currentUser: Store.getCurrentUser(),
            course: this.props.match.params.id,
            list:[]
        }
    }
    componentDidMount(){
        getAttendanceList(this.props.match.params.id, this.state.currentUser).then((res)=>{
          this.setState({...this.state, list: res});
        }).catch();
    }
    manageClick(date){
    let pickedDate = date.split(' ')[0].replace(/\//g,'-');
    pickedDate = `${pickedDate.split('-')[2]}-${pickedDate.split('-')[0]}-${pickedDate.split('-')[1]}`;
    this.setState({...this.state, viewMode: true, date: pickedDate});
    }
    render(){
       
        return(
            this.state.viewMode ? <ViewAttendance goBack={()=>{this.setState({...this.state, date: null, viewMode: false});}} course={this.state.course} date= {this.state.date}></ViewAttendance> :
            <Row style={{maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto',marginTop: '100px'}}>
            <Col>
              <Card small className="mb-4 overflow-hidden ">
                <CardHeader className="bg-dark">
                 
                </CardHeader>
                <CardBody className="bg-dark p-0 pb-3">
                  {this.state.list.length > 0 ? (
                    <table className="table table-dark mb-0">
                      <thead className="thead-dark">
                        <tr>
                          
                            <th scope="col" className="border-0">
                              Date
                            </th>
                         <th scope="col" className="border-0">
                              {`Actions`}
                            </th>
                        
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.list.map((row, index) => (
                          <tr>
                           
                              <td>
                                {row}
                              </td>
                           
                           
                              <td>
                                {" "}
                               
                                  <a
                                    onClick={() => {
                                      this.manageClick(row);
                                    }}
                                  >
                                   
                                      <FiEdit></FiEdit>
                                   
                                  </a>
                               
                              </td>
                           
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <center>No Data to Display</center>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
    
        )
    }
}