import React, { Component } from "react";
import "react-contexify/dist/ReactContexify.css";
import Modal from "react-modal";
import "react-multi-email/style.css";
import { getAutoAttendance } from "../services/api";
import Load from "../Load";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import { Store } from "../flux";

import 'react-toastify/dist/ReactToastify.css';
import Joyride from 'react-joyride';

export default class AutoAttendance extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            attendance: []
        }
    }
   componentDidMount(){
    getAutoAttendance(Store.getCurrentUser()).then((res)=>{
    this.setState({...this.state, attendance: res});
    }).catch((err)=>{

    })
   }
    render() {
        return (
            <ListGroup flush>
                  <ListGroupItem className="p-0 px-3 pt-3">
                    <Row>
                      <Col>
                        <Card small className="mb-4 overflow-hidden">
                          <CardHeader className="bg-dark">
                            <h6 className="m-0 text-white">
                              Attendance{" "}
                              
                              {/* <i
                                onClick={() => {
                                  this.props.history.push(
                                    `/adminmanage/COURSE`
                                  );
                                }}
                                style={{
                                  float: "right",
                                  color: "white",
                                  marginRight: "10px"
                                }}
                                class="fa fa-eye course-step"
                                aria-hidden="true"
                              ></i> */}
                            </h6>
                          </CardHeader>
                          <CardBody className="bg-dark p-0 pb-3">
              {this.state.attendance.length > 0 ? (
                <table className="table table-dark mb-0">
                  <thead className="thead-dark">
                    <tr>
                      {Object.keys(this.state.attendance[0]).map((head) => (
                        <th scope="col" className="border-0">
                          {head !== "Image" && head!== "Live" ? head : ""}
                        </th>
                      ))}
                      
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.attendance.map((row) => (
                      <tr>
                        {Object.values(row).map(col => (
                          <td>
                            {!col.includes("upload/uploads") &&
                            !col.includes(".jpg") &&
                            !col.includes(".jpeg") &&
                            !col.includes(".png") &&
                            !col==""
                              ? col
                              : ""}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <center>No Data to Display</center>
              )}
            </CardBody>
                        </Card>
                      </Col>
                    </Row>
    </ListGroupItem>
    </ListGroup>
        )
    }
}