import React, { Component } from "react";
import {
  getAttendance,
  postAttendance,
  dateAttendance
} from "../services/api";
import { Store } from "../flux";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    ButtonGroup,
    Button,
    Row,
    Col
  } from "shards-react";

export default class ViewAttendance extends Component{
    constructor(props){
        super(props)
        this.state = {
            currentUser: Store.getCurrentUser(),
            students:[]
        }
    }
    componentDidMount(){
        dateAttendance(this.props.course,this.props.date, this.state.currentUser).then((res)=>{
          this.setState({...this.state, students: res});
        }).catch();
    }
    render(){
        let data = this.state.students;
        let count = data.length;
        let firstPoint = Math.round(count/2);
        let secondPoint = count;
        return(
          <div>
          <div style={{paddingLeft: 20, paddingRight: 20,display: 'flex', flexDirection:'row', justifyContent:'space-between', flex: 1, margin: 10}}>
                <Button onClick={this.props.goBack}>Go Back</Button>
                
                 {/*<Button onClick={()=>{
                  let data = this.state.students;
                  data.forEach((x)=>{
                    x.ischecked = false;
                  });
                  this.setState({...this.state, students: data});
                }}>UnSelect All</Button><Button onClick={()=>{
                  let data = {
                    school: this.state.currentUser.Institution,
                    course: this.props.match.params.id,
                    user: this.state.currentUser.id,
                    data: this.state.students
                  }
                  postAttendance(data, this.state.currentUser).then().catch();
                }}>Save</Button> */}
                </div>
            <Row>
               
            <Col className="text-center view-report">
                <CardBody>
                 
            <ul>
            {data.slice(0,firstPoint).map((v, i) => (
             
                <li key={i}>
                  <input
                    type="checkbox"
                    data-key={v.id}                  // 3
                    onClick={()=>{
                    //   let data = this.state.students;
                    //   data[i].ischecked = !data[i].ischecked;
                    //   this.setState({...this.state, students: data});
                     
                    }}         // 4
                    checked={v.ischecked} // 5
                  />
                  <label>{v.name}</label>
                </li>
              ))}
              </ul>
              </CardBody>
            </Col>
            <Col className="text-center view-report">
                <CardBody>
            <ul>
            {data.slice(firstPoint,secondPoint).map((v, i) => (
                <li key={i}>
                  <input
                    type="checkbox"
                    data-key={v.id}                  // 3
                    onClick={()=>{
                    //   let data = this.state.students;
                    //   data[firstPoint+i].ischecked = !data[firstPoint+i].ischecked;
                    //   this.setState({...this.state, students: data});

                    }}         // 4
                    checked={v.ischecked} // 5
                  />
                  <label>{v.name}</label>
                </li>
              ))}
              </ul>
              </CardBody>
            </Col>
            </Row>
            </div>
        )
    }
}