import React from "react";
import styled from "styled-components";
import ReactTooltip from 'react-tooltip';
import Popup from 'reactjs-popup';
import Iframe from 'react-iframe'
import {Alert}  from "shards-react";
import {pay} from "../services/api";
import { Store } from "../flux";

const Container = styled.div`
display: flex;
height: ${window.screen.height}px;
flex: 1;
background-image: url('https://img.freepik.com/free-vector/abstract-red-geometric-polygonal-background_1035-18006.jpg');
background-repeat: repeat-y;
background-size: cover;
color: black;
font-family: Roboto;
font-size: 16px;
`;

export default class Choose extends React.Component {
constructor(props){
    super(props)
    this.state = {frame: false, url: '',currentUser: Store.getCurrentUser()}
}
render(){
    return (
        this.state.frame ? <div><Alert className="mb-0 alert-danger">
      <i className="fa fa-info mx-2"></i> Please remain on this page until your payment succeeds and you are automatically logged into your subscription. Maximum wait time is 10 minutes.
    </Alert><Iframe url={this.state.url}
      width="100%"
      height={window.innerHeight}
      id="myId"
      className="calls"
      display="initial"
      position="relative"/></div>:
    <Container>
        <ReactTooltip />
        <div className="container">
    <div className="grid">
        <div className="ccard">
            <p className="pricing">&#8377;1000<span className="small">/per month</span></p>
            {/* <p>Save $9</p> */}
            <hr/>
            <ul className="features">
            <li>Previous Plan +</li>
        <li>Branded Website</li>
        <li>Kanban Board</li>
        <li>Conduct Exams</li>
        <li data-tip="Third party video calling integration like Gmeet, Zoom etc.">
        Live Class *
          </li>
        <li>Curated Content</li>
        <li>Upto 100 Users</li>
        <li>100GB Storage</li>
        <li>Branded App</li>
            </ul>
            <a href="#" onClick = {()=>{pay('plan1',this.state.currentUser).then((url)=>{
               window.location.href = url;
             })}} className="cta_btn">Upgrade</a>
        </div>
        <div className="ccard">
            <p className="pricing">&#8377;10,000<span className="small">/per month</span></p>
            {/* <p>Save $15</p> */}
            <hr/>
            <ul className="features">
            <li>Previous Plan +</li>
        <li>Premium support</li>
        <li>Custom Integrations</li>
        <li>Upto 1000 Users</li>
        <li data-tip="Third party video calling integration like Gmeet, Zoom etc.">Live Class *</li>
        <li data-tip="Users and Courses like adding new users or courses will be handled by the Purple LMS team. The user could communicate preferences via Whatsapp or Call">Remote Management</li>
        <li>1TB Storage</li>
        <li>Branded App</li>
            </ul>
             <a href="#" onClick = {()=>{pay('plan2',this.state.currentUser).then((url)=>{
               window.location.href = url;
             })}} className="cta_btn">Upgrade</a>
        </div>
        <div className="ccard">
            <p className="pricing">Custom<span className="small"></span></p>
            {/* <p>Save $25</p> */}
            <hr/>
            <ul className="features">
            <li>Previous Plan +</li>
        <li>Dedicated support</li>
        <li>Custom Code</li>
        <li>Unlimited Users</li>
        <li data-tip="In house video calling solution">Live Class *</li>
        <li>Dedicated Hosting</li>
        <li>Marketing Support</li>
        <li>Unlimited Storage</li>
        <li>Custom Design</li>
            </ul>
             <a href="#" className="cta_btn">Upgrade</a>
        </div>
    </div>
    </div>
</Container>
);
}

}