import React, { Component } from "react";
import { lazy } from "react";
import IntroContent from "../content/IntroContent.json";
import MiddleBlockContent from "../content/MiddleBlockContent.json";
import AboutContent from "../content/AboutContent.json";
import MissionContent from "../content/MissionContent.json";
import ProductContent from "../content/ProductContent.json";
import ContactContent from "../content/ContactContent.json";
import FloatingWhatsApp from 'react-floating-whatsapp'
import Footer from "../components/Footer";
import Header from "../components/Header";
import {getWebsite, getCountry} from "../services/api";
import Contact from "../components/ContactForm";
import MiddleBlock from "../components/MiddleBlock";
import Container from "../common/Container";
import ScrollToTop from "../common/ScrollToTop";
import ContentBlock from "../components/ContentBlock";
import Pricing from "../components/pricing";
import "antd/dist/antd.css";
import {Styles} from "../styles/lstyles";
import Iframe from 'react-iframe'
import PricingDetail from "../components/PricingDetail";
import styled from "styled-components";


const MiddleBlockSection = styled("section")`
  position: relative;
  padding: 7.5rem 0 3rem;
  text-align: center;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    padding: 5.5rem 0 3rem;
  }
`;


export default class LandingPage extends React.Component {
constructor(props){
  super(props)
  let subdomain = window.location.href.split('.')[0].replace('https://','').replace('http://','').replace('lesson21','').replace('www','').replace('localhost:3000','').replace('/','');
  this.state = {
country: 'IN',
loading: subdomain.length> 0 ? true : false,
url: 'https://www.inspirelearning.in/',
index: 0,
img: 'f.jpg'
  }

  this.getImg = this.getImg.bind(this);
}
getImg(){
  let imgs = ['f','w','i'];
  let index = this.state.index;
  index+=1;
  if(index>= imgs.length){
    index=0;
  }
  this.setState({...this.state,index: index, img: `${imgs[index]}.jpg`});
}
componentDidMount(){
  let subdomain = window.location.href.split('.')[0].replace('https://','').replace('http://','').replace('lesson21','').replace('www','').replace('localhost:3000','').replace('/','');
  if(subdomain.length>0){
    getWebsite(subdomain).then((x)=>{
      if(x.length>3000){
       this.setState({...this.state,url:  `https://www.inspirelearning.in/ploojk9656453944/${subdomain}/index.php`})
      }
    }).catch((err)=>{console.log('meow')})
  } else {
    this.interval = setInterval(() => this.getImg(), 3000);
    getCountry().then((res)=>{
      this.setState({...this.state, country: res.country})
    }).catch(()=>{})
  }

}
render(){
  let subdomain = window.location.href.split('.')[0].replace('https://','').replace('http://','').replace('lesson21','').replace('www','').replace('localhost:3000','').replace('/','');
  return(subdomain.length>0 ?  <Iframe url={this.state.url}
  width="100%"
  height={window.innerHeight}
  id="myId"
  className="calls"
  style={{border:0}}
  display="initial"
  position="relative"/> :
  <div>
    <Styles/>
<Header />
  <Container>
  
    <ScrollToTop />
    <ContentBlock
      type="right"
      title={IntroContent.title}
      content={IntroContent.text}
      button={IntroContent.button}
      icon={this.state.img}
      id="intro"
    />
    <MiddleBlock
      title={MiddleBlockContent.title}
      content={MiddleBlockContent.text}
      button={MiddleBlockContent.button}
    />
    {/* <ContentBlock
      type="left"
      title={AboutContent.title}
      content={AboutContent.text}
      section={AboutContent.section}
      icon="graphs.svg"
      id="about"
    /> */}
    <br/><br/><br/><br/><br/><br/>
    <ContentBlock
      type="right"
      title={MissionContent.title}
      content={MissionContent.text}
      icon="video-call.svg"
      id="mission"
    />
    <br/><br/><br/><br/><br/><br/>
    <ContentBlock
      type="left"
      title={ProductContent.title}
      content={ProductContent.text}
      icon="efficient.svg"
      id="product"
    />
    
    
    {/* <PricingDetail country = {this.state.country}/> */}
    
    
    {/* <Pricing country = {this.state.country}/> */}
    
    {/* <Contact
      title={ContactContent.title}
      content={ContactContent.text}
      id="contact"
    /> */}
  </Container>
  <FloatingWhatsApp style={{zIndex: 1000}} phoneNumber={'+918075951159'} accountName={'Purple LMS'}/>
<Footer />
</div>);
}
}

