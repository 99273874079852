import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import { Card, CardBody, Form, FormInput } from "shards-react";
import { formDataUpload } from "../../services/api";
import { uploadFile } from "../../services/api";
import "react-quill/dist/quill.snow.css";
import "../../assets/quill.css";
import { Store } from "../../flux";

import ImageUploader from "quill-image-uploader";

// #2 register module
Quill.register("modules/imageUploader", ImageUploader);

class Editor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser : Store.getCurrentUser()
    }
  }
  modules = {
    // #3 Add "image" to the toolbar
    toolbar: [
      [
        { header: "1" },
        { header: "2" },
        { header: [3, 4, 5, 6] },
        { font: [] }
      ],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "video"],
      ["link", "image", "video"],
      ["clean"],
      ["code-block"],
      ["bold", "italic", "image"]
    ],
    // # 4 Add module and upload function
    imageUploader: {
      upload: file => {
        return uploadFile(file,this.state.currentUser)
      }
    }
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "imageBlot",
    "code-block",
    "video" // #5 Optinal if using custom formats
  ];

  render() {
    return (
      <Card small className="mb-3">
        <CardBody>
          <Form className="add-new-post">
            <FormInput
              value={this.props.title}
              onChange={e => {
                this.props.handleTitle(e.target.value);
              }}
              size="lg"
              className="mb-3"
              placeholder="Your Post Title"
            />
            <ReactQuill
              value={this.props.src}
              onChange={this.props.handleChange}
              modules={this.modules}
              formats={this.formats}
              className="add-new-post__editor mb-1"
            />
          </Form>
        </CardBody>
      </Card>
    );
  }
}

export default Editor;
