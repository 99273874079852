import React, { Component } from "react";
import { Container, Row, Col,FormTextarea, Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  FormInput,
  Button } from "shards-react";
  import Modal from "react-modal";
  import { Menu, Item, contextMenu } from "react-contexify";
import PageTitle from "./../components/common/PageTitle";
import { Store } from "../flux";
import IconDisplay from "../components/common/IconDisplay";
import PdfView from "./PdfView";
import { getAssignmentSubmissions,updateSubmittedAssignment } from "../services/api";
import { FiX } from "react-icons/fi";
import { FiBookOpen } from "react-icons/fi";
import "react-contexify/dist/ReactContexify.css";

class AssignmentSubmissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: Store.getCurrentUser(),
      currentBook: null,
      isOpen: false,
      bookID: null,
      remarks: '',
      grade: '',
      url: null,
      showModal: false,
      editModal: { open: false, elements: [] },
      buttonTheme: "primary",
      buttonText: "Publish",
      uploadUrl: "",
      resource: {
        src: "",
        thumbnail: "https://www.lesson21.com/app/upload/uploads/216968-pdf.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 320,
        tags: [
          { value: "Ocean", title: "Ocean" },
          { value: "People", title: "People" }
        ],
        caption: ""
      },
      books: [],
      uploadUrl: "",
      fileName: "",
      due: "2021-01-01"
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  setOpen(val, id) {
    this.setState({
      isOpen: val,
      url: id != null ? this.state.books[id].src : null,
      currentBook: id != null ? this.state.books[id] : null,
      bookID: id
    });
    
  }

  handleItemClick = ({ event, props }) => {
    this.setState({
      ...this.state,
      editModal: { open: true, elements: [props] }
    });
  };
  handleClose = () => {
    this.setState({ ...this.state, editModal: { open: false, elements: [] } });
  };
  handleOpenModal() {
    this.setState({ ...this.state, showModal: true });
  }

  handleCloseModal() {
    this.setState({ ...this.state, showModal: false });
  }
  componentDidMount() {
    let assignment = this.props.location.assignment;
    let type = this.props.location.type;
    getAssignmentSubmissions(assignment.id, this.state.currentUser)
      .then(data => {
        console.log(data);
        this.setState({ ...this.state, books: data });
      })
      .catch(err => {
        this.props.history.goBack();
      });
  }
  showAssignmentMenu(e, book) {
    
    if (this.state.currentUser.Type != "STUDENT") {
      contextMenu.show({
        id: `teacher`,
        event: e,
        props: {
          key: book
        }
      });
    }
  }
  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Submssions"
            subtitle="Lesson 21"
            className="text-sm-left mb-3"
          />
        </Row>

        {/* Small Stats Blocks */}
        <Row>
          <Col className="col-lg mb-4">
            {this.state.isOpen ? (
              <div  onContextMenu={e => {
                this.showAssignmentMenu(e, this.state.currentBook);
              }}>
                <PdfView url={this.state.url} />
                <Modal
          isOpen={this.state.showModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick={true}
          className="library_modal"
          overlayClassName="library_overlay"
          ariaHideApp={false}
        >
          <Card small>
            {/* Files & Dropdowns */}
            <CardHeader className="border-bottom">
              <h6 className="m-0">
                Grade Assignment
              </h6>
            </CardHeader>

            <ListGroup flush>
              <ListGroupItem className="px-3">
                <strong className="text-muted d-block mb-2">Grade</strong>
                <div className="custom-file mb-3">
                  <FormInput
                    onChange={e => {
                      this.setState({
                        ...this.state,
                        grade: e.target.value
                      });
                    }}
                  />
                </div>
                <strong className="text-muted d-block mb-2">Remarks</strong>
                <div className="custom-file mb-3">
                <FormTextarea onChange={(e)=>{this.setState({...this.state, remarks: e.target.value})}} placeholder="Please provide remarks" />
                </div>

              
                 
               
                
                <div className="custom-file mb-3">
                  <Button
                    theme={this.state.buttonTheme}
                    onClick={() => {
                      let data =  {id: this.state.currentBook.id
                       , marks: this.state.grade,
                       remarks: this.state.remarks,
                       student: this.state.currentBook.studentID
                      }
                      updateSubmittedAssignment(data, this.state.currentUser)
                      .then(()=>{}).catch(()=>{});
                      this.setState({...this.state, grade: null, remarks: null,bookID: null, isOpen: false});
                    }}
                  >
                    {'Submit'}
                  </Button>
                </div>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Modal>

                <Menu id={`teacher`}>
          <Item onClick={this.handleOpenModal}>Grade</Item>
        
        </Menu>
                <div class="fixed">
                  <FiX
                    onClick={() => {
                      this.setOpen(false, null);
                    }}
                    size={30}
                  />
                </div>
              </div>
            ) : (
              this.state.books.map((book, index) => (
                <IconDisplay
                  icon={<FiBookOpen></FiBookOpen>}
                  // context={e => {
                  //   this.showMenu(e, book);
                  // }}
                  key={index}
                  bookClick={() => {
                    this.setOpen(true, index);
                  }}
                  iconSize="4em"
                  title={book.studentName}
                />
              ))
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default AssignmentSubmissions;
