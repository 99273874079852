import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Container, Navbar } from "shards-react";
import NavbarSearch from "./NavbarSearch";
import NavbarNav from "./NavbarNav/NavbarNav";
import NavbarToggle from "./NavbarToggle";
import { Dispatcher, Constants, Store } from "../../../flux";
import {SvgIcon} from "../../../common/SvgIcon";
import logo from '../../../assets/logo.svg';

const handleClick = () => {
  Dispatcher.dispatch({
    actionType: Constants.TOGGLE_SIDEBAR
  });
};
const MainNavbar = ({ layout, stickyTop }) => {
  const classes = classNames(
    "main-navbar",
    "bg-white",
    stickyTop && "sticky-top"
  );
  const brand  = Store.getBrand()
  return (
    <div className={classes}>
      <Container className="p-0" style={{ maxWidth: "100%" }}>
        <Navbar type="light" className="align-items-stretch flex-md-nowrap p-0 ">
          <img
            onClick={() => {
              handleClick();
            }}
            id="main-logo"
            className="d-inline-block align-top mr-1 step-logo"
            style={brand!= null? { maxWidth: "180px", scale: "0.7" } : { maxWidth: "180px", scale: "0.7", marginLeft: 20 }}
            src={brand!= null? brand.brandingpic : logo}
          />
          {/* <NavbarSearch /> */}
          <NavbarNav />
          {/* <NavbarToggle /> */}
        </Navbar>
      </Container>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool
};

MainNavbar.defaultProps = {
  stickyTop: true
};

export default MainNavbar;
