import React from "react";

class BreadCrumb extends React.Component {
  render() {
    return (
      <div
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          paddingLeft: "30px",
          fontSize: "1.2em",
          paddingRight: "30px",
          borderRadius: "0.5rem",
          backgroundColor: "#f5f6f8",
          paddingTop: "16.5px",
          minHeight: "60px"
        }}
      >
        <div>
          <span style={{ paddingRight: "15px", fontWeight: "bold" }}>
            Dashboard
          </span>{" "}
          | <span style={{ paddingLeft: "15px" }}>Courses</span>
        </div>
      </div>
    );
  }
}

export default BreadCrumb;
